import { ChainId } from '@pancakeswap/sdk'
import { useChain, useNetwork } from "@thirdweb-dev/react"
import { atom, useAtomValue } from 'jotai'
import { useRouter } from 'next/router'
import { useDeferredValue } from 'react'
import { isChainSupported } from 'utils/wagmi'
import { useSessionChainId } from './useSessionChainId'

const queryChainIdAtom = atom(-1) // -1 unload, 0 no chainId on query

queryChainIdAtom.onMount = (set) => {
    const params = new URL(window.location.href).searchParams
    const c = params.get('chainId')
    if (isChainSupported(+c)) {
        set(+c)
    } else {
        set(0)
    }
}

export function useLocalNetworkChain() {
    const [sessionChainId] = useSessionChainId()
    // useRouter is kind of slow, we only get this query chainId once
    const queryChainId = useAtomValue(queryChainIdAtom)
    const { query } = useRouter()

    const chainId = +(sessionChainId || query.chainId || queryChainId)

    if (isChainSupported(chainId)) {
        return chainId
    }

    return undefined
}

export const useActiveChainId = () => {
    const localChainId = useLocalNetworkChain()
    const queryChainId = useAtomValue(queryChainIdAtom)
    
    const chain = useChain();
    const chainId = localChainId ?? chain?.chainId ?? (queryChainId >= 0 ? ChainId.ONUS : undefined)

    const isNotMatched = useDeferredValue(chain && localChainId && chain?.chainId !== localChainId)


    return {
        chainId,
        isWrongNetwork: isNotMatched,
        isNotMatched,
        chain,
    }
}
