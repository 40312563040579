import styled from "styled-components";

const Placeholder = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export default Placeholder;
