import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <path
                d="M14.625 18.5H2.25C1.65326 18.5 1.08097 18.2629 0.65901 17.841C0.237053 17.419 0 16.8467 0 16.25V3.875C0 3.27826 0.237053 2.70597 0.65901 2.28401C1.08097 1.86205 1.65326 1.625 2.25 1.625H6.75V3.875H2.25V16.25H14.625V11.75H16.875V16.25C16.875 16.8467 16.6379 17.419 16.216 17.841C15.794 18.2629 15.2217 18.5 14.625 18.5ZM8.6625 11.4204L7.07625 9.82963L14.1559 2.75H10.125V0.5H18V8.375H15.75V4.34188L8.6625 11.4204Z"
                fill="text"
            />
        </Svg>
    );
};

export default Icon;
