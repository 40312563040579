import { useAddress, useChain, useSDK } from '@thirdweb-dev/react';

export function useWeb3React() {
    const chain = useChain();
    const sdk = useSDK()
    const address = useAddress();
    const provider = sdk?.getProvider()
    return {
        chainId: chain?.chainId,
        account: sdk?.wallet.isConnected() ? address : null, // TODO: migrate using `isConnected` instead of account to check wallet auth
        isConnected: sdk?.wallet.isConnected(),
        isConnecting: sdk?.wallet.isConnected,
        chain,
        provider
    }
}
