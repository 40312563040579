import { createReducer } from '@reduxjs/toolkit';
import { FAQ_TYPE } from "config/index"
import {
    fetchActiveTab,
    fetchShowSuggest
} from './actions';

interface global {
    activeTab: string,
    isShowSuggest: boolean
}
export const initialState: global = {
    activeTab: FAQ_TYPE.STORIES,
    isShowSuggest: false
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchActiveTab, (state, action) => {
            state.activeTab = action.payload.activeTab
        })
        .addCase(fetchShowSuggest, (state, action) => {
            state.isShowSuggest = action.payload.isShowSuggest
        })
) 