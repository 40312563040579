import { useSDK, useSigner } from "@thirdweb-dev/react";
import { useMemo } from 'react';


export const useProviderOrSigner = (withSignerIfPossible = true) => {
    const sdk = useSDK()
    const provider = sdk.getProvider()
    const isConnected = sdk.wallet.isConnected()
    const address = isConnected ? sdk.wallet.getAddress() : ""
    const signer = useSigner();

    return useMemo(
        () => (withSignerIfPossible && address && isConnected && signer ? signer : provider),
        [address, isConnected, provider, signer, withSignerIfPossible],
    )
}
