import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75834 9.53116C3.29522 5.75674 6.46604 3.33325 10 3.33325C13.534 3.33325 16.7048 5.75674 18.2417 9.53116C18.3639 9.83129 18.3639 10.1685 18.2417 10.4687C16.7048 14.2431 13.534 16.6666 10 16.6666C6.46604 16.6666 3.29522 14.2431 1.75834 10.4687C1.63614 10.1685 1.63614 9.83129 1.75834 9.53116ZM10 15.1246C12.8492 15.1246 15.4615 13.1555 16.7916 9.99992C15.4615 6.84429 12.8492 4.8752 10 4.8752C7.15081 4.8752 4.53856 6.84429 3.20843 9.99992C4.53856 13.1555 7.15081 15.1246 10 15.1246ZM10 13.0385C8.35104 13.0385 7.01428 11.6781 7.01428 9.99992C7.01428 8.32177 8.35104 6.96137 10 6.96137C11.649 6.96137 12.9858 8.32177 12.9858 9.99992C12.9858 11.6781 11.649 13.0385 10 13.0385ZM10 11.4965C10.8122 11.4965 11.4706 10.8265 11.4706 9.99992C11.4706 9.17337 10.8122 8.50332 10 8.50332C9.18784 8.50332 8.52943 9.17337 8.52943 9.99992C8.52943 10.8265 9.18784 11.4965 10 11.4965Z"
                fill="text"
            />
        </Svg>
    );
};

export default Icon;
