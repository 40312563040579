/* eslint-disable camelcase */
import { KEY_LOGIN } from 'config';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { AppState, useAppDispatch } from '../index';
import { fetchDataUser, fetchPublicNonce } from './actions';
import { fetchNonceData, fetchPublicDataUser } from './fetchData';

export const UseCoreNonce = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        (async() => {
            const res = await fetchNonceData()
            dispatch(fetchPublicNonce(res))
        })()
    }, [dispatch])
}


// export const useCheckAccount = () => {
//     const { account } = useActiveWeb3React()
//     const dataLocalStorage = typeof window !== 'undefined' && localStorage?.getItem(KEY_LOGIN)
//     useEffect(() => {
//         const checkAccount = async (acc:string) => {
//             try {
//                 const token = dataLocalStorage;
//                 const decoded:any = await jwt_decode(!token ? '' : token);
//                 console.log('decoded',decoded)
//                 if (acc?.length === decoded?.sub?.length && acc?.toLowerCase() !== decoded?.sub?.toLowerCase()) {
//                     // localStorage.removeItem(KEY_LOGIN)
//                 }
//             } catch (error) {
//                 console.log(error)
//             }
//         }
//         if(account !== undefined){
//             checkAccount(account)
//         }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [  dataLocalStorage, account, KEY_LOGIN ])
// }

export const UseCoreDataUserAuth = (contractAddress: string, account:string, tokenAddress:string, decimal:number, chainId:number) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        (async() => {
            const res = await fetchPublicDataUser(contractAddress, account, tokenAddress, decimal, chainId )
            dispatch(fetchDataUser(res))
        })()
    }, [account, chainId, contractAddress, decimal, dispatch, tokenAddress])
}

export const GetDataAuth = () => {
    const dataAuth = useSelector<AppState, AppState['auth']>((state) => state?.auth)
    return dataAuth
}