import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { Button, Grid, Heading, Message, MessageText, Modal } from '@pancakeswap/uikit'
import { useChain, useDisconnect, useNetwork, useNetworkMismatch } from '@thirdweb-dev/react'

import { useLocalNetworkChain } from 'hooks/useActiveChainId'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import Dots from '../Loader/Dots'

const LIST_CHAINS = [
    {
        id: 56,
        name: 'BNB Smart Chain',
        testnet: false
    },
    {
        id: 97,
        name: 'BSC Testnet',
        testnet: true
    }
]

// Where chain is not supported or page not supported
export function UnsupportedNetworkModal({ pageSupportedChains }: { pageSupportedChains: number[] }) {
    const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const { isWrongNetwork } = useActiveWeb3React()
    const chain = useChain();
    const chainId = useLocalNetworkChain()
    const [{ data }] = useNetwork();
    const isMismatched = useNetworkMismatch();
    const { isConnected } = useAccount()
    const disconnect = useDisconnect();
    const { t } = useTranslation()
    

    const supportedMainnetChains = useMemo(
        () =>
            data?.chains.length > 0
                ? data?.chains.filter((e) => e.testnet && pageSupportedChains?.includes(e.chainId))
                : LIST_CHAINS.filter((e) => e.testnet && pageSupportedChains?.includes(e.id)),
        [data?.chains, pageSupportedChains],
    )

    function handleClick() {
        disconnect().then(() => {
            if (pageSupportedChains.includes(ChainId.ONUS)) {
                switchNetworkLocal(ChainId.ONUS)
            } else {
                switchNetworkLocal(ChainId.ONUS)
            }
        })
    }

    
    return (
        <CsModal title={t('Kiểm tra mạng lưới của bạn')} onDismiss={handleClick} headerBackground="gradientCardHeader">
            <Grid style={{ gap: '16px' }}>
                <div className="flex gap-1">
                    <p className="text-[#23262F] text-sm"> Hiện tại trang này chỉ được hỗ trợ ở</p>
                    <p className="text-[#23262F] text-sm">{supportedMainnetChains?.map((c) => c.name).join(', ')}</p>
                </div>
                <Message variant="warning">
                    <MessageText>{t('Vui lòng chuyển mạng lưới của bạn để tiếp tục.')}</MessageText>
                </Message>
                {canSwitch ? (
                    <Button
                        isLoading={isLoading}
                        onClick={() => {
                            if (supportedMainnetChains.map((c) => c.id).includes(chainId)) {
                                switchNetworkAsync(chainId)
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                if (!supportedMainnetChains.map((c) => c.id).includes(ChainId.ONUS)) {
                                    console.log('switchNetworkAsync')
                                    if(!isMismatched) {
                                        switchNetworkAsync(ChainId.ONUS)
                                      }
                                      else {
                                        // eslint-disable-next-line no-lonely-if
                                        if(isWrongNetwork) {
                                            disconnect().then(() => {
                                            switchNetworkLocal(pageSupportedChains.includes(chain?.chainId) ? chain?.chainId : ChainId.ONUS)
                                          })
                                        }
                                        else {
                                          switchNetworkAsync(pageSupportedChains.includes(chain?.chainId) ? chain?.chainId : ChainId.ONUS)
                                        }                    
                                      }
                                } else {
                                    // eslint-disable-next-line no-lonely-if
                                    if (isWrongNetwork) {
                                        disconnect().then(() => {
                                            switchNetworkLocal(ChainId.ONUS)
                                        })
                                    } else {
                                        switchNetworkAsync(ChainId.ONUS)
                                    }
                                }
                            }
                        }}
                    >
                        {isLoading ? <Dots>{t('Chuyển mạng lưới trong ví')}</Dots> : t('Chuyển mạng lưới trong ví')}
                    </Button>
                ) : (
                    <Message variant="danger">
                        <MessageText>{t('Không thể chuyển đổi mạng. Hãy thử nó trên ví của bạn')}</MessageText>
                    </Message>
                )}
                {isConnected && (
                    <Button
                        onClick={disconnect}
                    >
                        {t('Ngắt kết nối ví')}
                    </Button>
                )}
            </Grid>
        </CsModal>
    )
}
const CsModal = styled(Modal)`
    width: 100%;
    max-width: 450px;
    ${Heading} {
        font-weight: 800;
        font-size: 22px;
    }
    overflow-y: hidden;
    ${Heading} {
        font-weight: 800;
        font-size: 22px;
    }
    overflow-y: hidden;
`
