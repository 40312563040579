import axios from 'axios';
import { KEY_LOGIN } from 'config';
import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode';

// Add a request interceptor
export default function configAxios() {
    axios.defaults.baseURL = process.env.NEXT_PUBLIC_API
    axios.defaults.headers["content-type"] = "application/json";
    axios.defaults.timeout = 10000;
    
    axios.interceptors.request.use(
        async (config) => {
            const dataLocalStorage = localStorage?.getItem(KEY_LOGIN)
            if (dataLocalStorage) {
                const decodedToken: any = jwtDecode(dataLocalStorage);
                const currentDate = new Date();
                if (decodedToken.exp * 1000 < currentDate.getTime()) {
                    localStorage.removeItem(KEY_LOGIN)
                } else {
                     // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `Bearer ${dataLocalStorage}`
                }
            }
            return config
        },
        (error) => {
            Promise.reject(error)
        },
    )

    axios.interceptors.response.use(
        (response) => {
            return response
        },
        // eslint-disable-next-line func-names
        async function (error) {
            if (!navigator.onLine) {
                return Promise.reject(error)
            }
            const originalRequest = error.config
            if (
                error &&
                error.response &&
                error?.response?.status === 401 &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true
            }
            return Promise.reject(error)
        },
    )
}

