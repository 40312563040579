import React from "react";
import styled, { DefaultTheme } from "styled-components";
import CheckmarkCircleIcon from "../Svg/Icons/CheckmarkCircle";
import ErrorIcon from "../Svg/Icons/Error";
import BlockIcon from "../Svg/Icons/Block";
import InfoIcon from "../Svg/Icons/Info";
import { Text } from "../Text";
import { IconButton } from "../Button";
import { CloseIcon } from "../Svg";
import Flex from "../Box/Flex";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
    variant: AlertProps["variant"];
    theme: DefaultTheme;
    hasDescription?: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
    switch (variant) {
        case variants.DANGER:
            return "#FFF5F5";
        case variants.WARNING:
            return theme.colors.warning;
        case variants.SUCCESS:
            return "#F1FDF4"
        case variants.INFO:
        default:
            return theme.colors.secondary;
    }
};

const getBorderolor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
    switch (variant) {
        case variants.DANGER:
            return "#FF0007";
        case variants.WARNING:
            return "white";
        case variants.SUCCESS:
            return "#17E344"
        case variants.INFO:
        default:
            return "white";
    }
};

const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
    switch (variant) {
        case variants.DANGER:
            return BlockIcon;
        case variants.WARNING:
            return ErrorIcon;
        case variants.SUCCESS:
            return CheckmarkCircleIcon;
        case variants.INFO:
        default:
            return InfoIcon;
    }
};


const IconLabel = styled.div<ThemedIconLabel>`
    background-color: transparent;
    border-radius: 5px 0 0 5px;
    color: ${({ theme }) => theme.alert.background};
    padding: 12px;
    display: flex;
    align-items: center;
`;

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
    flex: 1;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "12px")};
    padding-top: 12px;
`;

const CloseHandler = styled.div`
    border-radius: 0 16px 16px 0;
    right: 8px;
    position: absolute;
    top: 8px;
`;

const StyledAlert = styled(Flex)<ThemedIconLabel>`
    position: relative;
    background-color: ${getThemeColor};
    border-radius: 24px;
    border: 2px solid ${getBorderolor};
`;

const CsText = styled(Text)<ThemedIconLabel>`
    color: ${getBorderolor};
`;


const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({ title, children, variant, onClick }) => {
    const Icon = getIcon(variant);

    return (
        <StyledAlert variant={variant}>
            <IconLabel variant={variant} hasDescription={!!children}>
                <Icon color="currentColor" width="32px" />
            </IconLabel>
            <Details hasHandler={!!onClick}>
                <CsText bold variant={variant}>{title}</CsText>
                {typeof children === "string" ? <CsText as="p" variant={variant}>{children}</CsText> : children}
            </Details>
            {onClick && (
                <CloseHandler>
                    <IconButton scale="sm" variant="text" onClick={onClick}>
                        <CloseIcon width="24px" color="currentColor" />
                    </IconButton>
                </CloseHandler>
            )}
        </StyledAlert>
    );
};

export default Alert;
