import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import province from "config/constants/address/province.json";
import { 
    fetchActivePrice, fetchStatusScreen, fetchActiveCategory, fetchActiveBrand, fetchListCategory, fetchTokenSupport, fetchActiveTokenPayment,
    fetchBuyVouchers,  fetchDataUser, getStatusLoading, fetchPublicBrand, fetchProvinceId, fetchListProvinceId, fetchTokenSupportBalance,
    fetchDetailBrand, fetchDataVouchers, fetchFillPhoneNumber, fetchFillEmail, fetchFillAmount, fetchIsUpdateOrder, fetchListProvinceByBrandId, fetchListProvinceByBrand, fetchDataUserByChain, fetchListProvince } from './actions'
import { BrandItem, Brandtype, Categorytype, dataBuyVouchersType, DataUser, dataVouchersType, listProvinceType, productType, resultListBalanceByChain, TokenSupportBalanceList, TokenSupportList } from './type'

interface global {
    price: productType,
    product: productType,
    isActiveScreen: number,
    category:string|null,
    ListCategory:Categorytype[],
    isLoading: boolean,
    ListBrand:Brandtype[],
    cityId:string,
    ListProvinceId:string[],
    ListProvinceByBrand:string[],
    brandItem:BrandItem[],
    dataVouchers:dataVouchersType,
    dataUser:DataUser,
    dataBuyVouchers:dataBuyVouchersType,
    email:string,
    phoneNumber:string,
    fillAmount:number,
    isUpdate: boolean,
    brandId: string,
    ListBalance:resultListBalanceByChain[],
    listProvince:listProvinceType[],
    tokenSupport: TokenSupportList[],
    tokenSupportBalance: TokenSupportBalanceList[],
    activeTokenPayment: string
}
export const initialState: global = {
    price: null,
    product: null,
    isActiveScreen: 0,
    category: '0',
    ListCategory:[],
    isLoading:true,
    ListBrand:[],
    cityId:null,
    ListProvinceId:[],
    ListProvinceByBrand:[],
    brandItem:null,
    dataVouchers:{
        email: '',
        phoneNumber: '',
        fillAmount: 0,
        deliveryMethod: '',
        itemId: '',
        nameProduct: '',
        price: '',
        orderId: "",
        brandName: "",
        viewTag: "1"
    },
    dataUser:{
        allowance:0,
        balanceOf:0,
    },
    dataBuyVouchers: null,
    email:"",
    phoneNumber:"",
    fillAmount: 1,
    isUpdate: false,
    brandId: null,
    ListBalance:[{
            chainId:56,
            balanceOf:0,
        },
        {
            chainId:1975,
            balanceOf:0,
        }
    ],
    listProvince:province,
    tokenSupport: [],
    tokenSupportBalance: [],
    activeTokenPayment: ""
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchActivePrice, (state, action) => {
            state.price = action.payload.price
        })
        .addCase(fetchActiveBrand, (state, action) => {
            state.product = action.payload.product
        })
        .addCase(fetchStatusScreen, (state, action) => {
            state.isActiveScreen = action.payload.isActiveScreen
        })
        .addCase(fetchActiveCategory, (state, action) => {
            state.category = action.payload.category
        })
        .addCase(fetchListCategory, (state, action) => {
            state.ListCategory = action.payload.ListCategory
        })
        .addCase(getStatusLoading, (state: global, action: PayloadAction<{ isLoading: boolean }>) => {
            state.isLoading = action.payload.isLoading;
        })
        .addCase(fetchPublicBrand, (state, action) => {
            state.ListBrand = action.payload.ListBrand
        })
        .addCase(fetchProvinceId, (state, action) => {
            state.cityId = action.payload.cityId
        })
        .addCase(fetchListProvinceId, (state, action) => {
            state.ListProvinceId = action.payload.ListProvinceId
        })
        .addCase(fetchDetailBrand, (state, action) => {
            state.brandItem = action.payload.brandItem
        })
        .addCase(fetchDataVouchers, (state, action) => {
            state.dataVouchers = action.payload.dataVouchers
        })
        .addCase(fetchDataUser, (state, action) => {
            state.dataUser = action.payload.dataUser
        })
        .addCase(fetchBuyVouchers, (state, action) => {
            state.dataBuyVouchers = action.payload.dataBuyVouchers
        })
        .addCase(fetchFillPhoneNumber, (state, action) => {
            state.phoneNumber = action.payload.phoneNumber
        })
        .addCase(fetchFillEmail, (state, action) => {
            state.email = action.payload.email
        })
        .addCase(fetchFillAmount, (state, action) => {
            state.fillAmount = action.payload.fillAmount
        })
        .addCase(fetchIsUpdateOrder, (state, action) => {
            state.isUpdate = action.payload.isUpdate
        })
        .addCase(fetchListProvinceByBrandId, (state, action) => {
            state.brandId = action.payload.brandId
        })
        .addCase(fetchListProvinceByBrand, (state, action) => {
            state.ListProvinceByBrand = action.payload.ListProvinceByBrand
        })
        .addCase(fetchDataUserByChain, (state, action) => {
            state.ListBalance = action.payload.ListBalance
        })
        .addCase(fetchListProvince, (state, action) => {
            state.listProvince = action.payload.listProvince
        })
        .addCase(fetchTokenSupport, (state, action) => {
            state.tokenSupport = action.payload.tokenSupport
        })
        .addCase(fetchTokenSupportBalance, (state, action) => {
            state.tokenSupportBalance = action.payload.tokenSupportBalance
        })
        .addCase(fetchActiveTokenPayment, (state, action) => {
            state.activeTokenPayment = action.payload.activeTokenPayment
        })
) 