/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from "react";
import { Flex } from "../Box";
import isTouchDevice from "../../util/isTouchDevice";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import MenuItem from "../MenuItem/MenuItem";
import { ChevronDownIcon } from "../Svg";
import { MenuItemsProps } from "./types";

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
    items = [],
    activeItem,
    activeSubItem,
    ...props
}) => {
    return (
        <Flex {...props}>
            {items.map(({ label, items: menuItems = [], href, icon, disabled }) => {
                const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
                const isActive = activeItem === href;
                const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
                const Icon = icon;
                const hasMenuItems = menuItems.length > 1;
                return (
                    <DropdownMenu
                        key={`${label}#${href}`}
                        items={menuItems}
                        py={1}
                        activeItem={activeSubItem}
                        isDisabled={disabled}
                    >
                        <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor} isDisabled={disabled}>
                            {label ||
                                (icon && createElement(Icon as any, { color: isActive ? "secondary" : "textSubtle" }))}
                            {/* { hasMenuItems && 
                <ChevronDownIcon/>
              } */}
                        </MenuItem>
                    </DropdownMenu>
                );
            })}
        </Flex>
    );
};

export default memo(MenuItems);
