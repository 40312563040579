import { createReducer } from '@reduxjs/toolkit'
import { getPageActive, getPageItem } from 'state/myvouchers/actions'
import { fetchLoading, fetchHistory, fetchDataResend, fetchDataReVerify, fetchDataInfoUser } from './actions'
import { DataInfoUser, HistoryItem, OrdersItems } from './type'

interface global {
    isLoading: boolean,
    dataHistory: HistoryItem,
    pageActive: number,
    pageItem: number,
    orderResendId:OrdersItems,
    orderVerify:OrdersItems,
    dataInfoUser: DataInfoUser,
    orders:OrdersItems,
}

export const initialState: global = {
    isLoading: false,
    dataHistory: null,
    pageActive: 0,
    pageItem: 0,
    orderResendId: null,
    orderVerify:null,
    dataInfoUser: {
        email:'',
        phone:'',
    },
    orders:null,
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchHistory, (state, action) => {
            state.dataHistory = action.payload.dataHistory
        })
        .addCase(getPageActive, (state, action) => {
            state.pageActive = action.payload.pageActive
        })
        .addCase(getPageItem, (state, action) => {
            state.pageItem = action.payload.pageItem
        })
        .addCase(fetchDataResend, (state, action) => {
            state.orderResendId = action.payload.orderResendId
        })
        .addCase(fetchDataReVerify, (state, action) => {
            state.orderVerify = action.payload.orderVerify
        })
        .addCase(fetchDataInfoUser, (state, action) => {
            state.dataInfoUser = action.payload.dataInfoUser
        })
)