import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { onusMainnetTokens } from '@pancakeswap/tokens'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Skeleton, Text } from '@pancakeswap/uikit'
import { useBalance, useContract, useContractRead, useDisconnect } from '@thirdweb-dev/react'
import { NATIVE_TOKEN_ADDRESS } from "@thirdweb-dev/sdk"
import BigNumber from 'bignumber.js'
import { ChainLogo } from 'components/Logo/ChainLogo'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

const COLORS = {
    ETH: '#627EEA',
    BNB: '#14151A',
}

interface WalletInfoProps {
    hasLowNativeBalance?: boolean
    switchView: (newIndex: number) => void
    onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
    const { t } = useTranslation()
    const { account } = useActiveWeb3React()
    const disconnect = useDisconnect();
    const { data, isLoading } = useBalance(NATIVE_TOKEN_ADDRESS);

    const handleLogout = () => {
        onDismiss?.()
        disconnect()
    }

    const { contract } = useContract(onusMainnetTokens?.vndc?.address);
    const { data: vndc, isLoading: vndcLoading, error } = useContractRead(
        contract,
        "balanceOf",
        [account],
    );
    return (
        <>
            <Text color="text" textTransform="uppercase" fontWeight="bold" mb="8px" mt="0.5rem">
                Địa chỉ ví 
            </Text>
            <CopyAddress account={account} mb="24px" mt="0.5rem"/>
            <Box mb="24px">
                <Flex justifyContent="space-between" alignItems="center" mb="8px">
                    <Flex bg={COLORS.BNB} borderRadius="16px" pl="8px" pr="8px" py="4px" alignItems="center">
                        <ChainLogo chainId={ChainId.ONUS} />
                        <Text color="white" ml="4px">
                            Onus Chain
                        </Text>
                    </Flex>
                    <LinkExternal href={getBlockExploreLink(account, 'address', ChainId.ONUS)}>
                        {getBlockExploreName(ChainId.ONUS)}
                    </LinkExternal>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mt="0.5rem">
                    <Text bold>Số dư ONUS</Text>
                    {isLoading ? (
                            <Skeleton height="22px" width="60px" />
                    ) : (
                            <Text>{formatBigNumber(data?.value, 6)}</Text>
                    )}
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mt="0.5rem">
                    <Text bold>Số dư VNDC</Text>
                    {(vndcLoading || error ) ? (
                            <Skeleton height="22px" width="60px" />
                    ) : (
                            <Text>
                                { new BigNumber(vndc?.toString()).isNaN() ?
                                    "0"
                                :
                                    Number(vndc?.toString()).toLocaleString("en", {minimumFractionDigits: 0,maximumFractionDigits: 0})
                                }
                            </Text>
                    )}
                </Flex>
            </Box>
            <Button variant="secondary" width="100%" onClick={handleLogout}>
                Ngắt kết nối ví
            </Button>
        </>
    )
}

export default WalletInfo
