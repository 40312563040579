import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg viewBox="0 0 55 54" fill="none" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4715 1.16022C25.3155 -0.386739 28.7497 -0.386739 31.5936 1.16022L48.0809 10.1285C51.1529 11.7994 53.0651 15.0165 53.0651 18.5135V35.4865C53.0651 38.9835 51.1529 42.2006 48.0809 43.8715L31.5936 52.8398C28.7497 54.3867 25.3155 54.3867 22.4715 52.8398L5.98424 43.8715C2.91228 42.2006 1 38.9835 1 35.4865L1 18.5135C1 15.0165 2.91228 11.7994 5.98424 10.1285L22.4715 1.16022Z" fill="#F7931A"/>
        <path d="M41.2267 33.3333C40.7867 36.88 37.88 39.7867 34.3333 40.2267C32.1867 40.4933 30.1867 39.9067 28.6267 38.76C27.7333 38.1067 27.9467 36.72 29.0133 36.4C33.0267 35.1867 36.1867 32.0133 37.4133 28C37.7333 26.9467 39.12 26.7333 39.7733 27.6133C40.9067 29.1867 41.4933 31.1867 41.2267 33.3333Z" fill="white"/>
        <path d="M25.32 13.6667C19.44 13.6667 14.6667 18.44 14.6667 24.32C14.6667 30.2 19.44 34.9733 25.32 34.9733C31.2 34.9733 35.9733 30.2 35.9733 24.32C35.96 18.44 31.2 13.6667 25.32 13.6667ZM24.0667 22.8267L27.28 23.9467C28.44 24.36 29 25.1733 29 26.4267C29 27.8667 27.8533 29.0533 26.4533 29.0533H26.3333V29.12C26.3333 29.6667 25.88 30.12 25.3333 30.12C24.7867 30.12 24.3333 29.6667 24.3333 29.12V29.04C22.8533 28.9733 21.6667 27.7333 21.6667 26.1867C21.6667 25.64 22.12 25.1867 22.6667 25.1867C23.2133 25.1867 23.6667 25.64 23.6667 26.1867C23.6667 26.6667 24.0133 27.0533 24.44 27.0533H26.44C26.7467 27.0533 26.9867 26.7733 26.9867 26.4267C26.9867 25.96 26.9067 25.9333 26.6 25.8267L23.3867 24.7067C22.24 24.3067 21.6667 23.4933 21.6667 22.2267C21.6667 20.7867 22.8133 19.6 24.2133 19.6H24.3333V19.5467C24.3333 19 24.7867 18.5467 25.3333 18.5467C25.88 18.5467 26.3333 19 26.3333 19.5467V19.6267C27.8133 19.6933 29 20.9333 29 22.48C29 23.0267 28.5467 23.48 28 23.48C27.4533 23.48 27 23.0267 27 22.48C27 22 26.6533 21.6133 26.2267 21.6133H24.2267C23.92 21.6133 23.68 21.8933 23.68 22.24C23.6667 22.6933 23.7467 22.72 24.0667 22.8267Z" fill="white"/>
    </Svg>
);

export default Icon;
