import styled, { css, keyframes } from "styled-components";
import { space } from "styled-system";
import getThemeValue from "../../util/getThemeValue";
import { SvgProps } from "./types";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const spinStyle = css`
    animation: ${rotate} 2s linear infinite;
`;

const Svg = styled.svg<SvgProps>`
    align-self: center; // Safari fix
    fill: ${({ theme, color }) => getThemeValue(theme, `colors.${color}`, color)};
    flex-shrink: 0;
    ${({ spin }) => spin && spinStyle};
    ${space};

    // Safari fix
    @supports (-webkit-text-size-adjust: none) and (not (-ms-accelerator: true)) and (not (-moz-appearance: none)) {
        filter: none !important;
    }
`;

Svg.defaultProps = {
    color: "text",
    width: "20px",
    xmlns: "http://www.w3.org/2000/svg",
    spin: false,
};

export default Svg;
