import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

interface Props extends SvgProps {
    color: string
}
const Icon: React.FC<React.PropsWithChildren<Props>> = (props) => {
    return (
        <Svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={props?.color}
                d="M19.2398 25.0933C18.9865 25.0933 18.7332 25 18.5332 24.8C18.1465 24.4133 18.1465 23.7733 18.5332 23.3867L25.9198 16L18.5332 8.61334C18.1465 8.22667 18.1465 7.58667 18.5332 7.2C18.9198 6.81334 19.5598 6.81334 19.9465 7.2L28.0398 15.2933C28.4265 15.68 28.4265 16.32 28.0398 16.7067L19.9465 24.8C19.7465 25 19.4932 25.0933 19.2398 25.0933Z"
            />
            <path
                fill={props?.color}
                d="M27.1065 17H4.6665C4.11984 17 3.6665 16.5467 3.6665 16C3.6665 15.4533 4.11984 15 4.6665 15H27.1065C27.6532 15 28.1065 15.4533 28.1065 16C28.1065 16.5467 27.6532 17 27.1065 17Z"
            />
        </Svg>
    );
};

export default Icon;
