import { scales, variants } from "./types";

export const scaleVariants = {
    [scales.MD]: {
        height: "48px",
        padding: "0 24px",
    },
    [scales.SM]: {
        height: "32px",
        padding: "0 16px",
    },
    [scales.XS]: {
        height: "20px",
        fontSize: "12px",
        padding: "0 8px",
    },
};

export const styleVariants = {
    [variants.PRIMARY]: {
        background: "linear-gradient(119deg, #FA9406 0%, #FFBD1D 100%);",
        color: "white",
    },
    [variants.SECONDARY]: {
        background: "transparent",
        border: "2px solid",
        borderColor: "gold",
        boxShadow: "none",
        color: "gold",
    },
    [variants.TERTIARY]: {
        background: "transparent",
        color: "#23262F",
        border: "2px solid",
        borderColor: "silver",
        ":hover": {
            background: "linear-gradient(119deg, #FA9406 0%, #FFBD1D 100%);",
            color: "white",
        },
    },
    [variants.SUBTLE]: {
        backgroundColor: "textSubtle",
        color: "backgroundAlt",
    },
    [variants.DANGER]: {
        background: "linear-gradient(119deg, #FA9406 0%, #FFBD1D 100%);",
        color: "white",
        borderRadius:"40px"
    },
    [variants.SUCCESS]: {
        backgroundColor: "success",
        color: "white",
    },
    [variants.TEXT]: {
        backgroundColor: "transparent",
        color: "text",
        boxShadow: "none",
    },
    [variants.LIGHT]: {
        backgroundColor: "input",
        color: "textSubtle",
        boxShadow: "none",
    },
};
