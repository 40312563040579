import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="0 0 60 60" {...props} style={{ fill: "none" }}>
            <path d="M26.9749 37.9252C26.4749 37.9252 25.9999 37.7252 25.6499 37.3752L19.5999 31.3252C18.8749 30.6002 18.8749 29.4002 19.5999 28.6752C20.3249 27.9502 21.5249 27.9502 22.2499 28.6752L26.9749 33.4002L37.7249 22.6502C38.4499 21.9252 39.6499 21.9252 40.3749 22.6502C41.0999 23.3752 41.0999 24.5752 40.3749 25.3002L28.2999 37.3752C27.9499 37.7252 27.4749 37.9252 26.9749 37.9252Z" fill="#17E344"/>
            <path d="M30.0002 56.875C28.4252 56.875 26.8502 56.35 25.6252 55.3L21.6752 51.9C21.2752 51.55 20.2752 51.2 19.7502 51.2H15.4502C11.7502 51.2 8.7502 48.2 8.7502 44.5V40.225C8.7502 39.7 8.4002 38.725 8.0502 38.325L4.6752 34.35C2.6252 31.925 2.6252 28.1 4.6752 25.675L8.0502 21.7C8.4002 21.3 8.7502 20.325 8.7502 19.8V15.5C8.7502 11.8 11.7502 8.8 15.4502 8.8H19.7752C20.3002 8.8 21.3002 8.425 21.7002 8.1L25.6502 4.7C28.1002 2.6 31.9252 2.6 34.3752 4.7L38.3252 8.1C38.7252 8.45 39.7252 8.8 40.2502 8.8H44.5002C48.2002 8.8 51.2002 11.8 51.2002 15.5V19.75C51.2002 20.275 51.5752 21.275 51.9252 21.675L55.3252 25.625C57.4252 28.075 57.4252 31.9 55.3252 34.35L51.9252 38.3C51.5752 38.7 51.2002 39.7 51.2002 40.225V44.475C51.2002 48.175 48.2002 51.175 44.5002 51.175H40.2502C39.7252 51.175 38.7252 51.55 38.3252 51.875L34.3752 55.275C33.1502 56.35 31.5752 56.875 30.0002 56.875ZM15.4502 12.55C13.8252 12.55 12.5002 13.875 12.5002 15.5V19.775C12.5002 21.2 11.8252 23.025 10.9002 24.1L7.5252 28.075C6.6502 29.1 6.6502 30.875 7.5252 31.9L10.9002 35.875C11.8252 36.975 12.5002 38.775 12.5002 40.2V44.475C12.5002 46.1 13.8252 47.425 15.4502 47.425H19.7752C21.2252 47.425 23.0502 48.1 24.1502 49.05L28.1002 52.45C29.1252 53.325 30.9252 53.325 31.9502 52.45L35.9002 49.05C37.0002 48.125 38.8252 47.425 40.2752 47.425H44.5252C46.1502 47.425 47.4752 46.1 47.4752 44.475V40.225C47.4752 38.775 48.1502 36.95 49.1002 35.85L52.5002 31.9C53.3752 30.875 53.3752 29.075 52.5002 28.05L49.1002 24.1C48.1502 23 47.4752 21.175 47.4752 19.725V15.5C47.4752 13.875 46.1502 12.55 44.5252 12.55H40.2752C38.8252 12.55 37.0002 11.875 35.9002 10.925L31.9502 7.525C30.9252 6.65 29.1252 6.65 28.1002 7.525L24.1502 10.95C23.0502 11.875 21.2002 12.55 19.7752 12.55H15.4502Z" fill="#17E344"/>

        </Svg>
    );
};

export default Icon;
