import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 508 105" fill="none">
    <path d="M502.797 52.9398C502.797 55.6633 504.992 58 507.748 58V98.3324C507.748 102.015 504.727 105 501 105H6.74842C3.02124 105 2.14577e-06 102.015 2.14577e-06 98.3324L0 58C2.75652 58 4.99198 55.7913 4.99198 53.0678C4.99198 50.3443 2.75652 48.1357 0 48.1357V6.65926C0 2.98504 3.02124 0 6.74842 0H501C504.727 0 507.748 2.98504 507.748 6.65926V48.1357C504.992 48.1357 502.797 50.2164 502.797 52.9398Z" fill="url(#paint0_linear_482_17834)"/>
    <defs>
        <linearGradient id="paint0_linear_482_17834" x1="0.000234172" y1="4.82695e-05" x2="359" y2="74.0002" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0167F4"/>
        <stop offset="1" stopColor="#012179"/>
        </linearGradient>
    </defs>
    </Svg>
);

export default Icon;
