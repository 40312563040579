import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { bscTokens, bscTestnetTokens } from '@pancakeswap/tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
    [VaultKey.CakeVaultV1]: {
        name: <Trans>Auto CAKE</Trans>,
        description: <Trans>Automatic restaking</Trans>,
        autoCompoundFrequency: 5000,
        gasLimit: 380000,
        tokenImage: {
            primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
            secondarySrc: '/images/tokens/autorenew.svg',
        },
    },
    [VaultKey.CakeVault]: {
        name: <Trans>Stake CAKE</Trans>,
        description: <Trans>Stake, Earn – And more!</Trans>,
        autoCompoundFrequency: 5000,
        gasLimit: 500000,
        tokenImage: {
            primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
            secondarySrc: '/images/tokens/autorenew.svg',
        },
    },
    [VaultKey.CakeFlexibleSideVault]: {
        name: <Trans>Flexible CAKE</Trans>,
        description: <Trans>Flexible staking on the side.</Trans>,
        autoCompoundFrequency: 5000,
        gasLimit: 500000,
        tokenImage: {
            primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
            secondarySrc: '/images/tokens/autorenew.svg',
        },
    },
    [VaultKey.IfoPool]: {
        name: 'IFO CAKE',
        description: <Trans>Stake CAKE to participate in IFOs</Trans>,
        autoCompoundFrequency: 1,
        gasLimit: 500000,
        tokenImage: {
            primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
            secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
        },
    },
} as const

export const livePools: SerializedPoolConfig[] = [
    {
        sousId: 0,
        stakingToken: bscTokens.bami,
        earningToken: bscTokens.bami,
        contractAddress: {
            97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
            56: '0xb4ef17d6230529bb2d92810eDc983a1A9FEC36e5',
        },
        poolCategory: PoolCategory.CORE,
        tokenPerBlock: '45000000000000000',
        isFinished: false,
    },
].map((p) => ({
    ...p,
    stakingToken: p.stakingToken.serialize,
    earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = []

export default [...livePools, ...finishedPools]
