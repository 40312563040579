import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 32 32" {...props}>
            <path d="M16.0001 18.8933C13.1601 18.8933 10.8401 16.5867 10.8401 13.7333C10.8401 10.88 13.1601 8.58667 16.0001 8.58667C18.8401 8.58667 21.1601 10.8933 21.1601 13.7467C21.1601 16.6 18.8401 18.8933 16.0001 18.8933ZM16.0001 10.5867C14.2668 10.5867 12.8401 12 12.8401 13.7467C12.8401 15.4933 14.2534 16.9067 16.0001 16.9067C17.7468 16.9067 19.1601 15.4933 19.1601 13.7467C19.1601 12 17.7334 10.5867 16.0001 10.5867Z" fill="#292D32"/>
            <path d="M16 30.3466C14.0267 30.3466 12.04 29.6 10.4933 28.12C6.55999 24.3333 2.21332 18.2933 3.85332 11.1066C5.33332 4.58663 11.0267 1.66663 16 1.66663C16 1.66663 16 1.66663 16.0133 1.66663C20.9867 1.66663 26.68 4.58663 28.16 11.12C29.7867 18.3066 25.44 24.3333 21.5067 28.12C19.96 29.6 17.9733 30.3466 16 30.3466ZM16 3.66663C12.12 3.66663 7.13332 5.73329 5.81332 11.5466C4.37332 17.8266 8.31999 23.24 11.8933 26.6666C14.2 28.8933 17.8133 28.8933 20.12 26.6666C23.68 23.24 27.6267 17.8266 26.2133 11.5466C24.88 5.73329 19.88 3.66663 16 3.66663Z" fill="#292D32"/>
        </Svg>
    );
};

export default Icon;



