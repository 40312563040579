import { SerializedWrappedToken } from '@pancakeswap/tokens'

export type FarmsDynamicDataResult = {
    tokenAmountTotal: string
    quoteTokenAmountTotal: string
    lpTotalSupply: string
    lpTotalInQuoteToken: string
    tokenPriceVsQuote: string
    poolWeight: string
    multiplier: string
}
export type FarmData = SerializedFarmConfig & FarmsDynamicDataResult

export interface FarmConfigBaseProps {
    pid: number
    v1pid?: number
    vaultPid?: number
    lpSymbol: string
    lpAddress: string
    multiplier?: string
    isCommunity?: boolean
    auctionHostingStartSeconds?: number
    auctionHostingEndDate?: string
    dual?: {
        rewardPerBlock: number
        earnLabel: string
        endBlock: number
    }
    boosted?: boolean
}
export interface SerializedStableFarmConfig extends SerializedClassicFarmConfig {
    stableSwapAddress?: string
    infoStableSwapAddress?: string
}

export interface SerializedClassicFarmConfig extends FarmConfigBaseProps {
    token: SerializedWrappedToken
    quoteToken: SerializedWrappedToken
}

export type SerializedFarmConfig = SerializedStableFarmConfig & SerializedClassicFarmConfig

export interface SerializedFarmPublicData extends SerializedClassicFarmConfig {
    lpTokenPrice?: string
    tokenPriceBusd?: string
    quoteTokenPriceBusd?: string
    tokenAmountTotal?: string
    quoteTokenAmountTotal?: string
    lpTotalInQuoteToken?: string
    lpTotalSupply?: string
    tokenPriceVsQuote?: string
    poolWeight?: string
    boosted?: boolean
    infoStableSwapAddress?: string
}

export interface AprMap {
    [key: string]: number
}

export function isStableFarm(farmConfig: SerializedFarmConfig): farmConfig is SerializedStableFarmConfig {
    return 'stableSwapAddress' in farmConfig && typeof farmConfig.stableSwapAddress === 'string'
}
