import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'

export const BSC_BLOCK_TIME = 3
export const BASE_BSC_SCAN_URLS = {
    [ChainId.BSC]: 'https://bscscan.com',
    [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
}
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 0.0001735
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR

export const CAKE_PER_BLOCKV2 = new BigNumber(0.0001735)
export const BLOCKS_PER_YEARV2 = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2.times(BLOCKS_PER_YEARV2)

export const BASE_URL = 'https://giftcards.vn/'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const startTimeStake = 1662469200000
export const LIMIT_VOTING = 100
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const AMOUNT_FULL_POOL = '2500000'

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const BASE_BSC_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const URL = process.env.NEXT_PUBLIC_API
export const TRANDING_FEE = 3
export const TRANSFER_FEE = 5

export const MAX_AMOUNT_TRANSFER_NFT = 50

export const NFT_TYPE = {
    DIAMOND: 4,
    GOLD: 3,
    SILVER: 2,
    BRONZE: 1,
}


export const USED = {
    color: "#00C708",
    status: "Đã sử dụng"
}
export const USE = {
    color: "#FCA912",
    status:"Chưa sử dụng"
}
export const EXPIRED = {
    color: "#FF0001",
    status: "Đã hết hạn"
}
export const DEFAULT = {
    color: "#000",
    status: "Không xác định"
}
// for cookies name 
export const KEY_LOGIN = "keylogin"
export const PAGE_SIZE = 8
export const MAXIMUM_SHOW_BRAND = 30
export const MORE_BRAND = 20
export const UNIT_TOKEN = 'đ'

// for localStorage name
export const DATA_HISTORY = "data_history"

export const TYPE_GIFTCARD = {
    PRICE_VOUCHER: [1, 2, 3, 6, 7, 8],
    FEATURED_PRODUCT: [4, 5, 7, 9, 10, 11, 12, 14, 15, 16, 19]
}

export const STATUS_VOURCHER = {
    NOT_USED: 1,
    USED: 2,
    EXPIRED: 3
}

export const STATUS_SELECTION = [
    {
        label: "Tất cả",
        value: "",
    },
    {
        label: "Chưa sử dụng",
        value: STATUS_VOURCHER?.NOT_USED,     
    },
    {
        label: "Đã sử dụng",
        value: STATUS_VOURCHER?.USED,     
    },
    {
        label: "Hết hạn",
        value: STATUS_VOURCHER?.EXPIRED,
    },
]

export const SUCCESSFUL = {
    color: "#00C708",
    status: "Gửi email thành công"
}
export const PROCESS = {
    color: "#FCA912",
    status:"Đang xử lý"
}
export const FAIL = {
    color: "#FF0001",
    status: "Gửi email không thành công"
}
export const INVALID = {
    color: "#FF0001",
    status: "Gửi email không thành công"
}

export const FAQ_TYPE = {
    STORIES: "stories",
    USER_MANUAL: "user_manual",
    ACCOUNT:"account",
    ORDER_AND_PAYMENT:"order_and_payment",
    DIGITAL_GIFT_VOUCHERS: "digital_gift_vouchers",
    CRYPTOCURRENCY_PAYMENT: "cryptocurrency_payment"
}

export const FAQ_TABS = [
    {
        name: "Câu chuyện Gift cards.",
        value: FAQ_TYPE.STORIES
    },
    {
        name: "Hướng dẫn bắt đầu Gift cards.",
        value: FAQ_TYPE.USER_MANUAL
    },
    {
        name: "Tài khoản Gift cards.",
        value: FAQ_TYPE.ACCOUNT
    },
    {
        name: "Thẻ quà tặng & Phiếu quà tặng kỹ thuật số",
        value: FAQ_TYPE.DIGITAL_GIFT_VOUCHERS
    },
    {
        name: "Sử dụng tiền điện tử thanh toán trên Gift cards dễ dàng hơn.",
        value: FAQ_TYPE.CRYPTOCURRENCY_PAYMENT
    },
    {
        name: "Đặt hàng/thanh toán trên Gift cards.",
        value: FAQ_TYPE.ORDER_AND_PAYMENT
    }
]