import { ChainId } from "@pancakeswap/sdk"
import { Binance, BinanceTestnet, OnusChain } from "@thirdweb-dev/chains"
import { useActiveChainId, useLocalNetworkChain } from "hooks/useActiveChainId"
import { atom, useAtomValue } from 'jotai'
import { isChainSupported } from "./wagmi"


export const getActiveChain = (chainId:number) => {
    switch (chainId) {
        case ChainId.BSC:
            return Binance
        case ChainId.ONUS:
            return OnusChain
        case ChainId.BSC_TESTNET:
            return BinanceTestnet
        default:
            return OnusChain
    }
}

const queryChainIdAtom = atom(-1) // -1 unload, 0 no chainId on query

queryChainIdAtom.onMount = (set) => {
    const params = new URL(window.location.href).searchParams
    const c = params.get('chainId')
    if (isChainSupported(+c)) {
        set(+c)
    } else {
        set(0)
    }
}

export const useActiveChainProvider = () => {
    const localChainId = useLocalNetworkChain()
    const queryChainId = useAtomValue(queryChainIdAtom)
    const chainId = localChainId ?? (queryChainId >= 0 ? ChainId.ONUS : undefined)
    switch (chainId || ChainId.BSC_TESTNET) {
        case ChainId.BSC:
            return Binance
        case ChainId.ONUS:
            return OnusChain
        case ChainId.BSC_TESTNET:
            return BinanceTestnet
        default:
            return OnusChain
    }
}