import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M13 11.9004H7C6.59 11.9004 6.25 11.5604 6.25 11.1504C6.25 10.7404 6.59 10.4004 7 10.4004H13C13.41 10.4004 13.75 10.7404 13.75 11.1504C13.75 11.5604 13.41 11.9004 13 11.9004Z"
            fill="black"
        />
        <path
            d="M2 11.8998C1.59 11.8998 1.25 11.5598 1.25 11.1498V6.52985C1.25 4.07985 3.24 2.08984 5.69 2.08984H11.31C13.88 2.08984 15.75 3.79984 15.75 6.14984C15.75 6.55984 15.41 6.89984 15 6.89984C14.59 6.89984 14.25 6.55984 14.25 6.14984C14.25 4.38984 12.72 3.58984 11.31 3.58984H5.69C4.07 3.58984 2.75 4.90985 2.75 6.52985V11.1498C2.75 11.5698 2.41 11.8998 2 11.8998Z"
            fill="black"
        />
        <path
            d="M16 21.9004H6C3.38 21.9004 1.25 19.7704 1.25 17.1504V10.1504C1.25 7.53039 3.38 5.40039 6 5.40039H16C18.62 5.40039 20.75 7.53039 20.75 10.1504V11.6003C20.75 12.0103 20.41 12.3503 20 12.3503H18.92C18.57 12.3503 18.25 12.4803 18.02 12.7203L18.01 12.7303C17.68 13.0603 17.55 13.5203 17.67 13.9703C17.82 14.5303 18.41 14.9404 19.07 14.9404H20C20.41 14.9404 20.75 15.2804 20.75 15.6904V17.1404C20.75 19.7704 18.62 21.9004 16 21.9004ZM6 6.90039C4.21 6.90039 2.75 8.36039 2.75 10.1504V17.1504C2.75 18.9404 4.21 20.4004 6 20.4004H16C17.79 20.4004 19.25 18.9404 19.25 17.1504V16.4504H19.07C17.72 16.4504 16.55 15.6004 16.22 14.3704C15.96 13.4004 16.24 12.3703 16.95 11.6703C17.47 11.1403 18.17 10.8503 18.92 10.8503H19.25V10.1504C19.25 8.36039 17.79 6.90039 16 6.90039H6Z"
            fill="black"
        />
        <path
            d="M20.9699 16.4496H19.0399C17.5299 16.4496 16.2499 15.3296 16.1299 13.8896C16.0499 13.0596 16.3499 12.2496 16.9499 11.6596C17.4499 11.1396 18.1599 10.8496 18.9099 10.8496H20.9599C21.9399 10.8496 22.7399 11.6396 22.7399 12.6196V14.6796C22.7499 15.6596 21.9499 16.4496 20.9699 16.4496ZM18.9199 12.3496C18.5699 12.3496 18.2499 12.4796 18.0199 12.7196C17.7299 12.9996 17.5899 13.3796 17.6299 13.7596C17.6799 14.4196 18.3199 14.9496 19.0399 14.9496H20.9699C21.1199 14.9496 21.2499 14.8296 21.2499 14.6796V12.6196C21.2499 12.4696 21.1199 12.3496 20.9699 12.3496H18.9199Z"
            fill="black"
        />
    </Svg>
);

export default Icon;
