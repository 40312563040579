import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { fetchPublicNonce, fetchDataUser, fetchEmailUser, getStatusLoading, fetchPhoneUser } from './actions'
import { DataUser} from './type'

interface global {
    dataNonce:string|null,
    dataUser:DataUser,
    email:string,
    phone:string
    isLoading:boolean,
}
export const initialState: global = {
    dataNonce:"",
    dataUser: null,
    email:'',
    phone: '',
    isLoading: false,
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchPublicNonce, (state, action) => {
            state.dataNonce = action.payload.dataNonce
        })
        .addCase(fetchDataUser, (state, action) => {
            state.dataUser = action.payload.dataUser
        })
        .addCase(fetchEmailUser, (state, action) => {
            state.email = action.payload.email
        })
        .addCase(fetchPhoneUser, (state, action) => {
            state.phone = action.payload.phone
        })
        
        .addCase(getStatusLoading, (state: global, action: PayloadAction<{ isLoading: boolean }>) => {
            state.isLoading = action.payload.isLoading;
        })
)