import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 25" {...props}>
            <path d="M14.9303 2.56592V8.46592C14.9303 8.90592 14.4103 9.12592 14.0903 8.83592L12.3403 7.22592C12.1503 7.04592 11.8503 7.04592 11.6603 7.22592L9.91031 8.82592C9.59031 9.12592 9.07031 8.89592 9.07031 8.46592V2.56592C9.07031 2.28592 9.29031 2.06592 9.57031 2.06592H14.4303C14.7103 2.06592 14.9303 2.28592 14.9303 2.56592Z" fill="#FEB318"/>
            <path d="M16.98 2.12593C16.69 2.08593 16.43 2.33593 16.43 2.62593V8.64593C16.43 9.40593 15.98 10.0959 15.28 10.4059C14.58 10.7059 13.77 10.5759 13.21 10.0559L12.34 9.25593C12.15 9.07593 11.86 9.07593 11.66 9.25593L10.79 10.0559C10.43 10.3959 9.96 10.5659 9.49 10.5659C9.23 10.5659 8.97 10.5159 8.72 10.4059C8.02 10.0959 7.57 9.40593 7.57 8.64593V2.62593C7.57 2.33593 7.31 2.08593 7.02 2.12593C4.22 2.47593 3 4.36593 3 7.06593V17.0659C3 20.0659 4.5 22.0659 8 22.0659H16C19.5 22.0659 21 20.0659 21 17.0659V7.06593C21 4.36593 19.78 2.47593 16.98 2.12593ZM17.5 18.8159H9C8.59 18.8159 8.25 18.4759 8.25 18.0659C8.25 17.6559 8.59 17.3159 9 17.3159H17.5C17.91 17.3159 18.25 17.6559 18.25 18.0659C18.25 18.4759 17.91 18.8159 17.5 18.8159ZM17.5 14.8159H13.25C12.84 14.8159 12.5 14.4759 12.5 14.0659C12.5 13.6559 12.84 13.3159 13.25 13.3159H17.5C17.91 13.3159 18.25 13.6559 18.25 14.0659C18.25 14.4759 17.91 14.8159 17.5 14.8159Z" fill="#FEB318"/>
        </Svg>
    );
};

export default Icon;



