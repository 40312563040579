import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg viewBox="0 0 139 52" fill="none" {...props}>
        <path d="M133.464 25.872C133.464 28.5955 135.659 30.9322 138.416 30.9322L138.408 45.3324C138.408 49.015 135.386 52 131.659 52L7.45643 52C3.72925 52 0.708008 49.015 0.708008 45.3324L0.716026 30.9322C3.47254 30.9322 5.70801 28.7235 5.70801 26C5.70801 23.2765 3.47254 21.0678 0.716026 21.0678L0.708008 6.65926C0.708008 2.98504 3.72925 0 7.45643 0H131.659C135.386 0 138.408 2.98504 138.408 6.65926L138.416 21.0678C135.659 21.0678 133.464 23.1485 133.464 25.872Z" fill="url(#paint0_linear_103_35334)"/>
        <defs>
        <linearGradient id="paint0_linear_103_35334" x1="128.089" y1="77.1572" x2="-1.9167" y2="-30.87" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C2C2C"/>
        <stop offset="1" stopColor="#555555"/>
        </linearGradient>
        </defs>
    </Svg>
);

export default Icon;
