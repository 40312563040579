import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 187 73" {...props}>
<mask id="mask0_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="57" y="36" width="1" height="1">
<path d="M57.398 36.2189V36.1807H57.3789L57.398 36.2189Z" fill="white"/>
</mask>
<g mask="url(#mask0_103_25010)">
<path d="M57.398 36.1807H57.3789V36.2189H57.398V36.1807Z" fill="url(#paint0_linear_103_25010)"/>
</g>
<mask id="mask1_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="15" y="22" width="27" height="32">
<path d="M41.932 30.3514L41.4219 30.0599L28.7257 22.7373L16.0842 30.0235L15.5195 30.3514V31.6447V45.5977L28.7257 53.2118L41.932 45.5977V31.6447V30.3514ZM39.7097 37.4008L28.7257 43.7397L17.76 37.4008V31.6447L18.5251 31.2075L18.8712 31.0071L28.7257 25.3239L29.5637 25.8157L32.5328 27.528L23.2429 32.9015L22.6782 33.2294L21.0024 34.1948L23.2429 35.4881L25.4834 34.1948L27.2321 33.193L34.7915 28.8395L38.5985 31.0436L29.928 36.0528L26.5035 37.9836L28.744 39.2769L39.7097 32.938V37.4008ZM28.7257 49.3501L17.76 43.0111V41.1167L28.7257 47.4557L39.6915 41.1167V43.0111L28.7257 49.3501Z" fill="white"/>
</mask>
<g mask="url(#mask1_103_25010)">
<path d="M41.9323 22.7373H15.5381V53.23H41.9323V22.7373Z" fill="url(#paint1_linear_103_25010)"/>
</g>
<mask id="mask2_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="31" y="17" width="27" height="13">
<path d="M48.5256 17.2906C47.6331 18.0556 46.7769 18.3471 45.9026 18.7114C44.3543 19.3489 41.5673 20.114 37.0317 20.114C36.0298 20.114 34.9733 20.0776 33.8804 20.0047C33.6254 20.6787 33.0789 21.4802 31.9678 22.0084L45.1011 29.6225L57.1051 22.6459L48.6714 17.1631C48.6349 17.1995 48.5803 17.2359 48.5256 17.2906Z" fill="white"/>
</mask>
<g mask="url(#mask2_103_25010)">
<path d="M31.4033 17.1632L58.5079 17.0903L59.0726 29.7683L31.968 29.8594L31.4033 17.1632Z" fill="url(#paint2_linear_103_25010)"/>
</g>
<mask id="mask3_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="1" y="16" width="25" height="13">
<path d="M1.14746 20.7522L13.4975 28.9491L25.374 22.0637C24.9186 21.8815 24.4815 21.6629 24.0807 21.3897C23.8986 21.3897 23.6982 21.4079 23.4978 21.4079C21.0387 21.4079 17.9968 20.7522 14.4812 19.4407C12.1314 18.5845 10.1277 17.7648 8.54294 16.4897L1.14746 20.7522Z" fill="white"/>
</mask>
<g mask="url(#mask3_103_25010)">
<path d="M1.11133 16.4899L25.3743 16.417L25.4108 29.5868L1.14776 29.6596L1.11133 16.4899Z" fill="url(#paint3_linear_103_25010)"/>
</g>
<mask id="mask4_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="45" y="25" width="13" height="38">
<path d="M45.1748 32.4464V62.7934L57.3974 55.6165V25.2695L45.1748 32.4464Z" fill="white"/>
</mask>
<g mask="url(#mask4_103_25010)">
<path d="M44.0996 22.7376L57.3969 22.7012L57.5062 63.2306L45.1743 62.8298L44.0996 22.7376Z" fill="url(#paint4_linear_103_25010)"/>
</g>
<mask id="mask5_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="15" y="48" width="27" height="25">
<path d="M15.5195 63.4308L28.7257 72.3746L41.9502 64.5601V48.2026L28.7257 55.8349L15.5195 48.2026V63.4308Z" fill="white"/>
</mask>
<g mask="url(#mask5_103_25010)">
<path d="M15.4648 46.8003L41.9501 47.9114L42.0048 73.5042L15.5195 72.393L15.4648 46.8003Z" fill="url(#paint5_linear_103_25010)"/>
</g>
<mask id="mask6_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="25" y="4" width="7" height="10">
<path d="M25.4834 6.70822C26.376 8.38405 27.3778 10.6974 27.7968 13.2111C27.9061 13.2111 28.0153 13.2111 28.1064 13.2111C28.325 13.2111 28.5254 13.2111 28.7258 13.2294C29.2358 11.1892 30.2558 8.82122 31.6767 6.54429L28.7258 4.83203L25.4834 6.70822Z" fill="white"/>
</mask>
<g mask="url(#mask6_103_25010)">
<path d="M25.4473 4.83218L31.677 4.81396L31.6952 13.2295L25.4837 13.2477L25.4473 4.83218Z" fill="url(#paint6_linear_103_25010)"/>
</g>
<mask id="mask7_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="22" width="14" height="41">
<path d="M0.0546464 53.1208L13.2609 62.0829V31.7177L0 22.7192L0.0546464 53.1208Z" fill="white"/>
</mask>
<g mask="url(#mask7_103_25010)">
<path d="M-0.0546875 22.7195L13.2608 22.6831L13.3701 62.0831L0.0546053 62.1742L-0.0546875 22.7195Z" fill="url(#paint7_linear_103_25010)"/>
</g>
<mask id="mask8_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="21" width="1" height="1">
<path d="M0.0546875 21.3902V21.4275L0.0919464 21.3716L0.0546875 21.3902Z" fill="white"/>
</mask>
<g mask="url(#mask8_103_25010)">
<path d="M0.0912092 21.3716H0.0546875V21.4446H0.0912092V21.3716Z" fill="url(#paint8_linear_103_25010)"/>
</g>
<mask id="mask9_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="7" y="0" width="44" height="21">
<path d="M31.6224 15.4695C31.6224 15.4695 32.3692 14.2855 32.8611 13.6297C33.4622 12.8282 34.7373 11.2253 37.0324 9.11229C39.3276 7.01751 41.8413 6.48926 43.4989 7.18145C45.1565 7.87364 46.1037 9.58589 46.0673 11.1342C46.0491 11.6989 45.7576 12.8465 45.1201 13.5022C43.6628 14.9959 40.7848 15.7974 36.6317 15.7974C35.1198 15.7974 33.4622 15.6881 31.6224 15.4695ZM11.4397 12.2818C10.6564 10.952 10.62 9.02121 11.7493 8.01936C13.3887 6.59855 15.7203 6.54391 17.469 7.69148C19.2177 8.83906 22.2597 11.6625 24.7188 17.0178C24.6095 17.036 24.482 17.036 24.3362 17.036C21.7861 17.036 13.1155 15.1234 11.4397 12.2818ZM13.0609 0.496374C11.1482 1.88075 6.88581 5.92458 7.52335 10.2052C7.74194 11.7171 8.25197 12.8465 8.98059 13.739C10.3468 15.4148 12.5144 16.3074 15.2467 17.3275C19.4727 18.894 22.6058 19.3494 24.7188 19.0761C26.5585 20.6427 29.1269 20.6427 30.6934 20.078C32.26 19.5315 31.8956 18.3293 31.6224 17.5096C39.5279 18.4204 43.5535 17.2364 45.0472 16.6171C45.8123 16.3074 46.9781 15.6334 47.6156 15.0687C48.4535 14.3583 49.2185 13.1379 49.8379 10.9703C50.949 7.10859 46.7048 1.22499 45.5208 0.587451C44.3186 -0.0500903 42.2056 -0.888002 38.1618 2.42721C34.0997 5.74243 31.2581 11.2617 30.6934 14.8502C30.6934 14.8502 30.0559 15.1234 29.8737 15.6699C29.1451 15.3966 27.3053 15.3966 25.7388 15.6699C25.8299 10.2416 21.6039 4.35805 19.8552 2.60937C18.5437 1.31607 16.4671 0.00455612 14.7002 0.00455612C14.0991 0.00455612 13.5527 0.15028 13.0609 0.496374Z" fill="white"/>
</mask>
<g mask="url(#mask9_103_25010)">
<path d="M6.83105 -0.87005L50.9307 -0.997559L50.9853 20.6424L6.8857 20.7699L6.83105 -0.87005Z" fill="url(#paint9_linear_103_25010)"/>
</g>
<mask id="mask10_103_25010" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="2" y="21" width="1" height="1">
<path d="M2.87793 21.3902V21.4275L2.91519 21.3716L2.87793 21.3902Z" fill="white"/>
</mask>
<g mask="url(#mask10_103_25010)">
<path d="M2.91445 21.3716H2.87793V21.4446H2.91445V21.3716Z" fill="url(#paint10_linear_103_25010)"/>
</g>
<path d="M78.326 18.0924H81.368L76.9234 29.7685L75.4115 33.7395L73.8996 29.7503L69.4551 18.0742H72.4788V18.0924L75.4115 25.7794L78.326 18.0924Z" fill="#1C2126"/>
<path d="M84.0273 33.7031V18.0742H86.8507V33.7031H84.0273Z" fill="#1C2126"/>
<path d="M92.3703 18.0924H101.442V20.9158H92.3703V24.4861H101.442V27.3095H92.3703V30.8797H101.442V33.7031H89.5469V18.0742L92.3703 18.0924Z" fill="#1C2126"/>
<path d="M111.497 18.0928H116.032V20.9162H111.497V33.7216H108.673V20.9162H104.138V18.0928H108.673H111.497Z" fill="#1C2126"/>
<path d="M127.799 18.0926H130.623V33.7397L127.799 30.9527L121.569 24.8141V33.7032H118.746V18.0562L121.569 20.8431L127.799 26.9817V18.0926Z" fill="#1C2126"/>
<path d="M133.318 33.7213L137.763 22.0452L139.275 18.0742L140.787 22.0634L143.628 29.5135L144.703 32.3369L145.231 33.7395H142.208L141.679 32.3369H136.889L136.36 33.7395H133.318V33.7213ZM140.586 29.4771L139.275 25.9979L137.945 29.4771H140.586Z" fill="#1C2126"/>
<path d="M159.767 20.8431L162.591 18.0562V33.7032H159.767V24.8141L157.272 27.2732L155.25 29.2587L153.228 27.2732L150.733 24.8141V33.7032H147.909V18.0562L150.733 20.8431L155.25 25.2877L159.767 20.8431Z" fill="#1C2126"/>
<path d="M72.2785 40.188H81.3498V43.0114H72.2785V46.5816H81.3498V49.405H72.2785V52.9752H81.3498V55.8351H69.4551V40.2062L72.2785 40.188Z" fill="#1C2126"/>
<path d="M92.9165 40.1881H95.9403L91.4957 51.8643L89.9838 55.8352L88.4719 51.846L84.0273 40.1699H87.0511V40.1881L89.9838 47.8751L92.9165 40.1881Z" fill="#1C2126"/>
<path d="M110.295 42.4831C111.77 43.9586 112.59 45.9259 112.59 48.0024C112.59 50.0972 111.77 52.0463 110.295 53.5217C108.819 54.9972 106.852 55.8169 104.775 55.8169C102.681 55.8169 100.732 54.9972 99.2561 53.5217C97.7806 52.0463 96.9609 50.079 96.9609 48.0024C96.9609 45.9259 97.7806 43.9586 99.2561 42.4831C100.732 41.0077 102.699 40.188 104.775 40.188C106.852 40.188 108.819 41.0077 110.295 42.4831ZM104.775 43.0296C102.025 43.0296 99.7843 45.2701 99.7843 48.0206C99.7843 50.7712 102.025 53.0117 104.775 53.0117C107.526 53.0117 109.766 50.7712 109.766 48.0206C109.748 45.2701 107.508 43.0296 104.775 43.0296Z" fill="#1C2126"/>
<path d="M115.268 49.879V40.2065H118.091V49.879C118.091 51.5912 119.494 52.9938 121.206 52.9938C122.918 52.9938 124.321 51.5912 124.321 49.879V40.2065H127.144V49.879C127.144 53.1577 124.466 55.8354 121.188 55.8354C117.945 55.8354 115.268 53.1577 115.268 49.879Z" fill="#1C2126"/>
<path d="M134.157 44.4868C133.209 45.434 132.699 46.6727 132.699 48.0024C132.699 49.3321 133.209 50.589 134.157 51.518C136.106 53.4671 139.257 53.4671 141.206 51.518L141.224 51.4998L143.228 53.5035L143.21 53.5217C141.734 54.9972 139.767 55.8169 137.69 55.8169C135.596 55.8169 133.647 54.9972 132.171 53.5217C130.696 52.0463 129.876 50.079 129.876 48.0024C129.876 45.9076 130.696 43.9586 132.171 42.4831C133.647 41.0077 135.614 40.188 137.69 40.188C139.785 40.188 141.734 41.0077 143.21 42.4831L143.228 42.5014L141.224 44.5051L141.206 44.4868C140.259 43.5396 139.02 43.0296 137.69 43.0296C136.361 43.0296 135.086 43.5396 134.157 44.4868Z" fill="#1C2126"/>
<path d="M154.958 40.188H157.782V55.8351H154.958V49.4414H148.729V55.8351H145.905V40.2062H148.729V46.5998H154.958V40.188Z" fill="#1C2126"/>
<path d="M163.319 40.188H172.391V43.0114H163.319V46.5816H172.391V49.405H163.319V52.9752H172.391V55.8351H160.496V40.2062L163.319 40.188Z" fill="#1C2126"/>
<path d="M183.466 51.1905L187 55.8354H183.448L180.26 51.6459H177.91V55.8354H175.087V40.2065H181.262C184.413 40.2065 186.982 42.7749 186.982 45.9262C186.982 48.2213 185.597 50.2979 183.466 51.1905ZM177.91 48.8042H181.262C181.389 48.8042 181.499 48.8042 181.626 48.786C183.065 48.6039 184.158 47.3834 184.158 45.9262C184.158 44.3415 182.865 43.0482 181.28 43.0482H177.91V47.5292V48.531V48.8042Z" fill="#1C2126"/>
<defs>
<linearGradient id="paint0_linear_103_25010" x1="57.3853" y1="36.1937" x2="57.4059" y2="36.1937" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint1_linear_103_25010" x1="15.5327" y1="37.9836" x2="41.9348" y2="37.9836" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="0.4847" stopColor="#A55FE2"/>
<stop offset="1" stopColor="#FF4982"/>
</linearGradient>
<linearGradient id="paint2_linear_103_25010" x1="-5.10373" y1="23.5944" x2="60.2614" y2="23.4138" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint3_linear_103_25010" x1="-0.248495" y1="23.0858" x2="57.3938" y2="22.9266" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint4_linear_103_25010" x1="-0.151622" y1="43.1295" x2="57.4832" y2="42.9703" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint5_linear_103_25010" x1="-0.113351" y1="60.2185" x2="57.5438" y2="60.0592" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint6_linear_103_25010" x1="-0.270677" y1="9.12505" x2="57.4014" y2="8.96573" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint7_linear_103_25010" x1="-0.138099" y1="42.4559" x2="57.534" y2="42.2966" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint8_linear_103_25010" x1="0.054555" y1="21.4008" x2="0.0920924" y2="21.4008" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint9_linear_103_25010" x1="-0.272639" y1="9.96508" x2="57.3994" y2="9.80576" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint10_linear_103_25010" x1="2.87449" y1="21.4008" x2="2.91203" y2="21.4008" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
</defs>
        </Svg>
    );
};

export default Icon;



