import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9083 0.425041C10.4527 -0.0305705 9.71399 -0.0305706 9.25838 0.425041L6 3.68342L2.74163 0.425041C2.28601 -0.0305709 1.54732 -0.0305709 1.09171 0.425041C0.636097 0.880652 0.636097 1.61934 1.09171 2.07496L5.17504 6.15829C5.63065 6.6139 6.36935 6.6139 6.82496 6.15829L10.9083 2.07496C11.3639 1.61935 11.3639 0.880653 10.9083 0.425041Z" fill="#777E91"/>
        </Svg>
    );
};

export default Icon;

