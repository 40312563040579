import axios from "axios";
import { API_URL } from "config/api/API_URL";

const getNonce = async () => (await axios.get(API_URL.GET_NONCE)).data;

const login = async (data: {
    nonce: string,
    message: string,
    signature: string,
    email:string,
    phone:string,
}) => (await axios.post(API_URL.LOGIN, data)).data

const getDataUser = async (data:{
    account: string
}) =>
(await axios.get(`${API_URL.USER}/${data?.account}`)).data;

const update = async (data: {
    email:string,
    phone?:string,
}) => (await axios.put(API_URL.UPDATE, data)).data

const checkEmail = async (data: {
    email:string,
}) => (await axios.post(API_URL.CHECKEMAIL, data)).data

export const authApi = {
    getNonce,
    login,
    getDataUser,
    update,
    checkEmail
}