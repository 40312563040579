import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
    title: `Giftcards`,
    image: 'https://giftcards.vn/images/preview.png?version=1.1',
    description: 'Giftcards là nền tảng cho phép sử dụng điểm thưởng VNDC để đổi voucher, giúp người dùng mua hàng dễ dàng và an toàn từ nhà phân phối chính thức và được ủy quyền....',
}

interface PathList {
    paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
    defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
    return {
        paths: {
            '/': { title: t('Home') },
        },
        defaultTitleSuffix: t('Giftcards'),
    }
}

export const getCustomMeta = memoize(
    (path: string, t: ContextApi['t'], _: string): PageMeta => {
        const pathList = getPathList(t)
        const pathMetadata =
            pathList.paths[path] ??
            pathList.paths[
                Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]
            ]

        if (pathMetadata) {
            return {
                title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
                ...(pathMetadata.description && { description: pathMetadata.description }),
            }
        }
        return null
    },
    (path, t, locale) => `${path}#${locale}`,
)
