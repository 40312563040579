import masterchefABI from 'config/abi/masterchef.json'
import chunk from 'lodash/chunk'
import BigNumber from 'bignumber.js'
import { multicallv2 } from 'utils/multicall'
import { getBscChainId } from 'state/farms/getBscChainId'
import { BIG_ZERO } from 'utils/bigNumber'
import { verifyBscNetwork } from 'utils/verifyBscNetwork'
import { SerializedFarmConfig } from '../../config/constants/types'
import { SerializedFarm } from '../types'
import { getMasterChefAddress } from '../../utils/addressHelpers'

export const fetchMasterChefFarmPoolLength = async (chainId: number) => {
    try {
        const [poolLength] = await multicallv2({
            abi: masterchefABI,
            calls: [
                {
                    name: 'poolLength',
                    address: getMasterChefAddress(chainId),
                },
            ],
            chainId,
        })

        return new BigNumber(poolLength).toNumber()
    } catch (error) {
        console.error('Fetch MasterChef Farm Pool Length Error: ', error)
        return BIG_ZERO.toNumber()
    }
}

const masterChefFarmCalls = async (farm: SerializedFarm) => {
    const { pid, quoteToken } = farm
    const isBscNetwork = verifyBscNetwork(quoteToken.chainId)
    const multiCallChainId = isBscNetwork ? quoteToken.chainId : await getBscChainId(quoteToken.chainId)
    const masterChefAddress = getMasterChefAddress(multiCallChainId)
    const masterChefPid = pid

    return masterChefPid || masterChefPid === 0
        ? [
              {
                  address: masterChefAddress,
                  name: 'poolInfo',
                  params: [masterChefPid],
              },
              {
                  address: masterChefAddress,
                  name: 'totalAllocPoint',
              },
          ]
        : [null, null]
}

export const fetchMasterChefData = async (farms: SerializedFarmConfig[], chainId: number): Promise<any[]> => {
    const masterChefCalls = await Promise.all(farms.map((farm) => masterChefFarmCalls(farm)))
    const chunkSize = masterChefCalls.flat().length / farms.length
    const masterChefAggregatedCalls = masterChefCalls
        .filter((masterChefCall) => masterChefCall[0] !== null && masterChefCall[1] !== null)
        .flat()

    const isBscNetwork = verifyBscNetwork(chainId)
    const multiCallChainId = isBscNetwork ? chainId : await getBscChainId(chainId)
    const masterChefMultiCallResult = await multicallv2({
        abi: masterchefABI,
        calls: masterChefAggregatedCalls,
        chainId: multiCallChainId,
    })
    const masterChefChunkedResultRaw = chunk(masterChefMultiCallResult, chunkSize)

    let masterChefChunkedResultCounter = 0
    return masterChefCalls.map((masterChefCall) => {
        if (masterChefCall[0] === null && masterChefCall[1] === null) {
            return [null, null]
        }
        const data = masterChefChunkedResultRaw[masterChefChunkedResultCounter]
        masterChefChunkedResultCounter++
        return data
    })
}
