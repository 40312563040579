/* eslint-disable consistent-return */
import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { useToast } from '@pancakeswap/uikit'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { useNetwork, useSDK, useSwitchChain } from "@thirdweb-dev/react"

import { useCallback, useMemo } from 'react'
import { useSessionChainId } from './useSessionChainId'
import { useSwitchNetworkLoading } from './useSwitchNetworkLoading'

export function useSwitchNetworkLocal() {
    const [, setSessionChainId] = useSessionChainId()
    return useCallback(
        (chainId: number) => {
            setSessionChainId(chainId)
            replaceBrowserHistory('chainId', chainId === ChainId.BSC ? null : chainId)
        },
        [setSessionChainId],
    )
}

export function useSwitchNetwork() {
    const [loading, setLoading] = useSwitchNetworkLoading()
    const { t } = useTranslation()
    const { toastError } = useToast()
    const [{ data, error, loading: _isLoading }] = useNetwork();
    const switchNetwork = useSwitchChain();
    const sdk = useSDK()
    const isConnected = sdk.wallet.isConnected()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const isLoading = _isLoading || loading
    
    const switchNetworkAsync = useCallback(
        async (chainId: number) => {
            if (isConnected && typeof switchNetwork === 'function') {
                if (isLoading) return
                setLoading(true)
                return switchNetwork(chainId)
                    .then((c) => {
                        switchNetworkLocal(chainId)
                    })
                    .catch(() => {
                        // TODO: review the error
                        toastError(t('Error connecting, please retry and confirm in wallet!'))
                    })
                    .finally(() => setLoading(false))
            }
            return new Promise(() => {
                switchNetworkLocal(chainId)
            })
        },
        [isConnected, switchNetwork, isLoading, setLoading, switchNetworkLocal, toastError, t],
    )

    const switchNetworks = useCallback(
        (chainId: number) => {
            if (isConnected && typeof switchNetwork === 'function') {
                return switchNetwork(chainId)
            }
            return switchNetworkLocal(chainId)
        },
        [isConnected, switchNetwork, switchNetworkLocal],
    )

    const canSwitch = useMemo(
        () =>
            isConnected
                ? !!switchNetworks  &&
                  !(
                      typeof window !== 'undefined' &&
                      // @ts-ignore // TODO: add type later
                      (window.ethereum?.isSafePal || window.ethereum?.isMathWallet)
                  )
                : true,
        [isConnected, switchNetworks],
    )

    return {
        ...data,
        switchNetworks,
        switchNetworkAsync,
        isLoading,
        canSwitch,
    }
}
