import BigNumber from "bignumber.js"

export const formatNumber = (newValue: string|number ) => {
    
    if( new BigNumber(newValue).isNaN()) {
        return "0"
    }
    return new BigNumber(newValue).toNumber().toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export const formatNumberv2 = (newValue: string|number, synbol: string ) => {
    
    if( new BigNumber(newValue).isNaN()) {
        return "0"
    }
    if( !new BigNumber(newValue).isNaN() && synbol !== "VNDC") {
        return new BigNumber(newValue).toNumber().toLocaleString('en', { minimumFractionDigits: 6, maximumFractionDigits: 6 })
    }
    return new BigNumber(newValue).toNumber().toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}