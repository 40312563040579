import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M18.0101 13.5055L18.677 9.16082H14.5074V6.34521C14.5074 5.1597 15.0867 4.00112 16.9526 4.00112H18.8454V0.296379C18.8454 0.296379 17.1277 0 15.4841 0C12.0556 0.0067359 9.81252 2.08139 9.81252 5.84676V9.15408H6V13.4987H9.81252V24H14.5074V13.4987H18.0101V13.5055Z" fill="#FEB318"/>
        </Svg>
    );
};

export default Icon;
