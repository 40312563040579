import { ContextApi } from '@pancakeswap/localization'
import {
    MenuItemsType,
    NftFillIcon,
    NftFilledIcon
} from '@pancakeswap/uikit'
import { DropdownMenuItemType, DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        [
            {
                label: t('Home'),
                icon: NftFillIcon,
                fillIcon: NftFilledIcon,
                href: '/',
                showItemsOnMobile: false,
                items:
                 [
                  {
                    label: t('Home'),
                    href: '/',
                    supportChainIds:[]
                  }
                ].map((item) => addMenuItemSupported(item, chainId))
              },
              {
                label: t('Blog'),
                icon: NftFillIcon,
                fillIcon: NftFilledIcon,
                href: 'https://blog.giftcards.vn/',
                showItemsOnMobile: false,
                type: DropdownMenuItemType.EXTERNAL_LINK,
                items:
                 [
                  {
                    label: t('Blog'),
                    href: 'https://blog.giftcards.vn/',
                    supportChainIds:[],
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                  }
                ].map((item) => addMenuItemSupported(item, chainId))
              },
              {
                label: t('FAQ'),
                icon: NftFillIcon,
                fillIcon: NftFilledIcon,
                href: '/faq',
                showItemsOnMobile: false,
                items:
                 [
                  {
                    label: t('FAQ'),
                    href: '/faq',
                    supportChainIds:[]
                  }
                ].map((item) => addMenuItemSupported(item, chainId))
              },
        ].map((item) => addMenuItemSupported(item, chainId))

export default config


