import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.6852 1.57324C13.2107 1.98973 13.5699 2.53748 13.6955 3.11672C13.8357 3.76333 13.6808 4.41957 13.26 4.96206L6.08434 14.2159C5.72161 14.684 5.17623 14.957 4.5867 14.9649L1.81823 14.9999H1.81043C1.50831 14.9999 1.246 14.7899 1.17762 14.4924L0.550857 11.7677C0.417541 11.1876 0.549126 10.5865 0.912715 10.1184L1.85458 8.903C2.07533 8.61776 2.48394 8.56701 2.76615 8.79188C3.04837 9.015 3.09771 9.428 2.8761 9.71324L1.93423 10.9286C1.81476 11.0826 1.77148 11.2795 1.81563 11.4702L2.32379 13.6804L4.57025 13.6524C4.7633 13.6498 4.9425 13.5606 5.06196 13.4066L12.2377 4.1527C12.4212 3.91646 12.4844 3.66271 12.4272 3.39671C12.3658 3.11584 12.1736 2.83497 11.8845 2.6066L10.6811 1.65199C10.2154 1.2845 9.55835 1.0955 9.10992 1.67561L8.16199 2.8971L9.66656 4.11683C9.94617 4.34257 9.99119 4.75644 9.76698 5.03819C9.63885 5.20094 9.45014 5.28493 9.25968 5.28493C9.11771 5.28493 8.97401 5.23769 8.85454 5.14056L7.36123 3.93045L4.90614 7.09703C4.68452 7.38228 4.27678 7.4339 3.9937 7.20816C3.71235 6.98503 3.66301 6.57204 3.88376 6.28679L8.08754 0.865377C8.90302 -0.188108 10.3314 -0.291356 11.4819 0.619505L12.6852 1.57324ZM9.33024 13.6875H14.8507C15.2091 13.6875 15.5 13.9815 15.5 14.3438C15.5 14.706 15.2091 15 14.8507 15H9.33024C8.97098 15 8.68097 14.706 8.68097 14.3438C8.68097 13.9815 8.97098 13.6875 9.33024 13.6875Z" fill="black"/>
    </Svg>
);

export default Icon;
