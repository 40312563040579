import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 28px;
  height: 28px;
  @media screen and (max-width: 768px){
    width: 18px;
    height: 18px;
  }
`

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <CsSvg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      
      <path d="M15.7014 18.7748C15.8344 18.7748 15.9674 18.7258 16.0724 18.6208L20.3214 14.3718C20.5244 14.1688 20.5244 13.8328 20.3214 13.6298L16.0724 9.38077C15.8694 9.17777 15.5334 9.17777 15.3304 9.38077C15.1274 9.58377 15.1274 9.91977 15.3304 10.1228L19.2084 14.0008L15.3304 17.8788C15.1274 18.0818 15.1274 18.4178 15.3304 18.6208C15.4284 18.7258 15.5684 18.7748 15.7014 18.7748Z" fill="#9596A6" />
      <path d="M8.05044 14.5256H19.8314C20.1184 14.5256 20.3564 14.2876 20.3564 14.0006C20.3564 13.7136 20.1184 13.4756 19.8314 13.4756H8.05044C7.76344 13.4756 7.52544 13.7136 7.52544 14.0006C7.52544 14.2876 7.76344 14.5256 8.05044 14.5256Z" fill="#9596A6" />
    </CsSvg>
  );
};

export default Icon;
