import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 28px;
  height: 28px;
  @media screen and (max-width: 768px){
    width: 18px;
    height: 18px;
  }
`

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <CsSvg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2986 18.7748C12.1656 18.7748 12.0326 18.7258 11.9276 18.6208L7.67862 14.3718C7.47562 14.1688 7.47562 13.8328 7.67862 13.6298L11.9276 9.38077C12.1306 9.17777 12.4666 9.17777 12.6696 9.38077C12.8726 9.58377 12.8726 9.91977 12.6696 10.1228L8.79162 14.0008L12.6696 17.8788C12.8726 18.0818 12.8726 18.4178 12.6696 18.6208C12.5716 18.7258 12.4316 18.7748 12.2986 18.7748Z" fill="#9596A6" />
      <path d="M19.9496 14.5256H8.16855C7.88155 14.5256 7.64355 14.2876 7.64355 14.0006C7.64355 13.7136 7.88155 13.4756 8.16855 13.4756H19.9496C20.2366 13.4756 20.4746 13.7136 20.4746 14.0006C20.4746 14.2876 20.2366 14.5256 19.9496 14.5256Z" fill="#9596A6" />
    </CsSvg>
  );
};

export default Icon;
