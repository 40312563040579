import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 20px;
  height: 21px;
  @media screen and (max-width: 768px){
    width: 18x;
    height: 19px;
  }
`
const Icon: React.FC<SvgProps> = (props) => (
    <CsSvg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1677 3V18" stroke="#9596A6" strokeWidth="1.86567" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.33398 15.5L5.83398 18L3.33398 15.5" stroke="#9596A6" strokeWidth="1.86567" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.83374 18V3" stroke="#9596A6" strokeWidth="1.86567" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.668 5.5L14.168 3L11.668 5.5" stroke="#9596A6" strokeWidth="1.86567" strokeLinecap="round" strokeLinejoin="round" />
    </CsSvg>
);

export default Icon;
