import { useTranslation } from '@pancakeswap/localization'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import Head from 'next/head'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import Container from './Container'

const StyledPage = styled.div`
    min-height: calc(100vh - 64px);
    padding-bottom: 16px;
    padding-left: 0px;
    padding-right: 0px;
    min-height: 80vh;
    margin-bottom: 300px;
    width: 100%;
    @media screen and (max-width: 600px) {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        overflow-x: hidden;
        margin-top: 40px;
        /* margin-bottom: 450px; */
        margin-bottom: 100px;
    }
`

export const PageMeta: React.FC<React.PropsWithChildren<{ symbol?: string }>> = ({ symbol }) => {
    const {
        t,
        currentLanguage: { locale },
    } = useTranslation()
    const { pathname } = useRouter()
    const pageMeta = getCustomMeta(pathname, t, locale) || {}
    const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
    let pageTitle = title
    if (symbol) {
        pageTitle = [symbol, title].join(' - ')
    }

    return (
        <Head>
            <title>{pageTitle}</title>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
        </Head>
    )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
    symbol?: string
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ children, symbol, ...props }) => {
    return (
        <>
            <PageMeta symbol={symbol} />
            <StyledPage {...props}>{children}</StyledPage>
        </>
    )
}

export default Page
