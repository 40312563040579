import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 29 32" fill="none">
            <path
                d="M14.3297 31.0002C21.6915 31.0002 27.6595 25.0323 27.6595 17.6705C27.6595 10.3087 21.6915 4.34076 14.3297 4.34076C6.96792 4.34076 1 10.3087 1 17.6705C1 25.0323 6.96792 31.0002 14.3297 31.0002Z"
                fill="none"
                stroke="#494949"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M14.3301 1V4.34054" stroke="#494949" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M2.54114 5.88098L4.32488 7.66478"
                stroke="#494949"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M26.1193 5.88098L24.3356 7.66478"
                stroke="#494949"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.3294 19.3402C15.2519 19.3402 15.9997 18.5924 15.9997 17.67C15.9997 16.7475 15.2519 15.9997 14.3294 15.9997C13.407 15.9997 12.6592 16.7475 12.6592 17.67C12.6592 18.5924 13.407 19.3402 14.3294 19.3402Z"
                stroke="#494949"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M14.3291 17.67L18.4967 13.5024" stroke="#494949" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.6595 1H16.0001" stroke="#494949" stroke-linecap="round" stroke-linejoin="round" />
        </Svg>
    );
};

export default Icon;
