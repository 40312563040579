import { FixedNumber } from '@ethersproject/bignumber'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const FIXED_TEN_IN_POWER_18 = FixedNumber.from(getFullDecimalMultiplier(18))

export const masterChefAddresses = {
    97: '0x81fc0da5d4e981eb86168b2f838ae0914ec8d38f',
    56: '0xf489022c5e9b973d39a93f96864cf24b28f23023',
    1945: '0xfC6412C60db06DE222538d51f0585933153dB9a5',
    1975: '0xe5485c394D8d1Ce80d0D648a4c6DC9c749dda7cb',
}

export const nonBSCVaultAddresses = {
    1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
}
