import { createReducer } from '@reduxjs/toolkit'
import { fetchLoading, fetchDataGiftCard, getPageActive, getPageItem, fetchStatus, fetchStatusLoading, fetTabActive } from './actions'
import { GiftCardsType, StatusType } from './type'


interface global {
    isLoading:boolean,
    giftCards: GiftCardsType[],
    pageActive:number,
    pageItem:number,
    status: StatusType[], 
    isStatusLoading: boolean,
    tabActive:number
}
export const initialState: global = {
    isLoading:false,
    giftCards: [],
    pageActive:0,
    pageItem:0,
    status: [],
    isStatusLoading: false,
    tabActive: 1
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchDataGiftCard, (state, action) => {
            state.giftCards = action.payload.giftCards
        })
        .addCase(getPageActive, (state, action) => {
            state.pageActive = action.payload.pageActive
        })
        .addCase(getPageItem, (state, action) => {
            state.pageItem = action.payload.pageItem
        })
        .addCase(fetchStatus, (state, action) => {
            state.status = action.payload.status
        })
        .addCase(fetchStatusLoading, (state, action) => {
            state.isStatusLoading = action.payload.isStatusLoading
        })
        .addCase(fetTabActive, (state, action) => {
            state.tabActive = action.payload.tabActive
        })
) 