/* eslint-disable jsx-a11y/anchor-is-valid */
import { AutoRenewIcon, Button, Flex, Heading, Input, Modal, Text } from '@pancakeswap/uikit'
import { useSigner } from '@thirdweb-dev/react'
import { KEY_LOGIN } from 'config'
import { authApi } from 'config/api/auth'
import contracts from 'config/constants/contracts'
import { getToken } from 'config/giftcards'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SiweMessage } from 'siwe'
import { AppDispatch } from 'state'
import { GetDataAuth } from 'state/auth'
import { fetchEmailUser, fetchPhoneUser, getStatusLoading } from 'state/auth/actions'
import { GetDataGiftCards, UseCoreDataUser, UseCorePaymentMethod } from 'state/giftCards'
import styled from 'styled-components'
import { getAddress } from 'utils/addressHelpers'
import { formatNumberv2 } from 'utils/formatNumber'
import { useWeb3LibraryContext } from '../../../../packages/wagmi/src/provider'

interface ModalProps {
    title: React.ReactNode
    hideCloseButton?: boolean
    onBack?: () => void
    onDismiss?: () => void
}

const ModalInfo: React.FC<React.PropsWithChildren<ModalProps>> = ({ title, onDismiss }) => {
    const { account, chainId, provider } = useActiveWeb3React()
    const dispatch = useDispatch<AppDispatch>()
    const token = getToken(chainId)
    const dataAuth = GetDataAuth()
    const signer = useSigner();
    const dataGiftCard = GetDataGiftCards()
    const { email: emailAuth, phone: phoneAuth, dataUser, isLoading } = dataAuth
    const { tokenSupportBalance } = dataGiftCard
    const contractAddress = getAddress(contracts.buyVouchers, chainId)
    UseCoreDataUser(contractAddress, account, token, chainId, provider)
    UseCorePaymentMethod("1", chainId, provider, account)
    const [emailUpdateState, setEmailUpdateState] = useState('')
    const [phoneUpdateState, setPhoneUpdateState] = useState('')
    const [isErrorDupplyCate, setIsErrorDupplyCate] = useState(false)
    const [isErrorType, setIsErrorType] = useState(false)
    const [isErrorTypePhone, setIsErrorTypePhone] = useState(false)
    const [pendding, setPendding] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isValidEmail, setEvalidEmail] = useState(false)
    const [isValidPhone, setIsValidPhone] = useState(false)
    const domain = window.location.host
    const origins = window.location.origin
    const library = useWeb3LibraryContext()

    const phonenoFormat = /^\d{10}$/
    // eslint-disable-next-line no-useless-escape
    const mailformat = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,100}$/
    const vnfRegex = /((09|03|07|08|05)+([0-9]{8})\b)/g

    const handleChangeAddress = (e) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { value } = e.target
    }

    const handleChangeEmail = async (e) => {
        const { value } = e.target
        setEmailUpdateState(value)
        if (value?.match(mailformat)) {
            const ress = await authApi?.checkEmail({ email: value })
            if (ress?.data?.isEmailValid === false) {
                setEvalidEmail(true)
            }
            if (ress?.data?.isEmailValid === true) {
                setEvalidEmail(false)
            }
        }
        setIsErrorDupplyCate(false)
        setIsErrorType(false)
        // setEvalidEmail(false)
    }

    const handleChangePhoneNumber = (e) => {
        const { value } = e.target
        setPhoneUpdateState(value)

        setIsErrorDupplyCate(false)
        setIsErrorTypePhone(false)
        setIsValidPhone(false)
    }

    const handleSaveChangeInfo = async () => {
        if (
            (emailUpdateState.match(mailformat) && emailUpdateState !== '') ||
            (phoneUpdateState?.match(phonenoFormat) && phoneUpdateState !== '')
        ) {
            if (phoneUpdateState !== '' && emailUpdateState === '' && phoneUpdateState.match(vnfRegex)) {
                await signInWithEthereum()
            }
            if (emailUpdateState !== '') {
                try {
                    const ress = await authApi?.checkEmail({ email: emailUpdateState })
                    if (ress?.data?.isEmailValid === false) {
                        setEvalidEmail(true)
                    }
                    if (ress?.data?.isEmailValid === true) {
                        setEvalidEmail(false)
                        try {
                            dispatch(getStatusLoading({ isLoading: true }))
                            await signInWithEthereum()
                        } catch (error) {
                            console.log('error', error)
                            // if(error?.response?.data?.errorDetail?.code === 11000) {
                            //     setIsErrorDupplyCate(true)
                            // }
                        } finally {
                            dispatch(getStatusLoading({ isLoading: false }))
                        }
                    }
                } catch {
                    setEvalidEmail(true)
                }
            }
        }
        if (!emailUpdateState.match(mailformat)) {
            setIsErrorType(true)
        }
        if (!phoneUpdateState.match(phonenoFormat)) {
            setIsErrorTypePhone(true)
        }
    }

    async function createSiweMessage(address, statement) {
        const message = new SiweMessage({
            domain,
            address,
            statement,
            uri: origins,
            version: '1',
            chainId,
            nonce: dataAuth?.dataNonce,
        })
        return message.prepareMessage()
    }

    async function signInWithEthereum() {
        if (
            !emailUpdateState.match(mailformat) ||
            !phoneUpdateState?.match(phonenoFormat) ||
            !phoneUpdateState?.match(vnfRegex)
        ) {
            setIsError(true)
        }
        setPendding(!false)
        try {
            const message = await createSiweMessage(account, 'Sign in with Ethereum to the app.')
            const signature = await signer.signMessage(message)
            const res = await authApi.login({
                nonce: dataAuth?.dataNonce,
                message,
                signature,
                email: emailUpdateState === '' ? emailAuth : emailUpdateState,
                phone: phoneUpdateState === '' ? phoneAuth : phoneUpdateState,
            })

            if (res?.statusCode === 201) {
                if (
                    emailUpdateState.match(mailformat) ||
                    phoneUpdateState.match(phonenoFormat) ||
                    phoneUpdateState?.match(vnfRegex)
                ) {
                    const resUpdate = await authApi.update({
                        email: emailUpdateState === '' ? emailAuth : emailUpdateState,
                        phone: phoneUpdateState === '' ? phoneAuth : phoneUpdateState,
                    })
                    dispatch(fetchEmailUser({ email: emailUpdateState === '' ? emailAuth : emailUpdateState }))
                    dispatch(fetchPhoneUser({ phone: phoneUpdateState === '' ? phoneAuth : phoneUpdateState }))
                }
                await onDismiss()
                localStorage?.setItem(KEY_LOGIN, res?.data?.accessToken)
                setIsError(false)
            } else {
                localStorage.removeItem(KEY_LOGIN)
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                setIsErrorDupplyCate(true)
            }
            if (error?.response?.data?.message?.[0] === 'Number phone invalid') {
                setIsValidPhone(true)
            }
            console.log('sign failed', error)
        } finally {
            setPendding(false)
        }
    }
    return (
        <CsModal title={title} onDismiss={onDismiss}>
            <Heading mt="1rem" mb="1rem" scale="lg">
                Thông tin cá nhân
            </Heading>
            <CsFlexInput>
                <div style={{ marginBottom: '10px', maxWidth: '100%', width: '100%' }}>
                    <Flex width="100%" alignItems="center" justifyContent="space-between">
                        <Flex>
                            <Text fontWeight="bold">Số điện thoại</Text>{' '}
                            <Text ml="4px" color="red">
                                *
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex mt="0.5rem" justifyContent="flex-start">
                        <CustomInput2
                            type="text"
                            scale="lg"
                            value={phoneUpdateState}
                            placeholder={phoneAuth}
                            onChange={(e) => handleChangePhoneNumber(e)}
                        />
                    </Flex>
                    {!phoneUpdateState.match(phonenoFormat) && phoneUpdateState !== '' && (
                        <Text fontSize={['14px', , '16px']} mt="0.5rem" color="red">
                            Số điện thoại không hợp lệ
                        </Text>
                    )}
                    {phoneUpdateState.match(phonenoFormat) &&
                        !phoneUpdateState?.match(vnfRegex) &&
                        phoneUpdateState !== '' && (
                            <Text fontSize={['14px', , '16px']} mt="0.5rem" color="red">
                                Số điện thoại không tồn tại
                            </Text>
                        )}
                    {/* {
                            ((isErrorDupplyCate && emailUpdateState !== '')) && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Email này đã được sử dụng. Vui lòng nhập email khác!</Text>
                        }
                        {
                            (isValidEmail === true && emailUpdateState !== '') && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Email không tồn tại</Text>
                        } */}
                </div>

                <div style={{ marginBottom: '10px', maxWidth: '100%', width: '100%' }}>
                    <Flex width="100%" alignItems="center" justifyContent="space-between">
                        <Flex>
                            <Text fontWeight="bold">Email</Text>{' '}
                            <Text ml="4px" color="red">
                                *
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex mt="0.5rem" justifyContent="flex-start">
                        <CustomInput2
                            type="text"
                            scale="lg"
                            value={emailUpdateState}
                            placeholder={emailAuth}
                            onChange={(e) => handleChangeEmail(e)}
                        />
                    </Flex>
                    {/* {
                            ((isErrorDupplyCate && emailUpdateState !== '')) && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Email này đã được sử dụng. Vui lòng nhập email khác!</Text>
                        } */}
                    {!isErrorDupplyCate && !emailUpdateState.match(mailformat) && emailUpdateState !== '' && (
                        <Text fontSize={['14px', , '16px']} mt="0.5rem" color="red">
                            Email không hợp lệ
                        </Text>
                    )}
                    {isErrorType === false && isValidEmail === true && emailUpdateState !== '' && (
                        <Text fontSize={['14px', , '16px']} mt="0.5rem" color="red">
                            Email không tồn tại
                        </Text>
                    )}
                </div>

                <div style={{ marginBottom: '10px', maxWidth: '100%', width: '100%' }}>
                    <Flex width="100%" alignItems="center">
                        <Text fontWeight="bold">Địa chỉ ví</Text>
                    </Flex>
                    <Flex mt="0.5rem" justifyContent="flex-start">
                        <CustomInput2
                            readOnly
                            scale="lg"
                            type="text"
                            value={account}
                            placeholder="Nhập địa chỉ ví"
                            onChange={(e) => handleChangeAddress(e)}
                        />
                    </Flex>
                </div>

                <div style={{ marginBottom: '10px', maxWidth: '100%', width: '100%' }}>
                    <Flex width="100%" alignItems="center">
                        <Text fontWeight="bold">Số dư</Text>
                    </Flex>
                    <Flex mt="0.5rem" flexDirection="column" justifyContent="flex-start" style={{ position: 'relative', gap:"1rem" }}>
                        {tokenSupportBalance?.map((dt) => {
                            return (
                                <Flex alignItems="center" width="100%" justifyContent='space-between'>
                                    <Text>{formatNumberv2(dt?.balance, dt?.token?.symbol)}</Text>
                                    <Text>{dt?.token?.symbol}</Text>
                                </Flex>
                            )
                        })}
                    </Flex>
                </div>
                <Flex width="100%" justifyContent="center" mt="1rem">
                    <CsButtonContinue
                        onClick={handleSaveChangeInfo}
                        style={{ cursor: 'pointer' }}
                        endIcon={pendding && <AutoRenewIcon color="white" spin />}
                        disabled={
                            pendding ||
                            (emailUpdateState === '' && phoneUpdateState === '') ||
                            (phoneAuth === '' &&
                                emailAuth === '' &&
                                (!emailUpdateState?.match(mailformat) || !phoneUpdateState?.match(phonenoFormat)))
                        }
                    >
                        Lưu thay đổi
                    </CsButtonContinue>
                </Flex>
            </CsFlexInput>
        </CsModal>
    )
}

const UnitToken = styled(Text)`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`

const CsButtonContinue = styled(Button)`
    width: 100%;
    border-radius: 4px;
`

const CsModal = styled(Modal)`
    padding: 1rem;
    min-width: 490px;
    @media screen and(max-width: 1024px) {
        min-width: 300px;
    }
    @media screen and (max-width: 600px) {
        min-width: 90%;
        padding: 0.5rem;
    }
`

export const CsContent = styled(Flex)`
    flex-direction: column;
    width: 100%;
    display: flex;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    padding: 22px 25px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
        padding: 12px 15px;
    }
`
export const Row = styled(Flex)`
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-between;
`
const CsFlexInput = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        gap: 0rem;
    }
`
export const CustomInput2 = styled(Input)`
    color: ${({ theme }) => theme.colors.text};
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    text-align: left;
    border: none;
    box-shadow: none;
    font-size: 16px;
    border-radius: 11.526px;
    background: #efefef;
    min-width: 280px;
    height: 48px;
    &::placeholder {
        color: ${({ theme }) => theme.colors.text};
    }
    &:focus {
        &::placeholder {
            opacity: 0.5;
        }
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 11px;
        padding: 0 0.5rem;
    }
`

export default ModalInfo
