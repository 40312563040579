import axios from "axios";
import { API_URL } from "config/api/API_URL";

const getBrand = async () => (await axios.get(API_URL.BRAND)).data;
const getCategory = async () => (await axios.get(API_URL.CATEGORY)).data;
const getDetailBrand = async (itemId) => (await axios.get(API_URL.DETAILBRAND, {params:itemId})).data;
const getDetailNewBrand = async (itemId) => (await axios.get(API_URL.DETAIL_NEW_BRAND, {params:itemId})).data;


const order = async (data: {
    deliveryMethod: string,
    phoneNo: string,
    email: string,
    quantity : number,
    itemId: string,
    chainId?: string,
    isGiftCardVoucher?:boolean
}) => (await axios.post(API_URL.ORDER, data, { timeout: 100000})).data

const verify = async (data: {
    transactionHash: string,
    chainId: string,
    orderId: string
}) => (await axios.post(API_URL.VERIFY, data, { timeout: 100000})).data

const paymentOnus = async (data: {
    orderId:string
}) => (await axios.post(API_URL.PAYMENT_ONUS, data, { timeout: 100000})).data

const getNewBrand = async () => (await axios.get(API_URL.NEW_BRAND)).data;


export const BrandApi = {
    getBrand,
    getCategory,
    getDetailBrand,
    order, 
    verify,
    paymentOnus,
    getNewBrand,
    getDetailNewBrand
}