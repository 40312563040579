import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 37 38" fill="none" {...props}>
            <rect y="0.55957" width="36.8151" height="36.8151" rx="18.4076" fill="#E6E8EC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.7932 12.5137C22.3922 13.1128 22.3922 14.084 21.7932 14.6831L17.509 18.9673L21.7932 23.2515C22.3922 23.8505 22.3922 24.8218 21.7932 25.4208C21.1941 26.0199 20.2229 26.0199 19.6238 25.4208L14.255 20.052C13.6559 19.4529 13.6559 18.4817 14.255 17.8826L19.6238 12.5137C20.2229 11.9147 21.1941 11.9147 21.7932 12.5137Z" fill="#23262F"/>
        </Svg>
    );
};

export default Icon;
