import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import Flex from "../../../components/Box/Flex";
import { LogoIcon, MenuIcon } from "../../../components/Svg";
import { MenuContext } from "../context";

interface Props {
    isDark: boolean;
    href: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`;

const StyledLink = styled("a")`
    display: flex;
    align-items: center;
    .mobile-icon {
        width: 32px;
        ${({ theme }) => theme.mediaQueries.lg} {
            display: none;
        }
    }
    .desktop-icon {
        width: 160px;
        display: none;
        ${({ theme }) => theme.mediaQueries.lg} {
            display: block;
        }
    }
    .eye {
        animation-delay: 20ms;
    }
    &:hover {
        .eye {
            transform-origin: center 60%;
            animation-name: ${blink};
            animation-duration: 350ms;
            animation-iteration-count: 1;
        }
    }
`;

const CsFlex = styled(Flex)`
    width: 140px;
    @media screen and (max-width:600px) {
       width: 110px;
    }
`


const Logo: React.FC<React.PropsWithChildren<Props>> = ({ isDark, href }) => {
    const { linkComponent } = useContext(MenuContext);
    const isAbsoluteUrl = href.startsWith("http");
    const innerLogo = (
        <CsFlex>
            <img src="/images/logoWhite.png?version=1.1" alt="" />
        </CsFlex>
    );

    return (
        <Flex>
            {isAbsoluteUrl ? (
                <StyledLink as="a" href={href} aria-label="PiBridge home page">
                    {innerLogo}
                </StyledLink>
            ) : (
                <StyledLink href={href} as={linkComponent} aria-label="PiBridge home page">
                    {innerLogo}
                </StyledLink>
            )}
        </Flex>
    );
};

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark);
