import { SerializedToken } from "@pancakeswap/sdk"
import { getGiftCardPaymentConfig } from "config/constants/giftcards"
import { listTokenGetBalance } from "config/giftcards"
import { useFastRefreshEffect, useSlowRefreshEffect } from "hooks/useRefreshEffect"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "state"
import { fetchDataUser, fetchDataUserByChain, fetchDetailBrand, fetchListCategory, fetchListProvinceByBrand, fetchListProvinceId, fetchPublicBrand, fetchTokenSupport, fetchTokenSupportBalance, getStatusLoading } from "./actions"
import { fetProvinceByBrandId, fetchBalanceByChain, fetchBrandById, fetchBrandCityData, fetchBrandData, fetchCategoryData, fetchNewBrandById, fetchNewBrandData, fetchPublicDataUser, getDateTokenBalance, getDateTokenSuppport } from "./fetchData"

export const UseCoreGiftCard = () => {
    const dispatch = useAppDispatch()
    const dataGiftcards = GetDataGiftCards()
    const { cityId, brandId } = dataGiftcards

    useEffect(() => {
        (async() => {
            dispatch(getStatusLoading({ isLoading: true }))
            const res = await fetchBrandData()
            const resNewBrand = await fetchNewBrandData()
            const resCategory = await fetchCategoryData()
            const arr = [...resNewBrand?.ListBrand, ...res?.ListBrand]
            dispatch(fetchListCategory(resCategory))
            dispatch(fetchPublicBrand({ListBrand: arr}))
            dispatch(getStatusLoading({ isLoading: false }))
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        (async() => {
            dispatch(getStatusLoading({ isLoading: true }))
            const resCityIdBrand = await fetchBrandCityData(cityId)
            dispatch(fetchListProvinceId(resCityIdBrand))
            dispatch(getStatusLoading({ isLoading: false }))
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId])

    useEffect(() => {
        (async() => {
            dispatch(getStatusLoading({ isLoading: true }))
            const resProvinceByBrandId = await fetProvinceByBrandId(brandId?.toString())
            dispatch(fetchListProvinceByBrand(resProvinceByBrandId))
            dispatch(getStatusLoading({ isLoading: false }))
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId])

}

export const UseDetailBrand = (itemId:string|number, isNewBrand?:string ) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        const getData = async() => {
            if(isNewBrand) {
                const resBrandItem = await fetchNewBrandById(itemId)
                dispatch(fetchDetailBrand(resBrandItem))
            } else {
                const resBrandItem = await fetchBrandById(itemId)
                dispatch(fetchDetailBrand(resBrandItem))
            }
            
        }
        if (itemId){
            getData()
        }
    }, [dispatch, isNewBrand, itemId])
}

export const UseCoreDataUser = (contractAddress: string, account:string, token: SerializedToken, chainId:number, provider: any) => {
    const dispatch = useAppDispatch()
    const listTokenByTypeChain = listTokenGetBalance(chainId)
    useEffect(() => {
        (async() => {
            const res = await fetchPublicDataUser(contractAddress, account, token, chainId, provider )
            const resListBalance = await fetchBalanceByChain(account, listTokenByTypeChain )
            dispatch(fetchDataUser(res))
            dispatch(fetchDataUserByChain(resListBalance))
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, token, chainId, dispatch])
}

export const UseCorePaymentMethod = (totalPrice: string, chainId:number, provider: any, account?:string) => {
    const dispatch = useAppDispatch()
    useFastRefreshEffect(() => {
        (async() => {
            const tokenSupport = await getGiftCardPaymentConfig(chainId)
            const dataTokenSupport = await getDateTokenSuppport(tokenSupport, totalPrice, chainId, provider)
            const tokenSupportBalance = await getDateTokenBalance(tokenSupport, account, chainId, provider)
            dispatch(fetchTokenSupport(dataTokenSupport))
            dispatch(fetchTokenSupportBalance(tokenSupportBalance))
        })()
    }, [account, chainId, dispatch, provider, totalPrice])
}

export const GetDataGiftCards = () => {
    const data = useSelector<AppState, AppState['giftCards']>((state) => state.giftCards)
    return data
}