// @ts-ignore
// eslint-disable-next-line import/extensions

import { Flex, WalletIcon, useMatchBreakpoints } from "@pancakeswap/uikit";
import { ConnectWallet } from "@thirdweb-dev/react";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import styled from 'styled-components';


const ConnectWalletButton = ({ children, routePath, ...props}: any) => {
    const { account } = useActiveWeb3React()
    const { isMobile } = useMatchBreakpoints()
    function sAccount(dataAddress) {
        if (dataAddress && !isMobile) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`
        }
        return ""
    }

    return (
        <CsConnectWallet
            modalTitle="Kết nối ví"
            btnTitle="Kết nối ví"
            switchToActiveChain={!false}
            welcomeScreen={{
                title: "Chào mừng đến với Gift cards",
                subtitle: "Kết nối ví của bạn để bắt đầu",
                img: {
                    src: "https://giftcards.vn/logo.png",
                    width: 128,
                    height: 128,
                },
            }}
            modalTitleIconUrl="https://giftcards.vn/logo.png"
            detailsBtn={() => {
                return <ContainerAccount>
                    <WalletIcon filterColor='#355DFF'/>
                    {sAccount(account)}
                </ContainerAccount>;
            }}
        />

    )
}

export default ConnectWalletButton

const ContainerAccount = styled(Flex)`
    width:155px;
    height: 46px;
    justify-content: center;
    align-items: center;
    gap:8px;
    border-radius: 12px;
    background: #355DFF;
    @media screen and (max-width:600px){
        width:60px;
    }
`
const CsConnectWallet = styled(ConnectWallet).attrs({theme:"light"})`
    background: #355DFF !important;
    color:#FFFFFF !important;
    @media screen and (max-width:600px){
        width:100px !important;
        min-width:60px !important;
    }
`
