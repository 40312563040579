import { SerializedToken, Token } from "@pancakeswap/sdk"
import axios from "axios"

import BigNumber from "bignumber.js"
import { ERC20_ABI } from "config/abi/erc20"
import { API_URL } from "config/api/API_URL"
import { BrandApi } from "config/api/brand"
import routerAbi from "config/abi/pancakeRouter.json"
import { ROUTER_ADDRESS } from "config/constants/exchange"
import { GiftCardPaymentTokenType } from "config/constants/giftcards/type"
import { PUBLIC_NODESV2 } from "config/nodes"
import { ethers } from "ethers"
import multicall from "utils/multicall"
import { BrandItem, Brandtype, DataUser, TokenSupportBalanceList, TokenSupportList, resultListBalanceByChain } from "./type"

export const fetchBrandData = async (): Promise<{ ListBrand: Brandtype[] | null }> => {
    try {
        const res = await BrandApi?.getBrand()
        return {
            ListBrand: res?.data?.items
        }
    } catch (error) {
        console.log(error)
        return {
            ListBrand: []
        }
    }
}

export const fetchCategoryData = async (): Promise<{ ListCategory: Brandtype[] | null }> => {
    try {
        const res = await BrandApi?.getCategory()
        return {
            ListCategory: res?.data
        }
    } catch (error) {
        console.log(error)
        return {
            ListCategory: []
        }
    }
}
export const fetchBrandCityData = async (cityIdState: string): Promise<{ ListProvinceId: string[] | null }> => {
    if (cityIdState) {
        try {
            const res = await axios.get(`${API_URL.BRANDCITY}/${cityIdState}`)
            const resNewBrand = await BrandApi?.getNewBrand()
            
            const newBrnadByAddress = Number(cityIdState) !== 0 ? resNewBrand?.data?.filter((dt) => dt?.address === cityIdState) : resNewBrand?.data?.filter((dt) => dt?.address)
            const oldProvinceId = Array.isArray(res?.data?.data) ? res?.data?.data : res?.data?.data?.brandIds
            const newProvinceId = newBrnadByAddress?.map((dt) => {
                return dt?._id
            })
            return {
                ListProvinceId: [...oldProvinceId, ...newProvinceId]
            }
        } catch (error) {
            console.log(error)
            return {
                ListProvinceId: []
            }
        }
    } else {
        return {
            ListProvinceId: []
        }
    }
}

export const fetProvinceByBrandId = async (brandId: string): Promise<{ ListProvinceByBrand: string[] | null }> => {

    if (brandId) {
        try {
            const res = await axios.get(`${API_URL.CITY_BY_BRAND}/${brandId?.toString()}/city`)
            return {
                ListProvinceByBrand: Array.isArray(res?.data?.data) ? res?.data?.data : res?.data?.data?.cityIds
            }
        } catch (error) {
            console.log(error)
            return {
                ListProvinceByBrand: []
            }
        }
    } else {
        return {
            ListProvinceByBrand: []
        }
    }

}

export const fetchBrandById = async (itemId: string | number): Promise<{ brandItem: BrandItem[] }> => {
    try {
        const res = await BrandApi?.getDetailBrand({ id: itemId })

        return {
            brandItem: res?.data
        }
    } catch (error) {
        console.log(error)
        return {
            brandItem: null
        }
    }
}

export const fetchPublicDataUser = async (contractAddress: string, account: string, token: SerializedToken, chainId: number, provider: any): Promise<{ dataUser: DataUser }> => {
    if (account?.length > 0 && token) {
        try {
            const calls = [
                {
                    address: token?.address,
                    name: 'allowance',
                    params: [account, contractAddress]
                },
                {
                    address: token?.address,
                    name: 'balanceOf',
                    params: [account]
                }
            ]
            const result = await multicall(ERC20_ABI, calls, chainId, provider)

            return {
                dataUser: {
                    allowance: new BigNumber(result[0]?.toString()).dividedBy(new BigNumber(10).pow(token?.decimals)).toNumber(),
                    balanceOf: new BigNumber(result[1]?.toString()).dividedBy(new BigNumber(10).pow(token?.decimals)).toNumber(),
                }
            }
        }
        catch (error) {
            console.log(error)
            return {
                dataUser: {
                    allowance: 0,
                    balanceOf: 0,
                }
            }
        }
    } else {
        return {
            dataUser: {
                allowance: 0,
                balanceOf: 0,
            }
        }
    }
}

export const fetchBalanceByChain = async (account: string, listTokenByTypeChain: Token[]): Promise<{ ListBalance: resultListBalanceByChain[] }> => {
    if (account?.length > 0) {
        try {
            const fetchBalance = async (item) => {
                const provider = new ethers.providers.JsonRpcProvider(PUBLIC_NODESV2[item?.chainId]);
                const signer = provider.getSigner(account);
                const contract = new ethers.Contract(
                    item?.address,
                    ERC20_ABI,
                    signer
                );
                const balanceOf = await contract?.balanceOf(account)
                return {
                    chainId: item?.chainId,
                    balanceOf: new BigNumber(balanceOf?.toString()).dividedBy(new BigNumber(10).pow(item?.decimals)).toNumber(),
                }
            }
            const ListBalance = []
            await Promise.all(listTokenByTypeChain?.map(async (dt) => {
                const resultTypeByChain = await fetchBalance(dt)
                ListBalance.push(resultTypeByChain)
            }));
            return { ListBalance }
        }
        catch (error) {
            return {
                ListBalance: [{
                    chainId: 56,
                    balanceOf: 0,
                },
                {
                    chainId: 1975,
                    balanceOf: 0,
                }],
            }
        }
    } else {
        return {
            ListBalance: [{
                chainId: 56,
                balanceOf: 0,
            },
            {
                chainId: 1975,
                balanceOf: 0,
            }],
        }
    }
}

export const getDateTokenSuppport = async (tokens: GiftCardPaymentTokenType[], totalPrice: string, chainId: number, provider: any): Promise<{ tokenSupport: TokenSupportList[] }> => {
    const getVndcExchange = () => {
        const vndc = tokens.filter((dt) => !dt?.tokenIn && !dt.tokenOut)
        const exchange = vndc?.map((dt) => {
            return {
                ...dt,
                exchangeRate: Number(totalPrice)
            }
        })
        return exchange
    }
    
    const getAmountsIn = async (item: TokenSupportList[]) => {
        const tokensOrder = item.filter((dt) => dt?.tokenIn && dt?.tokenOut)
        if(!new BigNumber(totalPrice).isNaN()) {
            const calls = tokensOrder?.map((data) => {
                return {
                    address: ROUTER_ADDRESS[chainId],
                    name: 'getAmountsIn',
                    params: [totalPrice,[data?.tokenIn?.address, data?.tokenOut?.address]]
                }
            })
            const [results] = await multicall(routerAbi, calls, chainId, provider)

            const mer = tokensOrder?.map((dt, index) => {
                return {
                    ...dt,
                    exchangeRate: new BigNumber(results[index][0]?.toString()).dividedBy(new BigNumber(10).pow(dt?.tokenIn?.decimals))?.toNumber()
                }
            })
            return mer
        }
        const mer = tokensOrder?.map((dt) => {
            return {
                ...dt,
                exchangeRate: 1
            }
        })
        return mer
       
    }
    const vndc = getVndcExchange()
    const orderToken = await getAmountsIn(tokens)
    return {
        tokenSupport: vndc.concat(orderToken)
    }
}

export const getDateTokenBalance = async (tokens: GiftCardPaymentTokenType[], account: string, chainId: number, provider: any): Promise<{ tokenSupportBalance: TokenSupportBalanceList[] }> => {
    if(account?.length > 0) {
        try {
            const calls = tokens?.map((dt) => {
                return {
                    address: dt?.token?.address,
                    name: 'balanceOf',
                    params: [account]
                }
            })
            const result = await multicall(ERC20_ABI, calls, chainId, provider)
            const merr = tokens?.map((dt, index) => {
                return {
                    ...dt,
                    balance: new BigNumber(result[index]?.balance?.toString()).dividedBy(new BigNumber(10).pow(dt?.token?.decimals)).toString()
                }
            })
            return {
                tokenSupportBalance: merr
            }
        } catch (error) {
            const merr = tokens?.map((dt) => {
                return {
                    ...dt,
                    balance: "0"
                }
            })
            return {
                tokenSupportBalance: merr
            }
        }
    }
    const merr = tokens?.map((dt) => {
        return {
            ...dt,
            balance: "0"
        }
    })
    return {
        tokenSupportBalance: merr
    }
}

export const fetchNewBrandById = async (itemId:string|number): Promise<{brandItem:BrandItem[]}> => {
    try {
        const res = await BrandApi?.getDetailNewBrand({id:itemId})
        const { data } = res
        const arr = data?.map((dt) => {
            return {
                id: dt?._id,
                brand: dt?._id,
                brand_id: dt?._id,
                cat_id: "",
                cat_title: dt?.name,
                gift_id: "",
                title: dt?.name,
                type: "1",
                price: dt?.price?.toString(),
                point: "",
                view: "",
                quantity: dt?.remainQuantity,
                stock: "",
                image: "",
                images_rectangle: [],
                expire_duration: "",
                code_display: "",
                code_display_type: "",
                price_promo: dt?.promoPrice?.toString(),
                start_promo: "",
                end_promo: "",
                is_promo: new BigNumber(dt?.price).isGreaterThan(dt?.promoPrice) ? "2" : "1",
                is_unfix: "",
                brandLogoLoyalty: "",
                brandImage: "",
                brand_name: dt?.name,
                brand_online: "",
                parent_cat_id: "",
                usage_check: "",
                content: "",
                note: dt?.description,
                office: dt?.addresses,
                isNewBrand: !false
            }
        })
        return {
            brandItem: arr
        }
    } catch (error) {
        console.log(error)
        return {
            brandItem: null
        }
    }
}

export const fetchNewBrandData = async (): Promise<{ListBrand:Brandtype[]|null}> => {
    try {
        const res = await BrandApi?.getNewBrand()
        const renderData = res?.data?.map((dt) => {
            return {
                banner: "",
                cat_id:"",
                cat_title: "",
                description:dt?.description,
                gift_count:"",
                id: dt?._id,
                images: dt?.logo,
                parent_cat_id:"1",
                title: dt?.brandName,
                isNewBrand: !false
            }
        })
        return {
            ListBrand: renderData
        }
    } catch (error) {
        console.log(error)
        return {
            ListBrand: []
        }
    }
}