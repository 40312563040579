import { LanguageProvider } from '@pancakeswap/localization'
import { ModalProvider, UIKitProvider, light } from '@pancakeswap/uikit'
import { Store } from '@reduxjs/toolkit'
import { Binance, OnusChain, BinanceTestnet } from "@thirdweb-dev/chains"
import { ThirdwebProvider, coinbaseWallet, embeddedWallet, metamaskWallet, walletConnect } from "@thirdweb-dev/react"
import { BASE_URL } from 'config'
import { HistoryManagerProvider } from 'contexts/HistoryContext'

import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { ThemeProvider as NextThemeProvider } from 'next-themes'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { useActiveChainProvider } from 'utils/getActiveChain'


const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
    return (
        <UIKitProvider theme={light} {...props}>
            {children}
        </UIKitProvider>
    )
}

const Providers: React.FC<React.PropsWithChildren<{ store: Store; children: React.ReactNode }>> = ({
    children,
    store,
}) => {

    const walletConfig = walletConnect({
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT,
    })

    walletConfig.meta.urls = {
        android: `${BASE_URL}`,
        ios: `${BASE_URL}`,
        chrome: `${BASE_URL}`,
        firefox: `${BASE_URL}`,
    }
    const metamaskConfig = metamaskWallet();
    metamaskConfig.meta.urls = {
        android: `${BASE_URL}`,
        ios: `${BASE_URL}`,
        chrome: `${BASE_URL}`,
        firefox: `${BASE_URL}`,
    }

    return (
        <ThirdwebProvider
            activeChain={useActiveChainProvider()}
            supportedChains={[OnusChain, Binance, BinanceTestnet]}
            clientId={process.env.NEXT_PUBLIC_CLIENT_ID}
            supportedWallets={[
                walletConfig,
                metamaskConfig,
                coinbaseWallet(),
                embeddedWallet(),
            ]}
            dAppMeta={{
                name: "Giftcards",
                description: "Giftcards là nền tảng cho phép sử dụng điểm thưởng VNDC để đổi voucher, giúp người dùng mua hàng dễ dàng và an toàn từ nhà phân phối chính thức và được ủy quyền...",
                logoUrl: `${BASE_URL}/logo.png`,
                url: `${BASE_URL}`
            }}

        >
            <Provider store={store}>
                <NextThemeProvider>
                    <StyledUIKitProvider>
                        <LanguageProvider>
                            <SWRConfig
                                value={{
                                    use: [fetchStatusMiddleware],
                                }}
                            >
                                <HistoryManagerProvider>
                                    <ModalProvider>{children}</ModalProvider>
                                </HistoryManagerProvider>
                            </SWRConfig>
                        </LanguageProvider>
                    </StyledUIKitProvider>
                </NextThemeProvider>
            </Provider>
        </ThirdwebProvider>
    )
}

export default Providers
