export enum API_URL {
    // auth
    GET_NONCE = "/v1/auth/nonce",
    LOGIN = "/v1/auth/verify",
    USER = "/v1/user",
    UPDATE = "/v1/user",
    CHECKEMAIL = "/v1/auth/verify-email",

    // brand
    BRAND = "/v1/brand",
    CATEGORY = "/v1/category/parents",
    BRANDCITY = "/v1/brand/city",
    CITY_BY_BRAND = "/v1/brand",
    DETAILBRAND = "/v1/brand/items",
    ORDER = "/v1/orders",
    VERIFY = "/v1/orders/verify",
    PAYMENT_ONUS = "/v1/onus-payment/create",
    NEW_BRAND = "/v1/provider/list",
    DETAIL_NEW_BRAND = "/v1/voucher/provider",
    // history
    HISTORY = "/v1/orders",
    RESENDMAIL = "/v1/orders/resend-giftcode",
    REVERIFY = "/v1/orders/verify",

    // vouchers
    VOUCHERS ="/v1/orders/gift-cards",
    VOUCHERS_STATUS= "/v1/orders/usage-status",
    GIFT_CARD_VOUCHER = "/v1/orders/gift-cards-voucher"
}