import { useTranslation } from '@pancakeswap/localization'
import { Flex, MenuIconMobile, Text } from '@pancakeswap/uikit'
import { Drawer } from 'antd'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from 'hooks/useTheme'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import config from './config/config'
import { getActiveMenuItem, getParentMenu } from './utils'

const DrawerMenu = () => {
    const [open, setOpen] = useState(false)
    const {
        t,
        currentLanguage: { code: languageCode },
    } = useTranslation()
    const { chainId } = useActiveWeb3React()
    const { isDark } = useTheme()

    const menuItems = useMemo(() => {
        return config(t, isDark, languageCode, chainId)
    }, [t, isDark, languageCode, chainId])
    const { pathname, asPath } = useRouter()
    const url = getParentMenu(pathname)

    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, url })
    const mobileDrawer = () => {
        return (
            <Drawer placement="top" closable={!false} onClose={() => setOpen(false)} visible={open} height={290}>
                <Flex
                    width="100%"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {menuItems?.map((dt) => {
                        return (
                            <Link href={dt?.href}>
                                <CsFlex
                                    onClick={() => setOpen(false)}
                                    width="100%"
                                    height="62px"
                                    alignItems="center"
                                    justifyContent="center"
                                    isActive={activeMenuItem?.href === dt?.href}
                                >
                                    <CsText color={activeMenuItem?.href === dt?.href ? '#fff' : '#C2CADF'}>
                                        {dt?.label}
                                    </CsText>
                                </CsFlex>
                            </Link>
                        )
                    })}
                </Flex>
            </Drawer>
        )
    }

    return (
        <ContainerIcon>
            {mobileDrawer()}
            <MenuIconMobile width={32} onClick={() => setOpen(!false)} />
        </ContainerIcon>
    )
}

export default DrawerMenu

const ContainerIcon = styled(Flex)`
    width: auto;
    margin-left: 12px;
    @media screen and (min-width: 1000px) {
        display: none;
    }
`

const CsText = styled(Text)`
    font-size: 20px !important;
`

const CsFlex = styled(Flex)<{ isActive: boolean }>`
    background: ${({ isActive }) => (isActive ? 'rgba(255, 255, 255, 0.10)' : 'transparent')};
    border-left: ${({ isActive }) => (isActive ? '2px solid #F89003' : 'none')};
`
