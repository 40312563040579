import { createAction } from '@reduxjs/toolkit'
import { GiftCardType, StatusType } from './type'

export const fetchLoading = createAction<{isLoading:boolean}>('myvouchers/fetchLoading')
export const fetchDataGiftCard = createAction<GiftCardType>('myvouchers/fetchDataVouchers')
export const getPageActive = createAction<{pageActive:number}>('myvouchers/getPageActive')
export const getPageItem = createAction<{pageItem:number}>('myvouchers/getPageItem')

export const fetchStatus = createAction<{status:StatusType[]}>('myvouchers/fetchStatus')
export const fetchStatusLoading = createAction<{isStatusLoading:boolean}>('myvouchers/fetchStatusLoading')
export const fetTabActive = createAction<{tabActive:number}>('myvouchers/fetTabActive')