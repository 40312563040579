import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 4px;
  height: 22px;
`
const Icon: React.FC<SvgProps> = (props) => (
    <CsSvg width="4" height="22" viewBox="0 0 4 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2.25" r="2" fill="black" />
        <circle cx="2" cy="10.75" r="2" fill="black" />
        <circle cx="2" cy="19.75" r="2" fill="black" />
    </CsSvg>
);

export default Icon;
