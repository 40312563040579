import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg viewBox="0 0 139 52" fill="none" {...props}>
        <path d="M132.756 25.872C132.756 28.5955 134.951 30.9322 137.708 30.9322L137.7 45.3324C137.7 49.015 134.678 52 130.951 52L6.74842 52C3.02124 52 0 49.015 0 45.3324L0.00801802 30.9322C2.76453 30.9322 5 28.7235 5 26C5 23.2765 2.76453 21.0678 0.00801802 21.0678L0 6.65926C0 2.98504 3.02124 0 6.74842 0H130.951C134.678 0 137.7 2.98504 137.7 6.65926L137.708 21.0678C134.951 21.0678 132.756 23.1485 132.756 25.872Z" fill="url(#paint0_linear_103_35329)"/>
        <defs>
        <linearGradient id="paint0_linear_103_35329" x1="127.381" y1="77.1572" x2="-2.6247" y2="-30.87" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0B2168"/>
        <stop offset="1" stopColor="#1854DF"/>
        </linearGradient>
        </defs>
    </Svg>
);

export default Icon;
