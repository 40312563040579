import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 310 10" fill="none" {...props}>
            <path d="M133.384 3.16016H5.92847V4.21763H133.384V3.16016Z" fill="#494949" />
            <path
                d="M5.92841 3.68986C5.92841 5.0117 4.83201 6.06917 3.46152 6.06917C2.09102 6.06917 0.994629 5.0117 0.994629 3.68986C0.994629 2.36802 2.09102 1.31055 3.46152 1.31055C4.83201 1.31055 5.92841 2.36802 5.92841 3.68986Z"
                fill="#494949"
            />
            <path
                d="M304.961 3.6879C304.961 2.36607 306.057 1.30859 307.428 1.30859C308.798 1.30859 309.895 2.36607 309.895 3.6879C309.895 5.00974 308.798 6.06721 307.428 6.06721C306.057 6.06721 304.961 5.00974 304.961 3.6879Z"
                fill="#494949"
            />
            <path d="M304.961 3.16016H177.505V4.21763H304.961V3.16016Z" fill="#494949" />
            <path
                fill="none"
                d="M164.082 1.05664L154.582 9.05664L145.082 1.05664"
                stroke="#494949"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default Icon;
