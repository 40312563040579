import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <Svg viewBox="0 0 190 75" fill="none" {...props}>
        <path d="M183.029 37.0019C183.029 39.9133 185.133 42.4815 187.98 42.9763V67.5302C187.98 70.6491 185.418 73.1978 182.232 73.1978H7.73866C4.55236 73.1978 1.99024 70.6491 1.99024 67.5302L1.99023 42.9797C4.81545 42.5089 6.98222 40.0776 6.98222 37.1299C6.98222 34.1823 4.81545 31.7509 1.99023 31.2802V6.85701C1.99023 3.74721 4.55154 1.19775 7.73865 1.19775L182.232 1.19776C185.419 1.19776 187.98 3.74722 187.98 6.85701V31.2768C185.179 31.7255 183.029 34.0359 183.029 37.0019Z" stroke="#3B71FE" fill="white" strokeWidth="2"/>
    </Svg>
);

export default Icon;
