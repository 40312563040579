import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 25" {...props}>
            <path d="M17.62 10.8159C17.19 10.8159 16.85 10.4659 16.85 10.0459C16.85 9.67592 16.48 8.90592 15.86 8.23592C15.25 7.58592 14.58 7.20592 14.02 7.20592C13.59 7.20592 13.25 6.85592 13.25 6.43592C13.25 6.01592 13.6 5.66592 14.02 5.66592C15.02 5.66592 16.07 6.20592 16.99 7.17592C17.85 8.08592 18.4 9.21592 18.4 10.0359C18.4 10.4659 18.05 10.8159 17.62 10.8159Z" fill="#FEB318"/>
            <path d="M21.2298 10.8159C20.7998 10.8159 20.4598 10.4659 20.4598 10.0459C20.4598 6.49592 17.5698 3.61592 14.0298 3.61592C13.5998 3.61592 13.2598 3.26592 13.2598 2.84592C13.2598 2.42592 13.5998 2.06592 14.0198 2.06592C18.4198 2.06592 21.9998 5.64592 21.9998 10.0459C21.9998 10.4659 21.6498 10.8159 21.2298 10.8159Z" fill="#FEB318"/>
            <path d="M11.05 15.0159L9.2 16.8659C8.81 17.2559 8.19 17.2559 7.79 16.8759C7.68 16.7659 7.57 16.6659 7.46 16.5559C6.43 15.5159 5.5 14.4259 4.67 13.2859C3.85 12.1459 3.19 11.0059 2.71 9.87592C2.24 8.73592 2 7.64592 2 6.60592C2 5.92592 2.12 5.27592 2.36 4.67592C2.6 4.06592 2.98 3.50592 3.51 3.00592C4.15 2.37592 4.85 2.06592 5.59 2.06592C5.87 2.06592 6.15 2.12592 6.4 2.24592C6.66 2.36592 6.89 2.54592 7.07 2.80592L9.39 6.07592C9.57 6.32592 9.7 6.55592 9.79 6.77592C9.88 6.98592 9.93 7.19592 9.93 7.38592C9.93 7.62592 9.86 7.86592 9.72 8.09592C9.59 8.32592 9.4 8.56592 9.16 8.80592L8.4 9.59592C8.29 9.70592 8.24 9.83592 8.24 9.99592C8.24 10.0759 8.25 10.1459 8.27 10.2259C8.3 10.3059 8.33 10.3659 8.35 10.4259C8.53 10.7559 8.84 11.1859 9.28 11.7059C9.73 12.2259 10.21 12.7559 10.73 13.2859C10.83 13.3859 10.94 13.4859 11.04 13.5859C11.44 13.9759 11.45 14.6159 11.05 15.0159Z" fill="#FEB318"/>
            <path d="M21.9696 18.3959C21.9696 18.6759 21.9196 18.9659 21.8196 19.2459C21.7896 19.3259 21.7596 19.4059 21.7196 19.4859C21.5496 19.8459 21.3296 20.1859 21.0396 20.5059C20.5496 21.0459 20.0096 21.4359 19.3996 21.6859C19.3896 21.6859 19.3796 21.6959 19.3696 21.6959C18.7796 21.9359 18.1396 22.0659 17.4496 22.0659C16.4296 22.0659 15.3396 21.8259 14.1896 21.3359C13.0396 20.8459 11.8896 20.1859 10.7496 19.3559C10.3596 19.0659 9.96961 18.7759 9.59961 18.4659L12.8696 15.1959C13.1496 15.4059 13.3996 15.5659 13.6096 15.6759C13.6596 15.6959 13.7196 15.7259 13.7896 15.7559C13.8696 15.7859 13.9496 15.7959 14.0396 15.7959C14.2096 15.7959 14.3396 15.7359 14.4496 15.6259L15.2096 14.8759C15.4596 14.6259 15.6996 14.4359 15.9296 14.3159C16.1596 14.1759 16.3896 14.1059 16.6396 14.1059C16.8296 14.1059 17.0296 14.1459 17.2496 14.2359C17.4696 14.3259 17.6996 14.4559 17.9496 14.6259L21.2596 16.9759C21.5196 17.1559 21.6996 17.3659 21.8096 17.6159C21.9096 17.8659 21.9696 18.1159 21.9696 18.3959Z" fill="#FEB318"/>
        </Svg>
    );
};

export default Icon;



