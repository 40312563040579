import { ChainId } from "@pancakeswap/sdk"
import { bscTestnetTokens, bscTokens, onusTestnetTokens, onusMainnetTokens } from "@pancakeswap/tokens"

export const getToken = (chainId:number) => {
    if ( chainId === ChainId.BSC_TESTNET ) {
        return bscTestnetTokens?.vndc2
    }
    if ( chainId === ChainId.ONUS_TESTNET ) {
        return onusTestnetTokens?.vndc2
    }
    if ( chainId === ChainId.BSC ) {
        return bscTokens?.vndc2
    }
    if ( chainId === ChainId.ONUS ) {
        return onusMainnetTokens?.vndc
    }
    return bscTokens.vndc2
}

export const listTokenGetBalance = (chainId:number) => {
    if(chainId === ChainId.BSC_TESTNET || chainId === ChainId.ONUS_TESTNET){
        return [bscTestnetTokens?.vndc2, onusTestnetTokens?.vndc2]
    }
    if(chainId === ChainId.BSC || chainId === ChainId.ONUS){
        return [bscTokens?.vndc2, onusMainnetTokens?.vndc]
    }
    return [bscTokens?.vndc2, onusMainnetTokens?.vndc]
}