import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1048 1.8853C17.8047 1.59159 17.3223 1.59425 17.0255 1.89127L13.9718 4.94661C12.7558 4.2955 11.4046 3.93948 10.0001 3.93948C6.47874 3.93948 3.29264 6.1774 1.73192 9.69267C1.64502 9.8884 1.64502 10.1118 1.73192 10.3075C2.43689 11.8953 3.47347 13.2225 4.72277 14.2006L1.88929 17.0356C1.59021 17.3348 1.59294 17.819 1.89533 18.1149C2.19549 18.4086 2.67783 18.4059 2.97469 18.1089L6.02839 15.0536C7.24438 15.7047 8.59556 16.0607 10.0001 16.0607C13.5214 16.0607 16.7075 13.8228 18.2682 10.3075C18.3551 10.1118 18.3551 9.8884 18.2682 9.69267C17.5633 8.10485 16.5267 6.77765 15.2774 5.79957L18.1109 2.96457C18.4099 2.66533 18.4072 2.18119 18.1048 1.8853ZM12.8416 6.07744C11.9518 5.67226 10.9903 5.45463 10.0001 5.45463C7.19809 5.45463 4.6263 7.19711 3.25983 10.0001C3.88592 11.2843 4.76504 12.346 5.80339 13.1194L7.38679 11.5351C7.12178 11.085 6.96978 10.5603 6.96978 10.0001C6.96978 8.32649 8.32649 6.96978 10.0001 6.96978C10.5597 6.96978 11.0839 7.12147 11.5337 7.38597L12.8416 6.07744ZM12.6134 8.46503L14.1968 6.88077C15.2351 7.65419 16.1142 8.71582 16.7403 10.0001C15.3739 12.8031 12.8021 14.5455 10.0001 14.5455C9.00982 14.5455 8.04831 14.3279 7.1586 13.9227L8.46643 12.6142C8.9163 12.8787 9.44048 13.0304 10.0001 13.0304C11.6737 13.0304 13.0304 11.6737 13.0304 10.0001C13.0304 9.4399 12.8784 8.91521 12.6134 8.46503ZM10.3858 8.53448C10.2627 8.50215 10.1334 8.48493 10.0001 8.48493C9.16329 8.48493 8.48493 9.16329 8.48493 10.0001C8.48493 10.1337 8.50222 10.2632 8.53468 10.3866L10.3858 8.53448ZM9.61432 11.4657L11.4655 9.61353C11.4979 9.73694 11.5152 9.86649 11.5152 10.0001C11.5152 10.8369 10.8369 11.5152 10.0001 11.5152C9.86677 11.5152 9.73749 11.498 9.61432 11.4657Z"
                fill="text"
            />
        </Svg>
    );
};

export default Icon;
