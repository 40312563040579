const variants = {
    warning: {
        background: "#FFB23719",
        borderColor: "failure",
    },
    danger: {
        background: "#ED4B9E19",
        borderColor: "failure",
    },
    success: {
        background: "rgba(49, 208, 170, 0.1)",
        borderColor: "success",
    },
};

export default variants;
