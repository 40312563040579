import { createAsyncThunk } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { ERC20_ABI } from "config/abi/erc20";
import { authApi } from "config/api/auth";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import multicall from "utils/multicall"
import { DataUser } from "./type";

export const fetchNonceData = async (): Promise<{dataNonce:string|null}> => {
    try {
        const res = await authApi.getNonce()
        
        return {
            dataNonce: res?.data?.nonce
        }
    } catch (error) {
        console.log(error)
        return {
            dataNonce: ""
        }
    }
}


// Async thunks
export const fetchInitialDatauser = createAsyncThunk<{dataUser:any}>(
    'auth/fetchInitialDatauser',
    async () => {
    const { account } = useActiveWeb3React()
    if(account?.length){
        try {
            const res = await authApi.getDataUser({account})
            return {
                dataUser: res?.data
            }
        } catch (error) {
            console.log(error)
            return {
                dataUser: ""
            }
        }
    } else {
        return{
            dataUser: ""
        }
    }
    },
)

export const fetchPublicDataUser = async (contractAddress: string, account:string, tokenAddress:string, decimal:number, chainId:number): Promise<{dataUser:DataUser}> => {
    if(account?.length > 0 ) {
        try {
            const calls = [
                    {
                        address: tokenAddress,
                        name: 'allowance',
                        params: [account, contractAddress]
                    },
                    {
                        address: tokenAddress,
                        name: 'balanceOf',
                        params: [account]
                    }
                ]
                const result = await multicall(ERC20_ABI, calls, chainId)
                return {
                    dataUser: {
                        allowance: new BigNumber(result[0]?.toString()).dividedBy(new BigNumber(10).pow(decimal)).toNumber(),
                        balanceOf: new BigNumber(result[1]?.toString()).dividedBy(new BigNumber(10).pow(decimal)).toNumber(),
                    }
                }
          }
          catch(error) {
            console.log(error)
            return {
                dataUser: {
                    allowance:0,
                    balanceOf:0,

                }
            }
          }
    } else {
        return {
            dataUser: {
                allowance:0,
                balanceOf:0,
            }
        }
    }
} 
