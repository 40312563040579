import { Language } from "../LangSelector/types";

export const footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.pancakeswap.finance/contact-us",
            },
            {
                label: "Blog",
                href: "https://pancakeswap.medium.com/",
            },
            {
                label: "Community",
                href: "https://docs.pancakeswap.finance/contact-us/telegram",
            },
            {
                label: "CAKE",
                href: "https://docs.pancakeswap.finance/tokenomics/cake",
            },
            {
                label: "—",
            },
            {
                label: "Online Store",
                href: "https://pancakeswap.creator-spring.com/",
                isHighlighted: true,
            },
        ],
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.pancakeswap.finance/contact-us/customer-support",
            },
            {
                label: "Troubleshooting",
                href: "https://docs.pancakeswap.finance/help/troubleshooting",
            },
            {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started",
            },
        ],
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/pancakeswap",
            },
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance",
            },
            {
                label: "Bug Bounty",
                href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
            },
            {
                label: "Audits",
                href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited",
            },
            {
                label: "Careers",
                href: "https://docs.pancakeswap.finance/hiring/become-a-chef",
            },
        ],
    },
];

export const socials = [
    {
        label: "Facebook",
        icon: "Facebook",
        href: "https://www.facebook.com/PiBridge.org",
    },
    {
        label: "Twitter",
        icon: "Twitter",
        href: "https://twitter.com/PiBridgeOrg",
    },
    {
        label: "Telegram",
        icon: "Telegram",
        href: "https://t.me/PiBridgeChannel",
    },
    {
        label: "Globe",
        icon: "Globe",
        href: "https://pibridge.org",
    },
];
export const MoreInformation = [
    {
        label: "Whitepaper",
        href: "https://whitepaper.pibridge.org/",
    },
    {
        label: "Smart Contract",
        href: "https://bscscan.com/address/0x45b19b46be1b9006f388873e2c460fccc7d00f15",
    },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
    code: `en${i}`,
    language: `English${i}`,
    locale: `Locale${i}`,
}));
