import BigNumber from 'bignumber.js'
import {
    SerializedFarm,
    DeserializedPool,
    SerializedPool,
    SerializedCakeVault,
    DeserializedCakeVault,
    SerializedLockedCakeVault,
    VaultKey,
} from 'state/types'
import { deserializeToken } from '@pancakeswap/tokens'
import axios from 'axios'
import { BIG_ZERO } from 'utils/bigNumber'
import { isAddress } from 'utils'
import { convertSharesToCake } from 'views/Pools/helpers'

const config = {
    headers: {
        'Content-Type': 'application/json',
        'X-API-Key':
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImExNzNmZTU3LTkxYTAtNDA2Yy1iYzNmLTQ3ZTRiMjQ5Mjc3MCIsIm9yZ0lkIjoiMTUyOTE0IiwidXNlcklkIjoiMTUyNTU4IiwidHlwZUlkIjoiZDc5ZTliMmQtNTI1Zi00MDUzLWI5ZWUtNDM4YjE1MjIyNTE0IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODIzMDkwNzEsImV4cCI6NDgzODA2OTA3MX0.tCzX-rO5d-Twps_ISFYic9RxjSGW8mCRFLGAJcVDmUg',
    },
}

const getMoralistApi = (token: string) => {
    return `https://deep-index.moralis.io/api/v2/erc20/${token}/price?chain=bsc`
}

const getMoralistApiWPi = () => {
    return `https://fuoakpkekqjv.grandmoralis.com:2053/server/functions/GetPricePiUsd`
}

type UserData =
    | DeserializedPool['userData']
    | {
          allowance: number | string
          stakingTokenBalance: number | string
          stakedBalance: number | string
          pendingReward: number | string
      }

export const transformUserData = (userData: UserData) => {
    return {
        allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
        stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
        stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
        pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
    }
}

const transformProfileRequirement = (profileRequirement?: { required: boolean; thresholdPoints: string }) => {
    return profileRequirement
        ? {
              required: profileRequirement.required,
              thresholdPoints: profileRequirement.thresholdPoints
                  ? new BigNumber(profileRequirement.thresholdPoints)
                  : BIG_ZERO,
          }
        : undefined
}

export const transformPool = (pool: SerializedPool): DeserializedPool => {
    const {
        totalStaked,
        stakingLimit,
        numberBlocksForUserLimit,
        userData,
        stakingToken,
        earningToken,
        profileRequirement,
        startBlock,
        ...rest
    } = pool

    return {
        ...rest,
        startBlock,
        profileRequirement: transformProfileRequirement(profileRequirement),
        stakingToken: deserializeToken(stakingToken),
        earningToken: deserializeToken(earningToken),
        userData: transformUserData(userData),
        totalStaked: new BigNumber(totalStaked),
        stakingLimit: new BigNumber(stakingLimit),
        stakingLimitEndBlock: numberBlocksForUserLimit + startBlock,
    }
}

export const transformVault = (vaultKey: VaultKey, vault: SerializedCakeVault): DeserializedCakeVault => {
    const {
        totalShares: totalSharesAsString,
        pricePerFullShare: pricePerFullShareAsString,
        fees: { performanceFee, withdrawalFee, withdrawalFeePeriod },
        userData: {
            isLoading,
            userShares: userSharesAsString,
            cakeAtLastUserAction: cakeAtLastUserActionAsString,
            lastDepositedTime,
            lastUserActionTime,
        },
    } = vault

    const totalShares = totalSharesAsString ? new BigNumber(totalSharesAsString) : BIG_ZERO
    const pricePerFullShare = pricePerFullShareAsString ? new BigNumber(pricePerFullShareAsString) : BIG_ZERO
    const userShares = new BigNumber(userSharesAsString)
    const cakeAtLastUserAction = new BigNumber(cakeAtLastUserActionAsString)
    let userDataExtra
    let publicDataExtra
    if (vaultKey === VaultKey.CakeVault) {
        const {
            totalCakeInVault: totalCakeInVaultAsString,
            totalLockedAmount: totalLockedAmountAsString,
            userData: {
                userBoostedShare: userBoostedShareAsString,
                lockEndTime,
                lockStartTime,
                locked,
                lockedAmount: lockedAmountAsString,
                currentOverdueFee: currentOverdueFeeAsString,
                currentPerformanceFee: currentPerformanceFeeAsString,
            },
        } = vault as SerializedLockedCakeVault

        const totalCakeInVault = new BigNumber(totalCakeInVaultAsString)
        const totalLockedAmount = new BigNumber(totalLockedAmountAsString)
        const lockedAmount = new BigNumber(lockedAmountAsString)
        const userBoostedShare = new BigNumber(userBoostedShareAsString)
        const currentOverdueFee = currentOverdueFeeAsString ? new BigNumber(currentOverdueFeeAsString) : BIG_ZERO
        const currentPerformanceFee = currentPerformanceFeeAsString
            ? new BigNumber(currentPerformanceFeeAsString)
            : BIG_ZERO

        const balance = convertSharesToCake(
            userShares,
            pricePerFullShare,
            undefined,
            undefined,
            currentOverdueFee.plus(currentPerformanceFee).plus(userBoostedShare),
        )
        userDataExtra = {
            lockEndTime,
            lockStartTime,
            locked,
            lockedAmount,
            userBoostedShare,
            currentOverdueFee,
            currentPerformanceFee,
            balance,
        }
        publicDataExtra = { totalLockedAmount, totalCakeInVault }
    } else {
        const balance = convertSharesToCake(userShares, pricePerFullShare)
        const { cakeAsBigNumber } = convertSharesToCake(totalShares, pricePerFullShare)
        userDataExtra = { balance }
        publicDataExtra = { totalCakeInVault: cakeAsBigNumber }
    }

    const performanceFeeAsDecimal = performanceFee && performanceFee / 100

    return {
        totalShares,
        pricePerFullShare,
        ...publicDataExtra,
        fees: { performanceFee, withdrawalFee, withdrawalFeePeriod, performanceFeeAsDecimal },
        userData: {
            isLoading,
            userShares,
            cakeAtLastUserAction,
            lastDepositedTime,
            lastUserActionTime,
            ...userDataExtra,
        },
    }
}

export const getTokenPricesFromFarm = (farms: SerializedFarm[]) => {
    return farms.reduce((prices, farm) => {
        const quoteTokenAddress = isAddress(farm.quoteToken.address)
        const tokenAddress = isAddress(farm.token.address)
        /* eslint-disable no-param-reassign */
        if (quoteTokenAddress && !prices[quoteTokenAddress]) {
            prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
        }
        if (tokenAddress && !prices[tokenAddress]) {
            prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
        }
        /* eslint-enable no-param-reassign */
        return prices
    }, {})
}

export const getTokenPrice = async (tokenAddress: string) => {
    const tokenPrice = await axios
        .get(getMoralistApi(tokenAddress), config)
        .then((resp) => resp.data)
        .catch((e) => e)
    return tokenPrice
}

export const getTokenPriceWPi = async () => {
    const tokenPrice = await axios
        .get(getMoralistApiWPi())
        .then((resp) => resp.data)
        .catch((e) => e)
    return tokenPrice
}
