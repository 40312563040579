import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="24px" height="20px" viewBox="0 0 25 20" fill="none" {...props}>
            <g clipPath="url(#clip0_388_5872)">
            <path d="M22.2306 0.17703C19.0645 1.49982 5.4868 7.16923 1.73581 8.71427C-0.779692 9.70369 0.693156 10.6311 0.693156 10.6311C0.693156 10.6311 2.83991 11.3748 4.6815 11.9304C6.52309 12.4882 7.50428 11.8685 7.50428 11.8685L16.1549 5.98962C19.2235 3.88683 18.486 5.61778 17.7506 6.36145C16.1549 7.9706 13.5165 10.5072 11.3083 12.548C10.3271 13.4135 10.8166 14.1572 11.2468 14.5269C12.8426 15.8881 17.1996 18.6726 17.4434 18.8585C18.7403 19.7838 21.2897 21.1152 21.6775 18.3008L23.2118 8.58605C23.7035 5.30792 24.193 2.27554 24.2545 1.41007C24.4389 -0.694858 22.2306 0.172756 22.2306 0.172756V0.17703Z" fill="#535353"/>
            </g>
            <defs>
            <clipPath id="clip0_388_5872">
            <rect width="24" height="20" fill="white" transform="translate(0.264648)"/>
            </clipPath>
            </defs>
        </Svg>
    );
};

export default Icon;
