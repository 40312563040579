import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg width="38" height="38" viewBox="0 0 38 38" fill="none" {...props}>
            <circle cx="19" cy="19" r="19" fill="#494949" fillOpacity="0.06" />
            <g clipPath="url(#clip0_2928_318)">
                <path
                    d="M24.6329 17.6586C24.2482 17.6586 23.9358 17.9707 23.9358 18.3562V25.6015H13.3942V15.0515H20.6341C21.0188 15.0515 21.3312 14.7394 21.3312 14.3539C21.3312 13.9684 21.0192 13.6562 20.6341 13.6562H12.9646C12.4328 13.6562 12 14.0894 12 14.6216V26.031C12 26.5632 12.4328 26.9963 12.9646 26.9963H24.3655C24.8972 26.9963 25.3301 26.5632 25.3301 26.031V18.3558C25.3301 17.9707 25.0181 17.6582 24.6329 17.6582V17.6586Z"
                    fill="#494949"
                />
                <path
                    d="M27.2014 11.0074L24.0338 11.4691C23.653 11.5244 23.3891 11.8786 23.4444 12.2597C23.5001 12.6407 23.8536 12.9048 24.2344 12.8495L25.3239 12.6908L18.5137 19.0134C18.2414 19.2859 18.2414 19.7275 18.5137 20C18.786 20.2725 19.2272 20.2725 19.4995 20L26.3101 13.677L26.1516 14.7673C26.0959 15.1484 26.3598 15.5026 26.741 15.5579C26.775 15.5627 26.809 15.5651 26.8423 15.5651C27.1826 15.5651 27.4805 15.3151 27.531 14.9681C27.531 14.9653 27.9927 11.7948 27.9923 11.7972C28.0599 11.33 27.6571 10.9409 27.2014 11.0074Z"
                    fill="#494949"
                />
            </g>
            <defs>
                <clipPath id="clip0_2928_318">
                    <rect width="16" height="16" fill="white" transform="translate(12 11)" />
                </clipPath>
            </defs>
        </Svg>
    );
};

export default Icon;
