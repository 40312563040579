import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.8547 6.43652V4.91189C2.8547 2.56987 4.73438 0.666748 7.05879 0.666748C9.3832 0.666748 11.2629 2.56987 11.2629 4.91189V6.43652H12.4528C13.1292 6.43652 13.6667 6.99143 13.6667 7.66318V16.1068C13.6667 16.7785 13.1292 17.3334 12.4528 17.3334H1.54727C0.870904 17.3334 0.333374 16.7785 0.333374 16.1068V7.66318C0.333374 6.99143 0.870904 6.43652 1.54727 6.43652H2.8547ZM1.85236 7.95167V15.8183H12.1477V7.95167H1.85236ZM9.74389 6.41318V4.91189C9.74389 3.40165 8.53917 2.1819 7.05879 2.1819C5.57841 2.1819 4.37369 3.40165 4.37369 4.91189V6.41318H9.74389Z"
                fill="text"
            />
        </Svg>
    );
};

export default Icon;
