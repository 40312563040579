import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 36 36" fill="none" {...props}>
            <path
                fill="none"
                d="M27.5251 9.33L18.165 4.125L8.41504 9.54"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M20.3551 29.0256L29.9101 23.7156V13.1406"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M6.42004 13.3945V23.7145L15.4801 28.7545"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M23.535 20.4454V13.9204L18.165 10.6504L12.795 13.9204V20.4454L18.165 23.7154L23.535 20.4454Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M18.165 23.7144V17.1895"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M12.795 13.9199L18.165 17.1899"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M23.5351 13.9199L18.165 17.1899"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M6.42 12.9598C7.68749 12.9598 8.715 11.9256 8.715 10.6499C8.715 9.37408 7.68749 8.33984 6.42 8.33984C5.1525 8.33984 4.125 9.37408 4.125 10.6499C4.125 11.9256 5.1525 12.9598 6.42 12.9598Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M18.06 31.8739C19.3275 31.8739 20.355 30.8397 20.355 29.5639C20.355 28.2881 19.3275 27.2539 18.06 27.2539C16.7925 27.2539 15.765 28.2881 15.765 29.5639C15.765 30.8397 16.7925 31.8739 18.06 31.8739Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                d="M29.5799 12.9598C30.8474 12.9598 31.8749 11.9256 31.8749 10.6499C31.8749 9.37408 30.8474 8.33984 29.5799 8.33984C28.3124 8.33984 27.2849 9.37408 27.2849 10.6499C27.2849 11.9256 28.3124 12.9598 29.5799 12.9598Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default Icon;
