import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 508 105" fill="none">
        <path d="M501.797 52.9398C501.797 55.8512 503.901 58.4194 506.748 58.9142V98.3324C506.748 101.451 504.186 104 501 104H6.74842C3.56213 104 1 101.451 1 98.3324L1 58.9176C3.82522 58.4468 5.99198 56.0155 5.99198 53.0678C5.99198 50.1202 3.82521 47.6888 1 47.2181V6.65926C1 3.54946 3.56131 1 6.74842 1H501C504.187 1 506.748 3.54946 506.748 6.65926V47.2147C503.947 47.6634 501.797 49.9738 501.797 52.9398Z" stroke="#3C72FF" stroke-width="2"/>
    </svg>
);

export default Icon;
