import { useTranslation } from '@pancakeswap/localization'
import { Menu as UikitMenu, MenuIconMobile } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useRouter } from 'next/router'
import UserMenu from './UserMenu'
import { contactConfig } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem, getParentMenu } from './utils'
import DrawerMenu from './MobileDrawer'

const Menu = (props) => {
    const { t } = useTranslation()
    const { pathname, asPath  } = useRouter()
  const menuItems = useMenuItems()
  const url = getParentMenu(pathname)


  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, url })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, url })

    const isShowBgHeader = pathname === "/faq"
    const isMarginBottom = pathname === "/faq"
    return (
        <>
            <UikitMenu
                linkComponent={(linkProps) => {
                    return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
                }}
                rightSide={
                    <>
                        <NetworkSwitcher />
                        <UserMenu />
                        <DrawerMenu />
                    </>
                }
                links={menuItems}
                contactInfo={contactConfig}
                subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
                // footerLinks={socialsConfig}
                activeItem={activeMenuItem?.href}
                activeSubItem={activeSubMenuItem?.href}
                isBgColor={isShowBgHeader}
                isMarginBottom={isMarginBottom}
                {...props}
            />
        </>
    )
}

export default Menu
