import { createAction } from '@reduxjs/toolkit'
import { BrandItem, Brandtype, Categorytype, DataUser, dataBuyVouchersType, dataVouchersType, productType, resultListBalanceByChain, listProvinceType, TokenSupportList, TokenSupportBalanceList } from './type'

export const fetchActivePrice = createAction<{price:productType}>('giftCards/fetchActivePrice')
export const fetchActiveBrand = createAction<{product:productType}>('giftCards/fetchActiveBrand')
export const fetchStatusScreen = createAction<{isActiveScreen:number}>('giftCards/fetchStatusScreen')
export const fetchActiveCategory = createAction<{category:string|null}>('giftCards/fetchActiveCategory')
export const getStatusLoading = createAction<{ isLoading: boolean }>("giftCards/getStatusLoading");
export const fetchListCategory = createAction<{ListCategory:Categorytype[]|null}>('giftCards/fetchListCategory')
export const fetchPublicBrand = createAction<{ListBrand:Brandtype[] | null}>('giftCards/fetchPublicBrand')
export const fetchProvinceId = createAction<{cityId:string | null}>('giftCards/fetchProvinceId')
export const fetchListProvinceId = createAction<{ListProvinceId:string[] | null}>('giftCards/fetchListProvinceId')
export const fetchDetailBrand = createAction<{brandItem:BrandItem[]}>('giftCards/fetchDetailBrand')
export const fetchDataVouchers = createAction<{dataVouchers:dataVouchersType}>('giftCards/fetchDataVouchers')
export const fetchDataUser = createAction<{dataUser:DataUser}>('giftCards/fetchDataUser')
export const fetchDataUserByChain = createAction<{ListBalance:resultListBalanceByChain[]}>('giftCards/fetchDataUserByChain')

export const fetchBuyVouchers = createAction<{dataBuyVouchers:dataBuyVouchersType}>('giftCards/fetchBuyVouchers')

export const fetchFillEmail = createAction<{email:string}>('giftCards/fetchFillEmail')
export const fetchFillPhoneNumber = createAction<{phoneNumber:string}>('giftCards/fetchFillPhoneNumber')
export const fetchFillAmount = createAction<{fillAmount:number}>('giftCards/fetchFillAmount')
export const fetchIsUpdateOrder = createAction<{isUpdate:boolean}>('giftCards/fetchIsUpdateOrder')
export const fetchListProvinceByBrandId = createAction<{brandId:string | null}>('giftCards/fetchListProvinceByBrandId')
export const fetchListProvinceByBrand = createAction<{ListProvinceByBrand:string[] | null}>('giftCards/fetchListProvinceByBrand')
export const fetchListProvince = createAction<{listProvince:listProvinceType[] | null}>('giftCards/fetchListProvince')
export const fetchTokenSupport = createAction<{tokenSupport:TokenSupportList[] | null}>('giftCards/fetchTokenSupport')
export const fetchTokenSupportBalance = createAction<{tokenSupportBalance: TokenSupportBalanceList[] | null}>('giftCards/fetchTokenSupportBalance')
export const fetchActiveTokenPayment = createAction<{activeTokenPayment: string}>('giftCards/fetchActiveTokenPayment')
