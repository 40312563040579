import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 14 17" fill="none" {...props}>
            <path
                fill="none"
                d="M9.58693 3.33398H4.41309C3.69873 3.33398 3.11963 3.91309 3.11963 4.62744C3.11963 5.3418 3.69873 5.9209 4.41309 5.9209H9.58693C10.3013 5.9209 10.8804 5.3418 10.8804 4.62744C10.8804 3.91309 10.3013 3.33398 9.58693 3.33398Z"
                stroke="#494949"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.57027 9.76078C3.73532 9.76078 3.89666 9.71184 4.03389 9.62014C4.17112 9.52845 4.27808 9.39812 4.34124 9.24563C4.4044 9.09315 4.42092 8.92536 4.38873 8.76349C4.35653 8.60161 4.27705 8.45292 4.16034 8.33621C4.04364 8.21951 3.89495 8.14003 3.73307 8.10783C3.57119 8.07563 3.40341 8.09216 3.25092 8.15532C3.09844 8.21848 2.96811 8.32544 2.87642 8.46267C2.78472 8.5999 2.73578 8.76124 2.73578 8.92629C2.73578 9.14761 2.8237 9.35986 2.9802 9.51636C3.13669 9.67286 3.34895 9.76078 3.57027 9.76078Z"
                fill="#494949"
            />
            <path
                d="M6.99996 9.76078C7.165 9.76078 7.32634 9.71184 7.46358 9.62014C7.60081 9.52845 7.70776 9.39812 7.77093 9.24563C7.83409 9.09315 7.85061 8.92536 7.81841 8.76349C7.78621 8.60161 7.70674 8.45292 7.59003 8.33621C7.47332 8.21951 7.32463 8.14003 7.16276 8.10783C7.00088 8.07563 6.83309 8.09216 6.68061 8.15532C6.52813 8.21848 6.3978 8.32544 6.3061 8.46267C6.21441 8.5999 6.16547 8.76124 6.16547 8.92629C6.16547 9.14761 6.25339 9.35986 6.40988 9.51636C6.56638 9.67286 6.77864 9.76078 6.99996 9.76078Z"
                fill="#494949"
            />
            <path
                d="M10.4966 9.76078C10.6616 9.76078 10.823 9.71184 10.9602 9.62014C11.0974 9.52845 11.2044 9.39812 11.2676 9.24563C11.3307 9.09315 11.3473 8.92536 11.3151 8.76349C11.2829 8.60161 11.2034 8.45292 11.0867 8.33621C10.97 8.21951 10.8213 8.14003 10.6594 8.10783C10.4975 8.07563 10.3297 8.09216 10.1773 8.15532C10.0248 8.21848 9.89444 8.32544 9.80275 8.46267C9.71105 8.5999 9.66211 8.76124 9.66211 8.92629C9.66211 9.14761 9.75003 9.35986 9.90653 9.51636C10.063 9.67286 10.2753 9.76078 10.4966 9.76078Z"
                fill="#494949"
            />
            <path
                d="M3.57027 14.0909C3.73532 14.0909 3.89666 14.0419 4.03389 13.9502C4.17112 13.8585 4.27808 13.7282 4.34124 13.5757C4.4044 13.4232 4.42092 13.2554 4.38873 13.0936C4.35653 12.9317 4.27705 12.783 4.16034 12.6663C4.04364 12.5496 3.89495 12.4701 3.73307 12.4379C3.57119 12.4057 3.40341 12.4222 3.25092 12.4854C3.09844 12.5486 2.96811 12.6555 2.87642 12.7927C2.78472 12.93 2.73578 13.0913 2.73578 13.2564C2.73578 13.4777 2.8237 13.6899 2.9802 13.8464C3.13669 14.0029 3.34895 14.0909 3.57027 14.0909Z"
                fill="#494949"
            />
            <path
                d="M6.99996 14.0909C7.165 14.0909 7.32634 14.0419 7.46358 13.9502C7.60081 13.8585 7.70776 13.7282 7.77093 13.5757C7.83409 13.4232 7.85061 13.2554 7.81841 13.0936C7.78621 12.9317 7.70674 12.783 7.59003 12.6663C7.47332 12.5496 7.32463 12.4701 7.16276 12.4379C7.00088 12.4057 6.83309 12.4222 6.68061 12.4854C6.52813 12.5486 6.3978 12.6555 6.3061 12.7927C6.21441 12.93 6.16547 13.0913 6.16547 13.2564C6.16547 13.4777 6.25339 13.6899 6.40988 13.8464C6.56638 14.0029 6.77864 14.0909 6.99996 14.0909Z"
                fill="#494949"
            />
            <path
                d="M10.4966 14.0909C10.6616 14.0909 10.823 14.0419 10.9602 13.9502C11.0974 13.8585 11.2044 13.7282 11.2676 13.5757C11.3307 13.4232 11.3473 13.2554 11.3151 13.0936C11.2829 12.9317 11.2034 12.783 11.0867 12.6663C10.97 12.5496 10.8213 12.4701 10.6594 12.4379C10.4975 12.4057 10.3297 12.4222 10.1773 12.4854C10.0248 12.5486 9.89444 12.6555 9.80275 12.7927C9.71105 12.93 9.66211 13.0913 9.66211 13.2564C9.66211 13.4777 9.75003 13.6899 9.90653 13.8464C10.063 14.0029 10.2753 14.0909 10.4966 14.0909Z"
                fill="#494949"
            />
            <path
                fill="none"
                d="M10.4298 16.2105H3.57024C3.23241 16.2116 2.89769 16.1458 2.58536 16.0171C2.27302 15.8883 1.98925 15.699 1.75036 15.4601C1.51147 15.2212 1.32219 14.9374 1.19342 14.6251C1.06464 14.3128 0.998911 13.9781 1.00001 13.6402V3.35931C0.998911 3.02147 1.06464 2.68675 1.19342 2.37442C1.32219 2.06209 1.51147 1.77831 1.75036 1.53942C1.98925 1.30053 2.27302 1.11125 2.58536 0.982478C2.89769 0.853702 3.23241 0.787974 3.57024 0.789076H10.4298C10.7676 0.787974 11.1023 0.853702 11.4146 0.982478C11.727 1.11125 12.0108 1.30053 12.2496 1.53942C12.4885 1.77831 12.6778 2.06209 12.8066 2.37442C12.9354 2.68675 13.0011 3.02147 13 3.35931V13.6402C13.0011 13.9781 12.9354 14.3128 12.8066 14.6251C12.6778 14.9374 12.4885 15.2212 12.2496 15.4601C12.0108 15.699 11.727 15.8883 11.4146 16.0171C11.1023 16.1458 10.7676 16.2116 10.4298 16.2105V16.2105Z"
                stroke="#494949"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default Icon;
