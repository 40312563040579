import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 104 40" {...props}>
<mask id="mask0_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="32" y="19" width="1" height="2">
<path d="M32.0467 20.0173V19.9961H32.0361L32.0467 20.0173Z" fill="white"/>
</mask>
<g mask="url(#mask0_103_32049)">
<path d="M32.0467 19.9961H32.0361V20.0173H32.0467V19.9961Z" fill="url(#paint0_linear_103_32049)"/>
</g>
<mask id="mask1_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="8" y="12" width="16" height="18">
<path d="M23.499 16.7745L23.2171 16.6135L16.2002 12.5664L9.21345 16.5933L8.90137 16.7745V17.4893V25.2009L16.2002 29.409L23.499 25.2009V17.4893V16.7745ZM22.2708 20.6706L16.2002 24.174L10.1396 20.6706V17.4893L10.5625 17.2477L10.7538 17.137L16.2002 13.996L16.6633 14.2678L18.3042 15.2141L13.1699 18.184L12.8578 18.3652L11.9316 18.8988L13.1699 19.6135L14.4082 18.8988L15.3747 18.3451L19.5526 15.939L21.6567 17.1571L16.8646 19.9256L14.972 20.9928L16.2102 21.7075L22.2708 18.2041V20.6706ZM16.2002 27.2748L10.1396 23.7713V22.7243L16.2002 26.2278L22.2607 22.7243V23.7713L16.2002 27.2748Z" fill="white"/>
</mask>
<g mask="url(#mask1_103_32049)">
<path d="M23.4987 12.5664H8.91113V29.4191H23.4987V12.5664Z" fill="url(#paint1_linear_103_32049)"/>
</g>
<mask id="mask2_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="17" y="9" width="15" height="8">
<path d="M27.1434 9.55631C26.6501 9.97914 26.1769 10.1402 25.6937 10.3416C24.838 10.6939 23.2977 11.1167 20.7909 11.1167C20.2372 11.1167 19.6533 11.0966 19.0493 11.0563C18.9083 11.4288 18.6063 11.8718 17.9922 12.1637L25.2507 16.3719L31.8851 12.5161L27.2239 9.48584C27.2038 9.50597 27.1736 9.52611 27.1434 9.55631Z" fill="white"/>
</mask>
<g mask="url(#mask2_103_32049)">
<path d="M17.6797 9.48607L32.6599 9.4458L32.972 16.4527L17.9918 16.503L17.6797 9.48607Z" fill="url(#paint2_linear_103_32049)"/>
</g>
<mask id="mask3_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="9" width="15" height="7">
<path d="M0.958984 11.469L7.78463 15.9993L14.3485 12.1939C14.0968 12.0932 13.8552 11.9724 13.6337 11.8214C13.5331 11.8214 13.4223 11.8315 13.3116 11.8315C11.9525 11.8315 10.2713 11.469 8.32827 10.7442C7.02958 10.271 5.92218 9.81799 5.04632 9.11328L0.958984 11.469Z" fill="white"/>
</mask>
<g mask="url(#mask3_103_32049)">
<path d="M0.938477 9.11302L14.3482 9.07275L14.3683 16.3514L0.958611 16.3917L0.938477 9.11302Z" fill="url(#paint3_linear_103_32049)"/>
</g>
<mask id="mask4_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="25" y="13" width="8" height="22">
<path d="M25.291 17.9323V34.7045L32.0462 30.738V13.9658L25.291 17.9323Z" fill="white"/>
</mask>
<g mask="url(#mask4_103_32049)">
<path d="M24.6973 12.5665L32.0464 12.5464L32.1068 34.9462L25.2912 34.7247L24.6973 12.5665Z" fill="url(#paint4_linear_103_32049)"/>
</g>
<mask id="mask5_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="8" y="26" width="16" height="14">
<path d="M8.90137 35.0569L16.2002 40L23.5091 35.6811V26.6406L16.2002 30.8588L8.90137 26.6406V35.0569Z" fill="white"/>
</mask>
<g mask="url(#mask5_103_32049)">
<path d="M8.87109 25.8652L23.509 26.4793L23.5392 40.6239L8.9013 40.0098L8.87109 25.8652Z" fill="url(#paint5_linear_103_32049)"/>
</g>
<mask id="mask6_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="14" y="2" width="4" height="6">
<path d="M14.4082 3.70734C14.9015 4.63354 15.4552 5.91209 15.6868 7.30138C15.7472 7.30138 15.8076 7.30138 15.8579 7.30138C15.9787 7.30138 16.0894 7.30138 16.2002 7.31145C16.4821 6.18391 17.0458 4.87515 17.8311 3.61674L16.2002 2.67041L14.4082 3.70734Z" fill="white"/>
</mask>
<g mask="url(#mask6_103_32049)">
<path d="M14.3877 2.67022L17.8307 2.66016L17.8408 7.31126L14.4078 7.32133L14.3877 2.67022Z" fill="url(#paint6_linear_103_32049)"/>
</g>
<mask id="mask7_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="12" width="8" height="23">
<path d="M0.354421 29.359L7.65323 34.3121V17.5299L0.324219 12.5566L0.354421 29.359Z" fill="white"/>
</mask>
<g mask="url(#mask7_103_32049)">
<path d="M0.293945 12.5568L7.65316 12.5366L7.71356 34.3122L0.354349 34.3626L0.293945 12.5568Z" fill="url(#paint7_linear_103_32049)"/>
</g>
<mask id="mask8_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="11" width="1" height="1">
<path d="M0.354492 11.8218V11.8424L0.375084 11.8115L0.354492 11.8218Z" fill="white"/>
</mask>
<g mask="url(#mask8_103_32049)">
<path d="M0.374627 11.8115H0.354492V11.8518H0.374627V11.8115Z" fill="url(#paint8_linear_103_32049)"/>
</g>
<mask id="mask9_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="4" y="0" width="24" height="12">
<path d="M17.8009 8.54968C17.8009 8.54968 18.2137 7.8953 18.4855 7.53288C18.8177 7.08991 19.5224 6.20399 20.7909 5.03618C22.0594 3.87844 23.4487 3.58649 24.3648 3.96904C25.281 4.3516 25.8045 5.29793 25.7843 6.15365C25.7743 6.46574 25.6132 7.09998 25.2608 7.4624C24.4554 8.28793 22.8648 8.73089 20.5694 8.73089C19.7339 8.73089 18.8177 8.67048 17.8009 8.54968ZM6.64634 6.78789C6.21344 6.05298 6.19331 4.98584 6.81748 4.43214C7.72354 3.64689 9.01216 3.61669 9.97862 4.25093C10.9451 4.88517 12.6263 6.44561 13.9854 9.4054C13.925 9.41547 13.8545 9.41547 13.774 9.41547C12.3646 9.41547 7.57253 8.3584 6.64634 6.78789ZM7.54233 0.274336C6.48526 1.03945 4.12951 3.2744 4.48186 5.64022C4.60267 6.47581 4.88456 7.09998 5.28725 7.59328C6.0423 8.51947 7.24031 9.01277 8.75041 9.57654C11.086 10.4423 12.8176 10.694 13.9854 10.543C15.0022 11.4088 16.4217 11.4088 17.2875 11.0967C18.1533 10.7947 17.9519 10.1302 17.8009 9.67722C22.1702 10.1806 24.395 9.52621 25.2206 9.18392C25.6434 9.01277 26.2877 8.64028 26.64 8.32819C27.1031 7.93557 27.526 7.26106 27.8683 6.06305C28.4824 3.92877 26.1367 0.677029 25.4823 0.324672C24.8179 -0.0276839 23.6501 -0.490781 21.4151 1.34147C19.1701 3.17373 17.5996 6.22412 17.2875 8.20739C17.2875 8.20739 16.9351 8.3584 16.8345 8.66042C16.4318 8.50941 15.415 8.50941 14.5492 8.66042C14.5995 5.66035 12.2639 2.40861 11.2974 1.44215C10.5726 0.727365 9.42492 0.00251808 8.44839 0.00251808C8.11617 0.00251808 7.81415 0.0830567 7.54233 0.274336Z" fill="white"/>
</mask>
<g mask="url(#mask9_103_32049)">
<path d="M4.09961 -0.480798L28.4726 -0.55127L28.5028 11.4087L4.12981 11.4792L4.09961 -0.480798Z" fill="url(#paint9_linear_103_32049)"/>
</g>
<mask id="mask10_103_32049" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="1" y="11" width="1" height="1">
<path d="M1.91504 11.8218V11.8424L1.93563 11.8115L1.91504 11.8218Z" fill="white"/>
</mask>
<g mask="url(#mask10_103_32049)">
<path d="M1.93517 11.8115H1.91504V11.8518H1.93517V11.8115Z" fill="url(#paint10_linear_103_32049)"/>
</g>
<path d="M43.6137 9.99981H45.295L42.8385 16.453L42.003 18.6476L41.1674 16.4429L38.7109 9.98975H40.3821V9.99981L42.003 14.2482L43.6137 9.99981Z" fill="white"/>
<path d="M46.7646 18.6275V9.98975H48.3251V18.6275H46.7646Z" fill="white"/>
<path d="M51.3759 9.99981H56.3894V11.5602H51.3759V13.5334H56.3894V15.0939H51.3759V17.0671H56.3894V18.6275H49.8154V9.98975L51.3759 9.99981Z" fill="white"/>
<path d="M61.9461 9.99951H64.4529V11.5599H61.9461V18.6373H60.3857V11.5599H57.8789V9.99951H60.3857H61.9461Z" fill="white"/>
<path d="M70.9566 9.99963H72.517V18.6475L70.9566 17.1072L67.5136 13.7145V18.6273H65.9531V9.97949L67.5136 11.5198L70.9566 14.9125V9.99963Z" fill="white"/>
<path d="M74.0068 18.6376L76.4633 12.1844L77.2989 9.98975L78.1344 12.1945L79.7049 16.312L80.2989 17.8725L80.5909 18.6476H78.9197L78.6277 17.8725H75.98L75.6881 18.6476H74.0068V18.6376ZM78.0237 16.2919L77.2989 14.369L76.5639 16.2919H78.0237Z" fill="white"/>
<path d="M88.6251 11.5198L90.1856 9.97949V18.6273H88.6251V13.7145L87.2459 15.0736L86.1284 16.1709L85.0109 15.0736L83.6317 13.7145V18.6273H82.0713V9.97949L83.6317 11.5198L86.1284 13.9762L88.6251 11.5198Z" fill="white"/>
<path d="M40.2714 22.2114H45.2849V23.7719H40.2714V25.7451H45.2849V27.3055H40.2714V29.2787H45.2849V30.8593H38.7109V22.2215L40.2714 22.2114Z" fill="white"/>
<path d="M51.6775 22.2112H53.3487L50.8923 28.6644L50.0567 30.8591L49.2211 28.6543L46.7646 22.2012H48.4358V22.2112L50.0567 26.4596L51.6775 22.2112Z" fill="white"/>
<path d="M61.2824 23.4799C62.0978 24.2954 62.5509 25.3826 62.5509 26.5303C62.5509 27.688 62.0978 28.7653 61.2824 29.5807C60.4669 30.3962 59.3796 30.8492 58.232 30.8492C57.0742 30.8492 55.997 30.3962 55.1816 29.5807C54.3661 28.7653 53.9131 27.678 53.9131 26.5303C53.9131 25.3826 54.3661 24.2954 55.1816 23.4799C55.997 22.6645 57.0843 22.2114 58.232 22.2114C59.3796 22.2114 60.4669 22.6645 61.2824 23.4799ZM58.232 23.7819C56.7118 23.7819 55.4735 25.0202 55.4735 26.5404C55.4735 28.0605 56.7118 29.2988 58.232 29.2988C59.7521 29.2988 60.9904 28.0605 60.9904 26.5404C60.9804 25.0202 59.7421 23.7819 58.232 23.7819Z" fill="white"/>
<path d="M64.0303 27.5669V22.2212H65.5907V27.5669C65.5907 28.5133 66.3659 29.2885 67.3122 29.2885C68.2586 29.2885 69.0337 28.5133 69.0337 27.5669V22.2212H70.5942V27.5669C70.5942 29.3791 69.1143 30.859 67.3022 30.859C65.5102 30.859 64.0303 29.3791 64.0303 27.5669Z" fill="white"/>
<path d="M74.4703 24.5873C73.9468 25.1108 73.6649 25.7954 73.6649 26.5303C73.6649 27.2652 73.9468 27.9599 74.4703 28.4733C75.5475 29.5505 77.2892 29.5505 78.3664 28.4733L78.3764 28.4632L79.4838 29.5706L79.4738 29.5807C78.6583 30.3962 77.5711 30.8492 76.4234 30.8492C75.2656 30.8492 74.1884 30.3962 73.373 29.5807C72.5575 28.7653 72.1045 27.678 72.1045 26.5303C72.1045 25.3726 72.5575 24.2954 73.373 23.4799C74.1884 22.6645 75.2757 22.2114 76.4234 22.2114C77.5811 22.2114 78.6583 22.6645 79.4738 23.4799L79.4838 23.49L78.3764 24.5974L78.3664 24.5873C77.8429 24.0638 77.1583 23.7819 76.4234 23.7819C75.6885 23.7819 74.9837 24.0638 74.4703 24.5873Z" fill="white"/>
<path d="M85.9673 22.2114H87.5278V30.8593H85.9673V27.3256H82.5243V30.8593H80.9639V22.2215H82.5243V25.7551H85.9673V22.2114Z" fill="white"/>
<path d="M90.5878 22.2114H95.6013V23.7719H90.5878V25.7451H95.6013V27.3055H90.5878V29.2787H95.6013V30.8593H89.0273V22.2215L90.5878 22.2114Z" fill="white"/>
<path d="M101.723 28.2918L103.676 30.859H101.713L99.9509 28.5435H98.6522V30.859H97.0918V22.2212H100.505C102.246 22.2212 103.666 23.6407 103.666 25.3823C103.666 26.6508 102.901 27.7985 101.723 28.2918ZM98.6522 26.973H100.505C100.575 26.973 100.635 26.973 100.706 26.9629C101.501 26.8622 102.105 26.1877 102.105 25.3823C102.105 24.5065 101.391 23.7917 100.515 23.7917H98.6522V26.2683V26.822V26.973Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_103_32049" x1="32.0396" y1="20.0033" x2="32.0511" y2="20.0033" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint1_linear_103_32049" x1="8.90816" y1="20.9927" x2="23.5001" y2="20.9927" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="0.4847" stopColor="#A55FE2"/>
<stop offset="1" stopColor="#FF4982"/>
</linearGradient>
<linearGradient id="paint2_linear_103_32049" x1="-2.49704" y1="13.0405" x2="33.629" y2="12.9407" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint3_linear_103_32049" x1="0.186929" y1="12.7585" x2="32.0447" y2="12.6705" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint4_linear_103_32049" x1="0.240487" y1="23.8367" x2="32.0941" y2="23.7487" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint5_linear_103_32049" x1="0.261334" y1="33.2812" x2="32.1273" y2="33.1932" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint6_linear_103_32049" x1="0.173898" y1="5.04281" x2="32.0481" y2="4.95476" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint7_linear_103_32049" x1="0.247845" y1="23.4647" x2="32.1221" y2="23.3766" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint8_linear_103_32049" x1="0.354419" y1="11.8277" x2="0.375114" y2="11.8277" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint9_linear_103_32049" x1="0.173539" y1="5.50756" x2="32.0478" y2="5.41951" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
<linearGradient id="paint10_linear_103_32049" x1="1.91314" y1="11.8277" x2="1.93384" y2="11.8277" gradientUnits="userSpaceOnUse">
<stop stopColor="#00C5DC"/>
<stop offset="1" stopColor="#3791E0"/>
</linearGradient>
</defs>
        </Svg>
    );
};

export default Icon;



