import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="36" height="36" viewBox="0 0 42 42" fill="none" {...props}>
            <rect width="42" height="42" rx="21" fill="#E6E8EC" fillOpacity="0.99"/>
            <path d="M14.5016 28.8103C14.0775 28.8103 13.6534 28.654 13.3185 28.3192C12.6712 27.6718 12.6712 26.6004 13.3185 25.953L25.953 13.3185C26.6004 12.6712 27.6718 12.6712 28.3192 13.3185C28.9665 13.9659 28.9665 15.0373 28.3192 15.6847L15.6847 28.3192C15.3722 28.654 14.9257 28.8103 14.5016 28.8103Z" fill="#292D32"/>
            <path d="M27.1361 28.8103C26.712 28.8103 26.2879 28.654 25.953 28.3192L13.3185 15.6847C12.6712 15.0373 12.6712 13.9659 13.3185 13.3185C13.9659 12.6712 15.0373 12.6712 15.6847 13.3185L28.3192 25.953C28.9665 26.6004 28.9665 27.6718 28.3192 28.3192C27.9844 28.654 27.5602 28.8103 27.1361 28.8103Z" fill="#292D32"/>
        </Svg>
    );
};

export default Icon;
