/* eslint-disable no-console */
import { useCallback } from 'react';

import { useTranslation } from '@pancakeswap/localization';
import { useToast } from '@pancakeswap/uikit';
import { InjectedWallet, useSDK } from "@thirdweb-dev/react";


const useActive = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const injected = new InjectedWallet();

    return useCallback(
        () =>
        injected.connect().catch((error) => {
                console.log('🚀 ~ file: useEagerConnect.ts ~ line 183 ~ activate ~ error', error)
            }),
        [injected],
    )
}
export const useEagerConnect = () => {
    // noop
}

export const useActiveHandle = () => {
    const handleActive = useActive()
    const { toastSuccess } = useToast()
    const { t } = useTranslation()
    const sdk = useSDK()
    const main = async () => {
        /**
         *  backward
         */
        console.log('~ before getAccount')
        const address = await sdk.wallet.getAddress();
        console.log('~ after getAccount', address)
        return new Promise((resolve) => {
            handleActive().then(resolve)
        })
    }
    return async (showToast = true) => {
        await main()
        const address = await sdk.wallet.getAddress();
        if (address && showToast) {
            toastSuccess(t('Success'), 'Wallet connected')
        }
    }
}
export default useEagerConnect
