/* eslint-disable camelcase */
import { AutoRenewIcon, Button, Flex, Heading, Input, Modal, ModalV2, Text } from '@pancakeswap/uikit';
import { useSigner } from "@thirdweb-dev/react";
import { EmbeddedWallet } from "@thirdweb-dev/wallets";
import { KEY_LOGIN } from 'config';
import { authApi } from 'config/api/auth';
import contracts from 'config/constants/contracts';
import { getToken } from 'config/giftcards';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useAuth from 'hooks/useAuth';
import jwt_decode from "jwt-decode";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SiweMessage } from 'siwe';
import { AppDispatch } from 'state';

import { GetDataAuth, UseCoreDataUserAuth, UseCoreNonce } from 'state/auth';
import { fetchEmailUser, fetchPhoneUser } from 'state/auth/actions';
import styled from 'styled-components';
import { getAddress } from 'utils/addressHelpers';
import { getActiveChain } from 'utils/getActiveChain';


export const LoginModal = () => {
    UseCoreNonce()
    const { account, chainId } = useActiveWeb3React()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const wallet = new EmbeddedWallet({
        chain: getActiveChain(chainId), 
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID, 
      });
    const token = getToken(chainId)
    const signer = useSigner();
    const contractAddress = getAddress(contracts.buyVouchers, chainId)
    UseCoreDataUserAuth(contractAddress, account, token?.address, token?.decimals, chainId)
    const dispatch = useDispatch<AppDispatch>()
    const [ openModal, setOpenModal ] = useState(false)
    const [ pendding, setPendding ] = useState(false)
    const { logout } = useAuth()
    const domain = window.location.host;
    const origins = window.location.origin;
    const dataLocalStorage = typeof window !== 'undefined' && localStorage?.getItem(KEY_LOGIN)
    
    const dataAuth = GetDataAuth()
    const {email: emailAuth, phone: phoneAuth} = dataAuth
    const [email, setEmail ] = useState('')
    const [phone, setPhone ] = useState('')
    const [isErrorEmail, setIsErrorEmail ] = useState(false)
    const [ isValidEmail, setEvalidEmail ] = useState(false)

    // validation
    const phonenoFormat = /^\d{10}$/;
    // eslint-disable-next-line no-useless-escape
    const mailformat = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,100}$/;
    const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;

    const [isUnauthorized, setIsUnauthorized ] = useState(false)

    const fetchDtUser = async () => {
        const resCheckAccount = await authApi.getDataUser({account})
        if(resCheckAccount?.data !== null){
            if(resCheckAccount?.data?.email === emailAuth){
                setIsErrorEmail(true)
            }
            
            setEmail(resCheckAccount?.data?.email)
            setPhone(resCheckAccount?.data?.phone)
            dispatch(fetchEmailUser({ email: resCheckAccount?.data?.email }))
            dispatch(fetchPhoneUser({ phone: resCheckAccount?.data?.phone }))
            return  setIsUnauthorized(true)
        }
        dispatch(fetchEmailUser({ email: '' }))
        dispatch(fetchPhoneUser({ phone: '' }))
        setEmail('')
        setPhone('')
        return setIsUnauthorized(false)
    }
    useEffect(() => {
        (async() => {
           try {
            const getEmail = await wallet?.getEmail();
            setEmail(getEmail)
           } catch (error) {
            console.log(error)
           }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        if((account?.length > 0))  {
            setOpenModal(!false)
        } else {
            setOpenModal(false)
        }
    },[account])

    useEffect(() => {
        if(account?.length){
            fetchDtUser()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account, isUnauthorized])

    const handleChangePhone = (e) => {
        const { value } = e.target;
        setPhone(value)
    }

    const handleChangeEmail = async(e) => {
        const { value } = e.target;
        setEmail(value)
        setIsErrorEmail(false)
        if(value?.match(mailformat)){
            const ress = await authApi?.checkEmail({email : value})
            if(ress?.data?.isEmailValid === false){
                setEvalidEmail(true)
            } if(ress?.data?.isEmailValid === true){
                setEvalidEmail(false)
            }
        }
    }
    

    async function createSiweMessage(address, statement) {
        const message = new SiweMessage({
            domain,
            address,
            statement,
            uri: origins,
            version: '1',
            chainId,
            nonce: dataAuth?.dataNonce
        });
        return message.prepareMessage();
    }

    async function signInWithEthereum() {
        if(!email.match(mailformat)){
            setIsErrorEmail(true)
        }
        if((email !== '') && (phone?.match(phonenoFormat) && phone?.match(vnf_regex) && phone !== '')){
            try{
                const ress = await authApi?.checkEmail({email})
                if(ress?.data?.isEmailValid === false){
                    setEvalidEmail(true)
                } if(ress?.data?.isEmailValid === true) {
                    setEvalidEmail(false)
                    setPendding(!false)
                        try {
                            // const signer = await library.getSigner();
                            const message = await createSiweMessage(
                                account,
                                'Sign in with Ethereum to the app.'
                            );
                            const signature = await signer.signMessage(message);
                            const res = await authApi.login({ 
                                nonce: dataAuth?.dataNonce, 
                                message, 
                                signature, 
                                email,
                                phone
                            })
                            if(res?.statusCode === 201) {
                                if(email.match(mailformat) && phone.match(phonenoFormat)){
                                    dispatch(fetchEmailUser({ email })) 
                                    dispatch(fetchPhoneUser({ phone })) 
                                }
                                localStorage?.setItem(KEY_LOGIN, res?.data?.accessToken)
                                setIsErrorEmail(false)
                                setPendding(false)
                            } else {
                                localStorage.removeItem(KEY_LOGIN)
                            }
                        } catch (error) {
                            console.log("sign failed",error)
                        } finally {
                            setPendding(false)
                        }
                }
            } catch {   
                setEvalidEmail(true)
            }
        }
    }
    
    const isEmailAuth = emailAuth !== ''
    const isPhoneAuth = phoneAuth !== ''
    useEffect(() => {
        const checkAccount = async (acc:string) => {
            if(dataLocalStorage){
                try {
                    const tokenAuth = dataLocalStorage;
                    const decoded:any = await jwt_decode(!tokenAuth ? '' : tokenAuth);
                    if (acc?.length === decoded?.sub?.length && acc?.toLowerCase() !== decoded?.sub?.toLowerCase()) {
                        setOpenModal(true)
                    }
                    if (acc?.length === decoded?.sub?.length && acc?.toLowerCase() === decoded?.sub?.toLowerCase()) {
                        setOpenModal(false)
                    }
                } catch (error) {
                    setOpenModal(false)
                }
            }
        }
        if(account !== undefined){
            checkAccount(account)
        }
    }, [dataLocalStorage, account])

        if (openModal) {
            return (
                <ModalV2 isOpen={openModal} onDismiss={() => setOpenModal(false)}>
                    <CsModal
                        title=""
                        hideCloseButton
                        // onDismiss={() => setOpenModal(false)}
                    >
                        <Flex width="100%" justifyContent="left" mt={["1rem",,"0rem"]}>
                            <Heading style={{textAlign: 'left'}}>Thông báo</Heading>
                        </Flex>
                        {
                            (isEmailAuth && isPhoneAuth) ? 
                                <>
                                    <Text width="100%" textAlign="left" mt="1rem">
                                        Vui lòng ký xác nhận vào ví của bạn để hoàn tất quá trình xác thực
                                    </Text>
                                </> 
                            :
                            <>
                                <Text width="100%" textAlign="left" mt="1rem">
                                    Ký và nhập thông tin
                                </Text>
                                <>
                                    <>
                                        {/* phone */}
                                        <Flex mt="0.5rem" justifyContent="flex-start">
                                            <CustomInput2
                                                type="text"
                                                scale="lg"
                                                value={phone}
                                                placeholder="Nhập số điện thoại"
                                                readOnly={(phoneAuth !== '' && phone !== '') ? !false : false}
                                                onChange={(e) => handleChangePhone(e)}
                                            />
                                        </Flex>

                                        {
                                            (!phone.match(phonenoFormat) && phone !== '') && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Số điện thoại không hợp lệ</Text>
                                        }
                                        {
                                            (phone.match(phonenoFormat) && !phone?.match(vnf_regex) && phone !== '') && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Số điện thoại không tồn tại</Text>
                                        }

                                        {/* email */}
                                        <Flex mt="0.5rem" justifyContent="flex-start">
                                            <CustomInput2
                                                type="text"
                                                scale="lg"
                                                value={email}
                                                placeholder="Nhập email"
                                                readOnly={(emailAuth !== '' && email !== '') ? !false : false}
                                                onChange={(e) => handleChangeEmail(e)}
                                            />
                                        </Flex>
                                        {
                                            (!email.match(mailformat) && email !== '') && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Email không hợp lệ</Text>
                                        }
                                        {
                                            (email.match(mailformat) && isErrorEmail === false && isValidEmail === true && email !== '') && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Email không tồn tại</Text>
                                        }
                                        {/* {
                                            (email.match(mailformat) && isErrorDupplyCate && email !== '') && <Text fontSize={["14px", , "16px"]} mt="0.5rem" color="red">Email này đã được sử dụng. Vui lòng nhập email khác!</Text>
                                        } */}
                                    </>
                                </>
                            </> 
                        }
    
                        <Flex width="100%" justifyContent="space-between" flexWrap="wrap" mt="1rem" style={{gap:'1rem'}}> 
                            <Button
                                width={["100%",,"46%"]}
                                variant='secondary'
                                onClick={() => {logout(); setOpenModal(false)}}
                            >
                                Hủy
                            </Button>
                            <Button
                                width={["100%",,"46%"]}
                                onClick={signInWithEthereum}
                                disabled={pendding 
                                    // || (( !email.match(mailformat) && email !== '') && ( !phone.match(phonenoFormat) && phone !== '')) 
                                    || email === '' || phone === '' 
                                }
                                endIcon={pendding ? <AutoRenewIcon color="white" spin/> : null}
                            >
                                {(isEmailAuth && isPhoneAuth) ? 'Ký' : 'Xác nhận'}
                            </Button>
                        </Flex>
                        
                    </CsModal>
                </ModalV2>
            )
    }
    return null
}

const CsModal = styled(Modal)`
    width: 100%;
    max-width: 450px;
    padding: 24px;
    ${Heading} {
        font-weight: 800;
        font-size: 30px;
    }
    @media screen and (max-width:600px) {
        max-width: 350px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 24px
    }
`
export const CustomInput2 = styled(Input)`
    color: ${({ theme }) => theme.colors.text};
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    text-align: left;
    border: none;
    box-shadow: none;
    font-size: 16px;
    border-radius: 11.526px;
    background: #EFEFEF;
    min-width:300px;
    width: 100%;
    height: 48px;
    &::placeholder {
        color: ${({ theme }) => theme.colors.default};
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
`;