import { useEffect } from 'react'

const useEagerConnect = () => {
    
    useEffect(() => {
        // const connectorInstance = connectors.find((c) => c.id === SAFE_ID && c.ready)
        // if (
        //     connectorInstance &&
        //     // @ts-ignore
        //     !window.cy
        // ) {
        //     connectAsync({ connector: connectorInstance }).catch(() => {
        //         client.autoConnect()
        //     })
        // } else {
        //     client.autoConnect()
        // }
        console.log("connectAsync")
    }, [])
}

export default useEagerConnect
