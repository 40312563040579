import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 16px;
  height: 16px;
  @media screen and (max-width: 768px){
    width: 14x;
    height: 14px;
  }
`
const Icon: React.FC<SvgProps> = (props) => (
  <CsSvg width="16" height="16" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.58301 8.57727C1.58301 6.33144 2.48134 5.43311 4.72717 5.43311H7.42218C9.66801 5.43311 10.5663 6.33144 10.5663 8.57727V11.2723C10.5663 13.5181 9.66801 14.4164 7.42218 14.4164H4.72717C2.48134 14.4164 1.58301 13.5181 1.58301 11.2723"
      fill="#fff"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path d="M14.4169 7.42218C14.4169 9.66801 13.5186 10.5663 11.2728 10.5663H10.5669V8.57717C10.5669 6.33134 9.66859 5.43301 7.42276 5.43301H5.43359V4.72717C5.43359 2.48134 6.33193 1.58301 8.57776 1.58301H11.2728C13.5186 1.58301 14.4169 2.48134 14.4169 4.72717"
      fill="#fff"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </CsSvg>
);

export default Icon;
