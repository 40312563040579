import { ChainId } from '@pancakeswap/sdk'
import { GiftCardPaymentTokenType } from './type'

let logged = false

export const getGiftCardPaymentConfig = async (chainId: ChainId) => {
    try {
        return (await import(`/${chainId}.ts`)).default.filter(
            (f: GiftCardPaymentTokenType ) => f.id !== null,
        ) as GiftCardPaymentTokenType[]
    } catch (error) {
        if (!logged) {
            console.error('Cannot get gitf card payment config', error, chainId)
            logged = true
        }
        return []
    }
}
