import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_2552_1291)">
                <path
                    d="M6.79167 11H20.2083C20.6456 11 21 11.4253 21 11.95V12.05C21 12.5747 20.6456 13 20.2083 13H6.79167C6.35444 13 6 12.5747 6 12.05V11.95C6 11.4253 6.35444 11 6.79167 11Z"
                    fill="white"
                />
                <path
                    d="M11.5278 16H20.4722C20.7637 16 21 16.4253 21 16.95V17.05C21 17.5747 20.7637 18 20.4722 18H11.5278C11.2363 18 11 17.5747 11 17.05V16.95C11 16.4253 11.2363 16 11.5278 16Z"
                    fill="white"
                />
                <path
                    d="M3.95 6H20.05C20.5747 6 21 6.42533 21 6.95V7.05C21 7.57467 20.5747 8 20.05 8H3.95C3.42533 8 3 7.57467 3 7.05V6.95C3 6.42533 3.42533 6 3.95 6Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2552_1291">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
            </defs>
        </Svg>
    );
};

export default Icon;
