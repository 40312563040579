/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import styled from "styled-components";
import { Flex } from "../Box";
import { InvoiceIcon, LocationIcon, MenuIconDark, PhoneIcon } from "../Svg";
import { Link } from "../Link";
import {
    ContainerFlex,
    StyledFooter
} from "./styles";
import { FooterProps } from "./types";
import { Heading } from "../Heading";
import { Text } from "../Text";

const MenuItem: React.FC<FooterProps> = ({
    items,
    isDark,
    contactInfo,
    toggleTheme,
    currentLang,
    langs,
    setLang,
    cakePriceUsd,
    buyCakeLabel,
    isMarginBottom,
    ...props
}) => {
    return (
        <StyledFooter
            {...props}
            justifyContent="center"
            isMarginBottom={isMarginBottom}
        >
            <Flex width="100%" justifyContent="center" alignItems="center" flexDirection="column">
                <ContainerFlex>
                    <CsFlexMaxWidth width="100%" flexWrap="wrap">
                        <ColLeft>
                            {/* <MenuIconDark width="183px" /> */}
                            <img src="/images/logoWhite.png?version=1.1" alt="" />
                        </ColLeft>
                        <ColRight flexDirection="column" style={{gap:"12px"}}>
                            <Flex my="1rem">
                                <CsHeading color="text">
                                    CÔNG TY CỔ PHẦN CÔNG NGHỆ GIẢI PHÁP <span style={{whiteSpace: 'nowrap', color: 'white' , fontWeight: '600'}}> QUÀ TẶNG</span> DOANH NGHIỆP
                                </CsHeading>
                            </Flex>
                            {contactInfo?.map((dt) => {
                                const Icon = dt?.icon
                                if(dt?.isExternalLink) {
                                    return (
                                        <Link href={dt?.href} external>
                                            <Flex width="100%">
                                                <Flex width="50px">
                                                    <Icon/>
                                                </Flex>
                                                <Flex width="100%">
                                                    <Text fontWeight={300} color="#fff">{dt?.name}</Text>
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    )
                                }
                                return (
                                    <Flex width="100%">
                                        <Flex width="50px">
                                            <Icon/>
                                        </Flex>
                                        <Flex width="100%">
                                            <Text fontWeight={300} color="#fff">{dt?.name}</Text>
                                        </Flex>
                                    </Flex>
                                )
                            })
                            }                        
                        </ColRight>
                    </CsFlexMaxWidth>
                </ContainerFlex>
                <CsFlex>
                    <CsTitle color="textSubtle">© 2023 GIFTCARDS.</CsTitle>
                    {/* <Flex style={{gap:"2rem"}}>
                            { items?.map((dt) => {
                                const Icon = dt?.icon
                                return (
                                    <Link href={dt.href} external>
                                        <Icon/>
                                    </Link>
                                )
                            })
                        }       
                    </Flex>       */}
                </CsFlex>
            </Flex>
        </StyledFooter>
    );
};

export default MenuItem;

const ColLeft = styled(Flex)`
    width: 30%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    > img{
        width: 183px;
    }
    @media screen and (max-width:600px) {
        width: 100%;
        > img{
            width: 100px;
        }
    }
`
const ColRight = styled(Flex)`
    width: 70%;
    @media screen and (max-width:600px) {
        width: 100%;
    }
`
const CsFlex = styled(Flex)`
    width: 100%;
    justify-content: center;
    /* max-width:1200px; */
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    background-color:#fff;
    
    /* @media screen and (max-width:1280px) {
        max-width: 900px;
    }
    @media screen and (max-width:768px) {
        max-width: 700px;
    } */
    @media screen and (max-width: 600px) {
        justify-content: center;
    }
`
const CsTitle = styled(Text)`
    font-size:16px;
    @media screen and (max-width:600px) {
        /* display: none; */
        font-size:14px;
    }
`
const CsFlexMaxWidth = styled(Flex)`
    width:100%;
    max-width:1200px;
    @media screen and (max-width:1280px) {
        max-width: 900px;
    }
    @media screen and (max-width:768px) {
        max-width: 700px;
    }
`
const CsHeading = styled(Heading)`
    font-size: 20px;
    color:#fff;
    font-weight: 400;
    @media screen and (max-width:600px) {
       font-size: 14px;
    }

`