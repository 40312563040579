import { useTranslation } from '@pancakeswap/localization'
import {
    Button,
    LogoutIcon,
    UserMenu as UIKitUserMenu,
    UserMenuItem,
    UserMenuVariant,
    useModal,
    Flex,
    Text
} from '@pancakeswap/uikit'
import NextLink from 'next/link'
import ModalInfo from 'views/GiftCards/components/ModalInfo'

import { useAddress, useDisconnect } from '@thirdweb-dev/react'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import styled from 'styled-components'
import WalletModal, { WalletView } from './WalletModal'

const UserMenu = () => {
    const { t } = useTranslation()
    const { isWrongNetwork } = useActiveChainId()
    const address = useAddress();
    const disconnect = useDisconnect();

    const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
    const [userMenuText, setUserMenuText] = useState<string>('')
    const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
    const avatarSrc = undefined
    useEffect(() => {
        if (hasPendingTransactions) {
            setUserMenuText(t('%num% Pending', { num: pendingNumber }))
            setUserMenuVariable('pending')
        } else {
            setUserMenuText('')
            setUserMenuVariable('default')
        }
    }, [hasPendingTransactions, pendingNumber, t])

    const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
    const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

    const onClickWalletMenu = (): void => {
        if (isWrongNetwork) {
            onPresentWrongNetworkModal()
        } else {
            onPresentWalletModal()
        }
    }
    
    const [onOpenModalInfo] = useModal(
        <ModalInfo
            title=""
        />
    );


    const UserMenuItems = () => {
        return (
            <>
                <UserMenuItem as="button" onClick={onOpenModalInfo}>
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                        <Text>{t('Thông tin cá nhân')}</Text>
                    </Flex>
                </UserMenuItem>
                <NextLink href="/myvoucher">
                    <UserMenuItem as="button">
                        <Flex alignItems="center" justifyContent="space-between" width="100%">
                            <Text>{t('Thẻ quà tặng')}</Text>
                        </Flex>
                    </UserMenuItem>
                </NextLink>

                <UserMenuItem as="button" onClick={disconnect}>
                    <Flex>
                        <Text>Ngắt kết nối</Text>
                    </Flex>
                    <LogoutIcon />
                </UserMenuItem>
            </>
        )
    }

    if (address) {
        return (
            <UIKitUserMenu
                account={address}
                avatarSrc={avatarSrc}
                text={userMenuText}
                variant={userMenuVariable}
                isShowIcon={!false}
            >
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    if (isWrongNetwork) {
        return (
            <UIKitUserMenu text={t('Network')} variant="danger">
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    return (
        <CsConnectWalletButton/>
    )
}

export default UserMenu

const CsConnectWalletButton = styled(ConnectWalletButton)`
    height: 46px;
    background: ${({ theme }) => theme.colors.primary} ;
    color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 12px;
    @media screen and (max-width:600px) {
        width:100px;   
    }
`

const CsButtonVerify = styled(Button)`
    padding-left: 0px;
    background: transparent;
    color: #23262F;
    font-weight: normal;
    font-size: 16px;
`
