import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg width="30" height="32" viewBox="0 0 30 32" fill="none" {...props}>
            <path
                fill="none"
                d="M15.1769 20.2134C17.4774 20.2134 19.3423 18.3486 19.3423 16.0481C19.3423 13.7477 17.4774 11.8828 15.1769 11.8828C12.8765 11.8828 11.0116 13.7477 11.0116 16.0481C11.0116 18.3486 12.8765 20.2134 15.1769 20.2134Z"
                stroke="#494949"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.986 10.0031L27.3166 7.11822C26.6197 5.91887 25.0962 5.51369 23.8969 6.21061L23.0703 6.68063C21.4009 7.63687 19.3264 6.43751 19.3264 4.50882V3.55259C19.3264 2.17496 18.208 1.05664 16.8304 1.05664H13.4917C12.114 1.05664 10.9958 2.17496 10.9958 3.55259V4.50882C10.9958 6.43751 8.92116 7.63687 7.2518 6.68063L6.42525 6.21061C5.2259 5.51369 3.70237 5.93508 3.00545 7.11822L1.33608 10.0031C0.639164 11.2025 1.06055 12.726 2.2437 13.4229L3.07029 13.8929C4.73966 14.8492 4.73966 17.2641 3.07029 18.2203L2.2437 18.6903C1.04435 19.3873 0.639164 20.9108 1.33608 22.1101L3.00545 24.995C3.70237 26.1944 5.2259 26.5996 6.42525 25.9027L7.2518 25.4327C8.92116 24.4764 10.9958 25.6758 10.9958 27.6044V28.5607C10.9958 29.9383 12.114 31.0566 13.4917 31.0566H16.8304C18.208 31.0566 19.3264 29.9383 19.3264 28.5607V27.6044C19.3264 25.6758 21.4009 24.4764 23.0703 25.4327L23.8969 25.9027C25.0962 26.5996 26.6197 26.1782 27.3166 24.995L28.986 22.1101C29.6829 20.9108 29.2615 19.3873 28.0784 18.6903L27.2518 18.2203C25.5825 17.2641 25.5825 14.8492 27.2518 13.8929L28.0784 13.4229C29.2777 12.726 29.6829 11.1863 28.986 10.0031Z"
                stroke="#494949"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default Icon;
