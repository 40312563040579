import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 21px;
  height: 20px;
  @media screen and (max-width: 768px){
    width: 19x;
    height: 18px;
  }
`
const Icon: React.FC<SvgProps> = (props) => (
  <CsSvg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.60425 4.79199C7.26258 4.79199 6.97925 4.50866 6.97925 4.16699V1.66699C6.97925 1.32533 7.26258 1.04199 7.60425 1.04199C7.94591 1.04199 8.22925 1.32533 8.22925 1.66699V4.16699C8.22925 4.50866 7.94591 4.79199 7.60425 4.79199Z" fill="#292D32"/>
  <path d="M14.2708 4.79199C13.9291 4.79199 13.6458 4.50866 13.6458 4.16699V1.66699C13.6458 1.32533 13.9291 1.04199 14.2708 1.04199C14.6124 1.04199 14.8958 1.32533 14.8958 1.66699V4.16699C14.8958 4.50866 14.6124 4.79199 14.2708 4.79199Z" fill="#292D32"/>
  <path d="M16.1042 18.4583C14.2875 18.4583 12.8125 16.9833 12.8125 15.1667C12.8125 13.35 14.2875 11.875 16.1042 11.875C17.9208 11.875 19.3958 13.35 19.3958 15.1667C19.3958 16.9833 17.9208 18.4583 16.1042 18.4583ZM16.1042 13.125C14.9792 13.125 14.0625 14.0417 14.0625 15.1667C14.0625 16.2917 14.9792 17.2083 16.1042 17.2083C17.2292 17.2083 18.1458 16.2917 18.1458 15.1667C18.1458 14.0417 17.2292 13.125 16.1042 13.125Z" fill="#292D32"/>
  <path d="M19.2707 18.9582C19.1124 18.9582 18.954 18.8999 18.829 18.7749L17.9957 17.9415C17.754 17.6999 17.754 17.2999 17.9957 17.0582C18.2374 16.8165 18.6374 16.8165 18.879 17.0582L19.7124 17.8915C19.954 18.1332 19.954 18.5332 19.7124 18.7749C19.5874 18.8999 19.429 18.9582 19.2707 18.9582Z" fill="#292D32"/>
  <path d="M8.02083 12.0837C7.9125 12.0837 7.80416 12.0587 7.70416 12.0171C7.59583 11.9754 7.50416 11.917 7.42916 11.842C7.39583 11.8004 7.35417 11.7587 7.32917 11.717C7.29583 11.667 7.27083 11.6171 7.25417 11.5671C7.22917 11.5171 7.2125 11.4671 7.20417 11.4171C7.19584 11.3587 7.1875 11.3004 7.1875 11.2504C7.1875 11.0337 7.27916 10.8171 7.42916 10.6587C7.50416 10.5837 7.59583 10.5254 7.70416 10.4837C8.00416 10.3504 8.37917 10.4254 8.61251 10.6587C8.76251 10.8171 8.85417 11.0337 8.85417 11.2504C8.85417 11.3004 8.84583 11.3587 8.8375 11.4171C8.82916 11.4671 8.8125 11.5171 8.7875 11.5671C8.77083 11.6171 8.74583 11.667 8.7125 11.717C8.67917 11.7587 8.64584 11.8004 8.61251 11.842C8.45417 11.992 8.2375 12.0837 8.02083 12.0837Z" fill="#292D32"/>
  <path d="M10.9376 12.083C10.7209 12.083 10.5042 11.9913 10.3459 11.8413C10.1959 11.683 10.1042 11.4747 10.1042 11.2497C10.1042 11.1413 10.1292 11.033 10.1709 10.933C10.2126 10.833 10.2709 10.7413 10.3459 10.658C10.6542 10.3497 11.2126 10.3497 11.5293 10.658C11.6793 10.8163 11.7709 11.033 11.7709 11.2497C11.7709 11.2997 11.7626 11.358 11.7542 11.4163C11.7459 11.4663 11.7292 11.5163 11.7042 11.5663C11.6876 11.6163 11.6626 11.6663 11.6292 11.7163C11.5959 11.758 11.5626 11.7997 11.5293 11.8413C11.3709 11.9913 11.1542 12.083 10.9376 12.083Z" fill="#292D32"/>
  <path d="M8.02083 15C7.80417 15 7.58749 14.9083 7.42916 14.7583C7.27916 14.6 7.1875 14.3833 7.1875 14.1667C7.1875 14.0583 7.2125 13.95 7.25417 13.85C7.29583 13.7417 7.35416 13.65 7.42916 13.575C7.73749 13.2667 8.30417 13.2667 8.61251 13.575C8.76251 13.7333 8.85417 13.95 8.85417 14.1667C8.85417 14.3833 8.76251 14.6 8.61251 14.7583C8.45417 14.9083 8.2375 15 8.02083 15Z" fill="#292D32"/>
  <path d="M18.0209 8.20019H3.85425C3.51258 8.20019 3.22925 7.91686 3.22925 7.5752C3.22925 7.23353 3.51258 6.9502 3.85425 6.9502H18.0209C18.3626 6.9502 18.6459 7.23353 18.6459 7.5752C18.6459 7.91686 18.3626 8.20019 18.0209 8.20019Z" fill="#292D32"/>
  <path d="M12.0792 18.9587H7.60417C4.5625 18.9587 2.8125 17.2087 2.8125 14.167V7.08366C2.8125 4.04199 4.5625 2.29199 7.60417 2.29199H14.2708C17.3125 2.29199 19.0625 4.04199 19.0625 7.08366V10.8337C19.0625 11.1753 18.7792 11.4587 18.4375 11.4587C18.0958 11.4587 17.8125 11.1753 17.8125 10.8337V7.08366C17.8125 4.70033 16.6542 3.54199 14.2708 3.54199H7.60417C5.22083 3.54199 4.0625 4.70033 4.0625 7.08366V14.167C4.0625 16.5503 5.22083 17.7087 7.60417 17.7087H12.0792C12.4208 17.7087 12.7042 17.992 12.7042 18.3337C12.7042 18.6753 12.4208 18.9587 12.0792 18.9587Z" fill="#292D32"/>
  </CsSvg>
);

export default Icon;
