import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { Button, Grid, Heading, Message, MessageText, Modal, Text } from '@pancakeswap/uikit'
import { useDisconnect, useNetworkMismatch } from '@thirdweb-dev/react'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem, getParentMenu } from 'components/Menu/utils'
import { useHistory } from 'contexts/HistoryContext'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'
import { chains } from 'utils/wagmi'

interface Props {
    onDismiss?:() => void
}

export const PageNetworkSupportModal:React.FC<Props> = ({onDismiss}) => {
    const { t } = useTranslation()
    const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const { chainId, isConnected, isWrongNetwork } = useActiveWeb3React()
    const disconnect = useDisconnect();
    const foundChain = useMemo(() => chains.find((c) => c.id === chainId), [chainId])
    const historyManager = useHistory()
    const lastValidPath = historyManager?.history?.find((h) => ['/swap', '/liquidity'].includes(h))

    const menuItems = useMenuItems()
    const { pathname, push } = useRouter()

    const { title, image } = useMemo(() => {
        const url = getParentMenu(pathname)
        const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, url })
        const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, url })

        return {
            title: activeSubMenuItem?.disabled ? activeSubMenuItem?.label : activeMenuItem?.label,
            image: activeSubMenuItem?.image || activeMenuItem?.image,
        }
    }, [menuItems, pathname])
    function handleClick() {
        disconnect().then(() => {
            push('/')
            switchNetworkLocal(ChainId.ONUS)
        })
    }
    
    const handleSwitchNetwork = () => {
        switchNetworkAsync(ChainId.ONUS).then(() => {
            onDismiss()
        }).catch((err) => console.log(err))
    }

    return (
        <CsModal title={title || t('Kiểm tra mạng lưới của bạn')} onDismiss={handleClick} headerBackground="gradientCardHeader">
            <Grid style={{ gap: '16px' }}>
                <Text>{t('Hiện tại trang này chỉ được hỗ trợ ở Onus Chain')}</Text>
                <Message variant="warning">
                    <MessageText>{t('Vui lòng chuyển mạng lưới của bạn để tiếp tục.')}</MessageText>
                </Message>
                {canSwitch ? (
                    <Button
                        variant={foundChain && lastValidPath ? 'secondary' : 'primary'}
                        isLoading={isLoading}
                        onClick={handleSwitchNetwork}
                    >
                        Chuyển mạng lưới trong ví
                    </Button>
                ) : (
                    <Message variant="warning">
                        <MessageText>{t('Vui lòng chuyển mạng lưới của bạn để tiếp tục.')}</MessageText>
                    </Message>
                )}
                {isConnected && (
                    <Button
                        variant="secondary"
                        onClick={handleClick}
                    >
                        Ngắt kết nối ví
                    </Button>
                )}
            </Grid>
        </CsModal>
    )
}

const CsModal = styled(Modal)`
    width: 100%;
    max-width: 450px;
    ${Heading} {
        font-weight: 800;
        font-size: 22px;
    }
    overflow-y: hidden;
`
