import { createAction } from '@reduxjs/toolkit'
import { BrandItem, Brandtype, Categorytype } from './type'

export const fetchActivePrice = createAction<{price:number}>('home/fetchActivePrice')
export const fetchStatusShowmore = createAction<{isShowmore:boolean}>('home/fetchStatusShowmore')
export const fetchActiveCategory = createAction<{category:number|null}>('home/fetchActiveCategory')

export const fetchListCategory = createAction<{ListCategory:Categorytype[]|null}>('home/fetchListCategory')
export const fetchPublicBrand = createAction<{ListBrand:Brandtype[] | null}>('home/fetchPublicBrand')
export const fetchProvinceId = createAction<{cityId:string | null}>('home/fetchProvinceId')
export const fetchListProvinceId = createAction<{ListProvinceId:string[] | null}>('home/fetchListProvinceId')
export const fetchDetailBrand = createAction<{brandItem:BrandItem[]}>('home/fetchDetailBrand')
export const getStatusLoading = createAction<{ isLoading: boolean }>("home/getStatusLoading");