import orderBy from 'lodash/orderBy'
import { ConfigMenuItemsType } from './config/config'

export const getParentMenu = ( url:string ) => {
  const lastIndexOf = url.lastIndexOf('/')
  if( lastIndexOf === 0) {
    return url 
  }
  if(lastIndexOf > 0) {
    let str = url;
    while (str?.lastIndexOf('/') > 0) {
        str = str.substring( 0 , str?.lastIndexOf('/'))
    }
    return str
  }
  return null
}
export const getActiveMenuItem = ({ url, menuConfig }: { url: string; menuConfig: ConfigMenuItemsType[] }) =>
    menuConfig.find((menuItem) => url === menuItem.href)

export const getActiveSubMenuItem = ({ url, menuItem }: { url: string; menuItem?: ConfigMenuItemsType }) => {
    const activeSubMenuItems = menuItem?.items.filter((subMenuItem) => url === subMenuItem.href) ?? []
   
    // Pathname do  esn't include any submenu item href - return undefined
    if (!activeSubMenuItems || activeSubMenuItems.length === 0) {
      return undefined
    }

    // Pathname includes one sub menu item href - return it
    if (activeSubMenuItems.length === 1) {
      return activeSubMenuItems[0]
    }

    // Pathname includes multiple sub menu item hrefs - find the most specific match
    const mostSpecificMatch = orderBy(activeSubMenuItems, (subMenuItem) => subMenuItem.href.length, 'desc')[0]

    return mostSpecificMatch
}
