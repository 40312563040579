import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
    position: relative;
    ${({ $isActive, $variant, theme }) =>
        $isActive &&
        $variant === "subMenu" &&
        `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};
    width: 120px !important;
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    text-transform: uppercase;
    background: ${({ $isActive }) => ($isActive ? "linear-gradient(98deg, #FFC120 7.64%, #F78E01 92.53%)" : "#fff")};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 400;
    opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
    ${({ $statusColor, theme }) =>
        $statusColor &&
        `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

    ${({ $variant }) =>
        $variant === "default"
            ? `
    // padding: 0 16px;
    height: 48px;
  `
            : `
    padding: 4px 4px 0px 4px;
    height: 42px;
  `}
  > svg {
        fill: ${({ theme }) => theme.colors.text};
    }
`;

export default StyledMenuItem;
