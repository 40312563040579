import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg height="20px" width="20px" viewBox="0 0 20 21" fill="none" {...props}>
            <path fill="none" d="M9.99805 13.9052L9.99805 7.09521" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M6.87305 10.7686C6.87305 10.7686 8.97971 13.9052 9.99971 13.9052C11.0197 13.9052 13.123 10.7686 13.123 10.7686" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" fillRule="evenodd" clipRule="evenodd" d="M17.707 10.5003C17.707 16.2812 15.7795 18.2087 9.9987 18.2087C4.21787 18.2087 2.29036 16.2812 2.29036 10.5003C2.29036 4.71949 4.21787 2.79199 9.9987 2.79199C15.7795 2.79199 17.707 4.71949 17.707 10.5003Z" stroke={props?.color} strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );
};

export default Icon;
