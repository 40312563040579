import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { 
    fetchActivePrice, fetchStatusShowmore, fetchActiveCategory, fetchPublicBrand, 
    fetchListCategory, fetchProvinceId, fetchDetailBrand, fetchListProvinceId, getStatusLoading } from './actions'
import { BrandItem, Brandtype, Categorytype } from './type'

interface global {
    price: number,
    isShowmore: boolean,
    category:number|null,
    ListBrand:Brandtype[],
    ListCategory:Categorytype[],
    cityId:string,
    ListProvinceId:string[],
    brandItem:BrandItem[],
    isLoading: boolean,
}
export const initialState: global = {
    price: 0,
    isShowmore:false,
    category: 0,
    ListBrand: [],
    ListCategory:[],
    cityId:null,
    ListProvinceId:[],
    brandItem:null,
    isLoading:true,
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchActivePrice, (state, action) => {
            state.price = action.payload.price
        })
        .addCase(fetchStatusShowmore, (state, action) => {
            state.isShowmore = action.payload.isShowmore
        })
        .addCase(fetchActiveCategory, (state, action) => {
            state.category = action.payload.category
        })
        .addCase(fetchPublicBrand, (state, action) => {
            state.ListBrand = action.payload.ListBrand
        })
        .addCase(fetchListCategory, (state, action) => {
            state.ListCategory = action.payload.ListCategory
        })
        .addCase(fetchProvinceId, (state, action) => {
            state.cityId = action.payload.cityId
        })
        .addCase(fetchListProvinceId, (state, action) => {
            state.ListProvinceId = action.payload.ListProvinceId
        })
        .addCase(fetchDetailBrand, (state, action) => {
            state.brandItem = action.payload.brandItem
        })
        .addCase(getStatusLoading, (state: global, action: PayloadAction<{ isLoading: boolean }>) => {
            state.isLoading = action.payload.isLoading;
        })
)