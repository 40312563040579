import { FacebookIcon, GlobeIcon, InvoiceIcon, LocationIcon, PhoneIcon, TelegramIcon } from '@pancakeswap/uikit'
import { ContactItem } from '@pancakeswap/uikit/src/components/Footer/types'
import { ElementType } from 'react'

interface socialItem {
    name: string,
    href: string,
    icon: ElementType<any>
}
export const socialsConfig:socialItem[] = [
    {
        name: "Facebook",
        href: "#",
        icon: FacebookIcon
    },
    {
        name: "Telegram",
        href: "#",
        icon: TelegramIcon
    },
    {
        name: "Globe",
        href: "#",
        icon: GlobeIcon
    }
] 

export const contactConfig:ContactItem[] = [
    {
        name: "Lầu 3, số 81 Nguyễn Hiền, KDC 91B, An Khánh, Ninh Kiều, Cần Thơ",
        icon: LocationIcon
    },
    {
        name: "MST: 1801746602",
        icon: InvoiceIcon
    },
    {
        name: "02923826688",
        icon: PhoneIcon
    },
    {
        name: "https://www.facebook.com/GiftCards.voucher",
        icon: FacebookIcon,
        isExternalLink: !false,
        href: "https://www.facebook.com/GiftCards.voucher"
    }
    
]