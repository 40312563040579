import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 20 20" fill="none" {...props}>
            <path fill="none" d="M13.4057 10H6.5957" stroke="#F89003" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" d="M10.2686 6.87329C10.2686 6.87329 13.4052 8.97996 13.4052 9.99996C13.4052 11.02 10.2686 13.1233 10.2686 13.1233" stroke="#F89003" strokeLinecap="round" strokeLinejoin="round"/>
            <path fill="none" fillRule="evenodd" clipRule="evenodd" d="M10.0003 17.7083C15.7812 17.7083 17.7087 15.7808 17.7087 9.99992C17.7087 4.21909 15.7812 2.29159 10.0003 2.29159C4.21949 2.29159 2.29199 4.21909 2.29199 9.99992C2.29199 15.7808 4.21949 17.7083 10.0003 17.7083Z" stroke="#F89003" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );
};

export default Icon;
